.paginator {

    .paginator-left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-family: 'HelveticaNowDisplay';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.03em;
        color: #A3A3A3;

        span {
            cursor: pointer;
        }
    }



    .paginator-center {
        justify-content: center;
        align-items: center;
        display: flex;

        .paginator-box {
            cursor: pointer;
            width: 28px;
            height: 28px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0px 3px;
            font-family: 'HelveticaNowDisplay';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.03em;
            border-radius: 4px;
            color: #111111;

            &.selected {
                color: #111111;
                background-color: #fafafa;
                border-radius: 4px;
            }

        }


    }

    .paginator-right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-family: 'HelveticaNowDisplay';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.03em;
        color: #A3A3A3;

        span {
            cursor: pointer;
        }
    }

    @media screen and (max-width: 600px) {
        .paginator-left {
            justify-content: flex-end;
        }

        .paginator-right {
            justify-content: flex-start;
        }
    }
}