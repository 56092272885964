.responsive-padding-history-card {
  padding: 32px 48px 32px 48px;
}

.subscriptionSearch {
  background-color: inherit;
  height: 25px;
}

.paginator-options {
  width: 40px;
  background-color: inherit;
  font-size: 14px;
  font-weight: 400;
  padding: 0;
  margin: 0px 10px;
  border: 0px !important;
}

.td-padding {
  padding: 8px 12px;
}

.th-padding {
  padding: 8px 12px;
}

.history-section {
  .history-details {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .history-collection {
    padding-top: 15px;
  }

  .cardholder-section {
    display: flex;
    flex-direction: column;
  }

  .history-search {
    border: none !important;
    border-radius: 25rem;
    height: 38px;
    font-size: 14px;
  }

  .amount-details {
    display: flex;
    justify-content: space-around;
  }
}

.theme-dark {
  .history-section {
    .history-search {
      border: none !important;
    }
  }
}

@media screen and (max-width: 600px) {

  .responsive-padding-history-card {
    padding: 16px;
  }

  .history-section {
    .responsive-historyCard {
      border: 1px solid #eeeeee;
      border-radius: 8px;

      .history-title {
        padding: 0 0 1.5rem 0 !important;
      }
    }

    .history-collection {
      margin-bottom: 15px;
      padding: 16px;
      border: 1px solid #eee;
      border-radius: 8px;

      .responsive-history-details {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
      }
    }
  }

  .theme-dark {
    .history-section {
      .responsive-historyCard {
        border: 1px solid #242424;
      }
    }

    .history-collection {
      border: 1px solid #242424;
    }
  }
}