.managecard-main {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border: 1px solid;
  border-radius: 8px;

  .card-detail {
    display: flex;

    .card-logo {
      margin-right: 1rem;
    }

    .card-number {
      display: flex;
      flex-direction: column;
    }
  }
}

@media screen and (max-width: 600px) {
  .managecard-main {
    padding: 0.5rem;
    margin: 0;
  }
}