.detail-section {
  .transaction-date {
    display: flex;
    justify-content: flex-start;

    .details {
      width: 50%;
    }

    .date-section {
      display: flex;
      flex-direction: column;
    }
  }

  .creator-info-container {
    gap: 8px;
    margin-top: 1.5rem;

    .creator-avatar {
      position: relative;
      width: 36px;
      height: 36px;
      object-fit: cover;
      margin-bottom: 0;
      border-radius: 100%;
      align-self: center;
    }
  }
}

@media screen and (max-width: 600px) {
  .details {
    font-size: 14px;
  }

  .detail-section .transaction-date {
    display: block;

    span {
      margin-bottom: 10px;
    }
  }

  .creator-info-container {
    margin-top: 0 !important;
  }
}