.title {
  font-weight: 800;
  font-size: 20px;
}

.subtitle {
  font-size: 14px;
  font-weight: 700;
}

.details {
  font-size: 12px;
  font-weight: 400;
}

.theme-light {
  .bgc-white {
    background-color: $white-color;
  }

  .text-color-dark {
    color: $black-color;
  }

  .details-text {
    padding-top: 4px;
    line-height: 20px;
    color: #111;
  }

  .details-text-title{
    color: $gray-color;
    line-height: 16px
  }

  .card-color {
    background-color: $lightest-gray-color;
    border-color: $dark-white-color !important;
  }
}

.theme-dark {
  .bgc-white {
    background-color: $black-color !important;
  }

  .text-color-dark {
    color: $white-color !important;
  }

  .details-text {
    padding-top: 4px;
    color: #FFF;
    line-height: 20px
  }

  .details-text-title{
    color: $dim-gray-color;
    line-height: 16px
  }

  .card-color {
    background-color: $lighter-black-color;
    border-color: $light-black-color !important;
  }

  .bgc-lighter-black {
    background-color: $lighter-black-color !important;
  }

  .bgc-lighter-black-new{
    background-color: #111 !important;
  }
}