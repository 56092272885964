.add-card-modal {
    overflow: auto;
    max-height: 600px;

    @media (max-width: 576px) {
        max-height: 600px;
    }

    .modal-input {
        width: 100%;
        border: 1px solid $dark-white-color;
        background-color: transparent;
        font-size: 14px;
    }

    .select-country-input,
    .stripe-input {
        width: 100%;
        border: 1px solid $dark-white-color;
        font-size: 14px;
    }

    .stripe-input {
        padding: 11px 24px;
    }
}

.theme-dark {
    .add-card-modal {
        .modal-input {
            border: 1px solid $light-black-color;
            color: #fff !important;
        }

        .select-country-input,
        .stripe-input {
            border: 1px solid $light-black-color;
        }
    }
}