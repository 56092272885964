.pills {
    display: inline-flex;
    padding: 2px 4px;
    align-items: center;
    gap: 2px;   
    border-radius: 2px;

    font-family: 'HelveticaNowDisplay';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;

    &.red {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #C00;
        color: #C00;        
    }

    &.orange {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #F2994A;
        color: #F2994A;
    }

    &.open {
        background: #D5E1FF;
        border: 1px solid #2E69FF;
        border-radius: 28px;
        padding: 4px 8px;
        font-weight: 700;
    }

    &.solved {
        background: #EDFAF0;
        border: 1px solid #4ECC6A;
        border-radius: 28px;
        padding: 4px 8px;
        font-weight: 700;
    }
}