@import "utilities/main";
@import "components/main";
@import "pages/main";

.prevent-swipedown {
  overscroll-behavior-y: contain;
}

@media (min-width: 768px) {
  .main-content {
    min-height: 100vh;
  }
}

.carousel-status {
  display: none;
}

.sub-check {
  position: relative;
  top: -8px;
}

.fix-padding-comment {
  padding-top: 10px !important;
}

.pt-90px {
  padding-top: 90px;
}

.searched-text {
  color: #cc0000;
  font-weight: 700;
}

.fix-image-trending-post {
  height: 0px;
  padding-bottom: 100%;
  overflow: hidden;
  border-radius: 8px;
}

.border-select {
  border-radius: 40px;
  border: 1px solid var(--border-day, #eee);
}

.main-mobile-clip {
  margin-top: 63px;
}

.fix-buttom:hover {
  background-color: #d40000;
}

.wallet-info {
  color: var(--gray-day, #a3a3a3);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.42px;
}

.clip-what {
  display: flex;
  padding: 24px;
  display: grid;
  gap: 24px;
  align-self: stretch;
  background: var(--background-day, #fff);
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
  border-left: 1px solid #eee;
  border-radius: 0px 0px 8px 8px;
}

.theme-dark {
  .clip-what {
    background: var(--background-day, #111);
    border-right: 1px solid #111;
    border-bottom: 1px solid #111;
    border-left: 1px solid #111;
  }
}

.shedulePost {
  margin-top: 12px !important;

  .post-remove-icon {
    background: black;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.datepicker-input-base {
  border: none !important;
}

.datepicker-form-control {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.datepicker {
  height: 36px;
  border: none;
}

.shedulePostHover:hover {
  box-shadow: 0 0 5px 1px #ff2800;
}

.w100 {
  width: 100% !important;
}

@media (min-width: 513px) {
  .font-not-found {
    font-size: 32px !important;
  }
}

.text-min-price-chat {
  color: var(--gray-day, #a3a3a3);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

@media (max-width: 512px) {
  .text-min-price-chat {
    color: var(--gray-night, #707070);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  .find-creator-padding-mobile {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .font-not-found {
    font-size: 20px !important;
  }
}

.text-black {
  color: black !important;
}

@media (min-width: 513px) {
  .image-not-found {
    width: 528px !important;
  }
}

@media (max-width: 512px) {
  .image-not-found {
    width: 100% !important;
  }
}

.select-clip-from-collection {
  padding: 0px 24px 16px 24px;
  background: var(--background-day, #fff);
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
  border-left: 1px solid #eee;
  font-size: 14px;
  font-weight: 700;
  color: #a3a3a3;
}

.select-category-for-clip {
  padding: 24px;
  background: var(--background-day, #fff);
  border-right: 1px solid #eee;
  border-left: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.preview-clip-medias {
  width: 100%;
  display: flex;
  flex-direction: row;

  .preview-clip-media {
    border: solid 1px;
    border-radius: 4px;
    background-color: #eee;
    height: auto;
    max-height: 120px;
    max-width: 120px;
    margin: 4px;
    position: relative;

    flex: 1;
    aspect-ratio: 1 / 1;
  }

  .preview-clip-open-collections {
    border: solid 1px;
    border-radius: 4px;
    background-color: #eee;
    height: auto;
    max-height: 120px;
    max-width: 120px;
    margin: 4px;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    line-height: 14px;
    font-style: normal;
    color: #a3a3a3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    flex: 1;
    aspect-ratio: 1 / 1;
  }
}

.position-relative {
  position: relative;
}

.image-post-fix {
  position: absolute;
  top: 0px;
  margin-left: 32px;
  margin-right: 32px;
  margin-top: 50px;
  border-radius: 12px;
  overflow: hidden;
}

.fix-post-locked {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
}

.background-paid-post {
  background: var(--overlay-day, rgba(0, 0, 0, 0.25));
}

.no-border-down {
  border-bottom: 0px !important;
}

.button-upload-clip {
  width: 100%;
  display: inline;
  text-align: right;
}

.clip-comment-input {
  border-radius: 40px;
  border: 1px solid var(--border-day, #eee);
  padding: 5px 16px;
  background: transparent;
}

.theme-dark {
  .clip-comment-input {
    border: 1px solid var(--border-night, #242424);
    color: white;
  }
}

.font-size-12px {
  font-size: 12px !important;
}

.font-size-18px {
  font-size: 18px !important;
}

.blocked-clip {
  position: relative;
}

.blocked-clip::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.75) 0%,
    rgba(0, 0, 0, 0.75) 100%
  );
  z-index: 1;
}

.width-clip-reaction {
  //width: 285px;
  margin-top: 0px !important;
  padding: 0px 8px !important;
  right: -40px;
  left: unset !important;
}

.fix-clip-reaction-mobile {
  //width: 300px;
  margin-top: 0px !important;
  position: absolute;
  bottom: 20px;
  left: 16px;
  padding: 0px 8px !important;
}

.special-input-clip {
  border: 0px;
}

.zIndex9999 {
  z-index: 9999;
}

.clip-browse {
  color: var(--gray-day, #a3a3a3);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.42px;
  text-decoration-line: underline;
}

.fix-buttom {
  font-size: 14px !important;
  width: 200px !important;
  margin-bottom: 20px;
  margin-right: 10px;
}

.mb-5px {
  margin-bottom: 5px;
}

.mr-48px {
  margin-right: 48px;
}

.mr-5px {
  margin-right: 5px;
}

.ml-4px {
  margin-left: 4px;
}

.ml-8px {
  margin-left: 8px;
}

.report-action {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  color: #a3a3a3;

  ul {
    padding-left: 1rem;

    li {
      list-style-type: disc;

      color: #a3a3a3;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
  }
}

.bg-white-only-desk {
  background-color: white;
}

.edit-img-profile {
  width: 20px !important;
  height: 20px !important;
  border-radius: 50% !important;
  display: inline-block;
  line-height: 10px !important;
  text-align: center;
  top: 33px;
  left: 30px;
  padding: 2px;
  background-color: #a3a3a3;
}

.dropdown-settings {
  height: fit-content;
  margin: 1rem 0;
  border: 1px solid #eee;
  border-radius: 36px;

  select {
    border-right: 16px solid transparent;
    border-left: 0;
    border-top: 0;
    border-bottom: 0;
    margin: 0 !important;
  }
}

.theme-dark {
  .bg-white-only-desk {
    background-color: #111;
  }
}

.flex-paginator {
  display: flex;
}

.flex-paginator-list {
  display: flex;
  flex-direction: column;

  div {
    cursor: pointer;
  }
}

.w-88 {
  width: 88% !important;
}

.f-12px-16px {
  font-size: 12px;
}

.w-80 {
  width: 80%;
}

.resize-paginator {
  width: 70%;
  display: block;
}

.w-85 {
  width: 85%;
  text-align: -webkit-right;
}

.w-50 {
  width: 50%;
}

.w-75 {
  width: 75%;
}

.fix-square-image-profile {
  padding-bottom: 100% !important;
  position: absolute;
  top: 0px;
  padding-top: 0px !important;
  height: 0px !important;
  overflow: hidden !important;
  border-radius: 8px;
  // margin: 4px;
  // margin-bottom: 0px !important;
  // margin-top: 8px !important;
}

@media (min-width: 513px) {
  .fix-div-square-image-profile {
    padding-top: 32% !important;
    width: 32% !important;
    height: 0px !important;
    overflow: hidden !important;
  }
}

@media (max-width: 512px) {
  .fix-div-square-image-profile {
    padding-top: 48% !important;
    width: 48% !important;
    height: 0px !important;
    overflow: hidden !important;
  }
}

.sub-button {
  background-color: transparent;
  border-color: #eeeeee;
  color: white;
  border-width: 2px;
  border-radius: 8px;
  height: auto;
}

.margin-botton-none {
  margin-bottom: 0px !important;
}

.sub-button:focus {
  background-color: transparent;
  border-color: #c80000;
}

.sub-button:hover {
  background-color: transparent;
  border-color: #c80000;
}

.button-fix-total {
  border-color: transparent;
  background-color: #c80000;
  white-space: nowrap;
}

.button-fix-total:hover {
  border-color: transparent;
  background-color: #c80000;
}

.created-post-features-bookmark {
  margin: 10px 0 0 !important;
  justify-content: space-between;
  width: 100%;
}

.button-fix-total:focus {
  border-color: transparent;
  background-color: #c80000;
}

.no-media-found-text {
  color: var(--background-night, #111);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.54px;
}

.theme-dark {
  .no-media-found-text {
    color: var(--background-night, #ffffff);
  }
}

@media (max-width: 512px) {
  .fix-mobile-title-creator {
    padding-top: 12px !important;
  }
}

.sub-button-desc {
  //styleName: Small/400;

  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}

.select-category-clip {
  color: var(--background-night, #111);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.text-green {
  color: #4ecc6a;
}

.text-12px {
  color: var(--background-night, #111);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.theme-dark {
  .text-12px {
    color: #ffffff;
  }
}

.container-user-like-story {
  margin-top: 12px;
}

.scheduled-date-picker {
  display: inline-block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 50px;
  color: #000;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #000;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.h-250px {
  height: 250px;
}

.login-card {
  background-color: white;
}

.radius-16 {
  border-radius: 16px !important;
}

.p-top-20 {
  padding-top: 20px;
}

.p-bottom-20 {
  padding-bottom: 20px;
}

.m-20px-30px {
  margin: 20px 30px 20px 30px;
}

.round-cover-edit {
  background-color: #a3a3a3;
  color: #ffffff;
  font-size: 20px;
  padding: 8px;
  display: inline-flex;
  position: absolute;
  right: 16px;
  top: 60px;
  border: none;
  border-radius: 20px;
}

.round-cover-share {
  background-color: #a3a3a3;
  color: #ffffff;
  font-size: 20px;
  padding: 8px;
  display: inline-flex;
  position: absolute;
  right: 16px;
  top: 16px;
  border: none;
  border-radius: 20px;
}

.round-image-edit {
  background-color: #a3a3a3;
  color: #ffffff;
  font-size: 24px;
  padding: 8px;
  display: inline-flex;
  position: absolute;
  left: 125px;
  top: 10px;
  border: none;
  border-radius: 20px;
  z-index: 700;
}

.profile-main-section {
  padding-right: 48px;
}

.fix-dam-radio-buy {
  // padding: 16px !important;
  padding-top: 0px !important;
  padding-bottom: 16px !important;
}

.see-all-button {
  font-size: 14px;
  font-weight: 700;
  color: #c80000;
  border: 1px solid #c80000;
}

.footer-media {
  border-top: none;
  background: #ffffff;
}

.gray-text-important2 {
  .text-grey-400 {
    color: #a3a3a3 !important;
  }

  & {
    color: #a3a3a3 !important;
  }
}

@media (min-width: 513px) {
  .m-clip-button-comment {
    margin-left: 44px;
    margin-top: 8px;
  }
}

@media (max-width: 512px) {
  .m-clip-button-comment {
    margin-top: 8px;
  }
}

@media (max-width: 512px) {
  .cancel-button-clip {
    width: 50%;
  }
}

.list-comment-clip {
  display: grid;
}

.list-comment-clip {
  max-height: 312px;
  overflow: scroll;
  width: 100%;
  margin-left: 16px;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.fix-comment-post-send {
  display: flex !important;
  width: 100% !important;
  padding-bottom: 18px !important;
  padding-top: 8px !important;
  padding-left: 18px !important;
  padding-right: 18px !important;
}

.send-button-clip {
  background-color: black !important;
  color: var(--background-day, #fff) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 20px !important;
  letter-spacing: -0.42px !important;
}

.send-button-clip:hover {
  background-color: black !important;
}

.width-image-common-post {
  width: auto !important;
  max-width: 100%;
  object-fit: cover;
  height: auto !important;
}

.text-for-ago-comment {
  color: var(--gray-day, #a3a3a3);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.42px;
}

.button-advance-upload-clip-inactive {
  display: flex;
  padding: 4px 8px;
  align-items: center;
  border-radius: 2px;
  border: 1px solid var(--border-day, #eee);
  color: var(--gray-day, #a3a3a3);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.42px;
  background-color: transparent;
  margin-right: 8px;
  margin-top: 4px;
}

.button-advance-upload-clip-active {
  display: flex;
  padding: 4px 8px;
  align-items: center;
  border-radius: 2px;
  border: 1px solid var(--primary-red-night, #c00);
  background: var(--primary-red-white-90-day, #fae5e5);
  color: var(--primary-red-night, #c00);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.42px;
  margin-right: 8px;
  margin-top: 4px;
}

.send-button-clip:focus {
  background-color: black !important;
}

.cancel-button-clip {
  background-color: transparent;
  border: 0px;
  color: var(--gray-day, #a3a3a3);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.42px;
}

.cancel-button-clip:hover {
  background-color: transparent;
  border: 0px;
}

.cancel-button-clip:focus {
  background-color: transparent;
  border: 0px;
}

.align-items-center {
  align-items: center;
}

.fix-modal-price {
  text-align: -webkit-center;
  text-align-last: left;
}

.find-creator-page-prev {
  align-items: center;
  height: 100vh;
  position: fixed;
  top: 0px;
  display: grid;
  color: black;
  cursor: pointer;
}

.find-creator-page-succ {
  align-items: center;
  height: 100vh;
  position: fixed;
  top: 0px;
  display: grid;
  color: black;
  cursor: pointer;
}

.container-page-find-creator-succ {
  padding-left: 12px;
  padding-right: 10px;
}

.ml-12 {
  margin-left: 12px !important;
}

.fix-video-post {
  width: auto !important;
  height: auto !important;
  max-width: 100% !important;
  max-height: 100% !important;
}

.container-second-search {
  z-index: 9;
  position: absolute;
  top: 50px;
  margin-left: 16px;
  width: 300px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.container-second-search-mobile {
  position: absolute;
  top: 110px;
  margin-left: 16px;
  width: 250px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  z-index: 9999;
}

.theme-dark {
  .container-second-search {
    background-color: black;
    color: white;
  }

  .container-second-search-mobile {
    background-color: black;
    color: white;
  }
}

.image-2search {
  border-radius: 99px;
  overflow: hidden;
  margin: 8px;
}

.container-page-find-creator-prev {
  padding-right: 25px;
}

.margin12px {
  margin: 12px;
}

.fix-header-support {
  padding: 16px !important;
  text-align-last: center;
}

.w50 {
  width: 50% !important;
}

.border-radius-40 {
  border-radius: 40px;
  margin-right: 12px;
}

.br-8px {
  border-radius: 8px;
}

.br-36px {
  border-radius: 36px !important;
}

.br-40px {
  border-radius: 40px !important;
}

.rec-swipable {
  align-items: center;
}

.align-center {
  align-items: center;
}

.align-center-only {
  align-items: center !important;
}

.desk-modal-up {
  width: 50%;
  margin-left: 10%;
}

.filter-text {
  color: var(--text-day, #111);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.48px;
}

.theme-dark {
  .filter-text {
    color: #ffffff;
  }
}

.active-checkbox-clip {
  color: #c80000;
  border: #c80000;
}

.name-comment-creator {
  color: var(--text-day, #111);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.48px;
  margin-left: 8px;
  margin-right: 8px;
}

.theme-dark {
  .name-comment-creator {
    color: var(--text-day, #fff);
  }
}

.mt-top32px {
  margin-top: 32px;
}

.mt-6px {
  margin-top: 6px;
}

.mt-3px {
  margin-top: 3px;
}

.round-checkbox {
  -webkit-appearance: none;
  appearance: none;
  border: 2px solid #eeeeee;
  border-radius: 50%;
  // background-color: #C80000;
  width: 13px;
  height: 13px;
}

.round-checkbox:checked {
  background-color: #c80000;
  border: none;
  // transform-origin: bottom left;
  // clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

.round-checkbox:checked:after {
  background-color: white;
  // border: none;
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

// .round-checkbox:focus {
//   background-color: #C80000;
// }

.reply-comment-clip {
  color: var(--gray-day, #a3a3a3);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.42px;
  margin-bottom: 8px;
  margin-top: 8px;
  margin-left: 40px;
}

.x-icon-filter {
  color: #111111;
  text-align: right;
  margin-right: 24px;
  cursor: pointer;
}

.theme-dark {
  .x-icon-filter {
    color: #ffffff;
  }
}

.withdraw-method {
  border-radius: 8px;
  border: 1px solid var(--border-day, #eee);
  background: var(--background-day, #fff);
  padding: 16px;
  margin-top: 24px;
  margin-bottom: 24px;
}

.card-money-money {
  color: var(--text-day, #111);
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 32px;
}

.card-money-available {
  color: var(--text-day, #111);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.48px;
}

.p-12px-16px {
  padding: 12px 16px !important;
}

.p-32px-16px {
  padding: 32px 16px !important;
}

.p-4px-30px {
  padding: 4px 30px !important;
}

.p-16px-32px {
  padding: 16px 32px !important;
}

.p-16px-24px {
  padding: 16px 24px !important;
}

.pb-12px {
  padding-bottom: 12px;
}

.mt-5px {
  margin-top: 5px;
}

.border-top-grey {
  border-top: 1px solid #eeeeee;
}

.card-money-request {
  margin-top: 24px;
  margin-bottom: 16px;
  color: var(--text-day, #111);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.54px;
}

.margin-bottom-16px {
  margin-bottom: 16px !important;
}

.card-money-settings {
  color: var(--text-day, #111);
  font-size: 16px;
  margin-bottom: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.48px;
  text-decoration-line: underline;
}

.m-16 {
  margin-bottom: 16px;
}

.card-money-choose {
  color: var(--text-day, #111);
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.42px;
}

.post-fix-payment {
  text-align: -webkit-center;
  width: 100%;
  margin-top: 8px;
  padding: 8px;
  position: relative;
  display: flex;
  justify-content: center;
}

.card-blur {
  filter: blur(8px);
}

.card-money-right {
  margin-right: 12px;
}

.button-w-100 {
  width: 100% !important;
}

.w-75 {
  width: 75%;
}

.card-money-left {
  margin-left: 8px;
}

.card-money-center {
  place-content: center;
}

.all-media {
  padding-bottom: 0;
  padding-left: 0;
  padding-top: 1rem;
}

.profile-card {
  width: 100%;
  padding: 1rem 0;
}

.card {
  border: 0px;
  box-shadow: none;
  border-radius: 0;
  overflow: hidden;
}

.pt-10px {
  padding-top: 10px !important;
}

.social-icon {
  border: 1px solid #eeeeee;
  border-radius: 4px;
  background-color: #fafafa;
  font-size: 18px;
  display: flex;
  width: 28px;
  height: 28px;
  align-items: center;
  justify-content: center;
}

.theme-dark .social-icon {
  border: 1px solid #242424;
  background-color: #2b2b2b;
}

.profile-figure {
  // top: -110px !important;
  left: 6px !important;
  width: 160px !important;
  height: 160px !important;
  border-radius: 50%;
  border: 3px solid rgb(255, 255, 255);
}

img.profile-figure {
  background-color: white !important;
}

.profile-figure-story-to-visualize {
  // top: -110px !important;
  left: 6px !important;
  width: 160px !important;
  height: 160px !important;
  border-radius: 50%;
  border: 3px solid #ff2800;
  cursor: pointer;
  animation: pulse-animation-profile 2s infinite;
}

@keyframes pulse-animation-profile {
  0% {
    box-shadow: 0 0 8px #ff2800;
  }

  50% {
    box-shadow: 0 0 18px #ff2800;
  }

  100% {
    box-shadow: 0 0 8px #ff2800;
  }
}

.profile-figure-visualized-all-story {
  left: 6px !important;
  width: 160px !important;
  height: 160px !important;
  border-radius: 50%;
  border: 3px solid gray;
  cursor: pointer;
}

.review-parts {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.social-media-section {
  justify-content: space-evenly;
  padding-left: 3rem;
  padding-top: 0.5rem;
  gap: 12px;
}

.user_id {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 800;
  color: #111;

  img {
    margin-left: 4px;
  }
}

.save-pass {
  width: 100px;
}

.ml-24px {
  margin-left: 24px;
}

.uniq_id {
  font-size: 14px;
  font-weight: 400;
  color: #a3a3a3;
}

.profile-user-icon {
  top: -40px;
  left: 30px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 3px solid #212529;
}

.border-color-red {
  border-color: #c80000 !important;
}

.align-center {
  align-self: center;
}

.profile-bio-section {
  justify-content: space-between;
  position: relative;
  top: 2rem;
  padding-top: 1rem;
}

.profile {
  padding-bottom: 3rem;
  padding-top: 1rem;
}

.bio-description {
  font-size: 16px;
  font-weight: 400;
  color: #111;
}

.all-post-count {
  font-size: 16px;
  font-weight: 700;
  color: #111;
}

.subscribers-profile-indicator {
  color: #111 !important;
}

.theme-dark {
  .bio-description,
  .all-post-count,
  .subscribers-profile-indicator,
  .user_id {
    color: #fff !important;
  }
}

.bg-counter-active {
  background-color: #d5e1ff !important;
}

.count-bookmark {
  height: 24px;
  width: 24px;
  font-size: 12px;
  padding: 2px 0px;
  background: #eee;
  border-radius: 4px;
  margin-left: 5px;
  margin-bottom: 5px;
  font-weight: 400;
}

.private-section {
  background: #ffffff;
  border: 1px solid #c80000;
  box-shadow: 0px 0px 8px rgba(204, 0, 0, 0.2);
  border-radius: 8px;
  justify-content: space-between;
  padding: 1rem;
}

.theme-dark .private-section {
  background: #111;
  border: 1px solid #242424;
  box-shadow: none;
}

.private-title {
  font-weight: 800;
  font-size: 28px;
  color: #111;
}

.private-details {
  font-weight: 400;
  font-size: 16px;
  color: #111;
}

.checkout-option {
  display: flex;
  align-items: baseline;
}

.checkout-option p {
  font-weight: 400;
  font-size: 12px;
  color: #111;
}

.logo-text {
  color: black;
}

.width50 {
  width: 50%;
}

.navbar-circle {
  display: relative;
  border-radius: 100%;
  height: 32px;
  width: 32px;
  border: 1px solid #eee;
  background-color: #fafafa;
}

.wallet-schedule-button {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  border: 1px solid var(--text-day, #111);
  background-color: transparent !important;
  margin-right: 8px;
}

.wallet-schedule-button:hover {
  background-color: transparent !important;
  border: 1px solid var(--text-day, #111);
}

.wallet-schedule-button:focus {
  background-color: transparent !important;
  border: 1px solid var(--text-day, #111);
}

.theme-dark {
  .wallet-schedule-button {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    border: 1px solid var(--text-day, #ffffff);
    background-color: transparent !important;
    margin-right: 8px;
    color: #ffffff !important;
  }

  .wallet-schedule-button:hover {
    background-color: transparent !important;
    border: 1px solid var(--text-day, #ffffff);
  }

  .wallet-schedule-button:focus {
    background-color: transparent !important;
    border: 1px solid var(--text-day, #ffffff);
  }
}

.fc-daygrid-day-top {
  flex-direction: row !important;
  justify-content: flex-end;
  align-items: center;
  font-size: 10px;
}

.wallet-set-manual {
  color: var(--text-day, #111);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.54px;
}

.fc-theme-standard .fc-scrollgrid {
  border: 0 !important;
}

.notification-selected {
  color: #2e69ff;
}

.icon-upload-clip-calendar {
  color: #4ecc6a;
}

.background-color-trending-post {
  background: white;
}

.bread-crumb-delete-account {
  padding: 20px 15px !important;
  margin-left: 0 !important;
}

.theme-dark .background-color-trending-post {
  background: #111111 !important;
}

.icon-upload-clip-thumbnail {
  color: #c80000;
}

.notification-border {
  border-bottom: 1px solid #2e69ff !important;
}

.notification-rest {
  color: #a3a3a3;
}

.theme-dark {
  .created-post-content {
    color: white !important;
  }

  .search-input,
  .commentInput {
    background-color: transparent;
    border: 1px solid #242424 !important;
    color: #fff;
  }

  .bg-gray {
    background-color: #2b2b2b !important;
  }

  .vaultText {
    color: #fff !important;
  }

  .modal-header-custom {
    color: #111 !important;
  }

  .settings-item {
    color: #ffffff;
  }

  .creator-card-container {
    background-color: #111;
    border: 1px solid #111;
  }

  .fc-view {
    background-color: #111;
  }

  .post-fullname {
    color: white;
  }

  .navbar-circle {
    background-color: #2b2b2b;
    border: 1px solid #242424;
  }

  .post-features,
  .post-features-text {
    color: white;
  }

  .post-input {
    background-color: #111;
    color: white;
  }

  .post-container {
    border: 1px solid #111;
  }

  .created-post-container {
    border: 1px solid #111;
  }

  .logo-text {
    color: white;
  }

  .profile-user-icon {
    border: 3px solid rgb(255, 255, 255);
  }

  .nav-content {
    background-color: #111111;
  }

  .profile-count,
  .profile-title {
    color: white !important;
  }

  .fullname-profile {
    color: white;
  }

  .username-profile,
  .notificationTime {
    color: #707070 !important;
  }

  .nav-header {
    background-color: #111111 !important;
  }

  .basic-features {
    color: #fff;
  }

  .feeds-title,
  .darkthemeUser,
  .darktheme-txt {
    color: #fff !important;
  }

  .darkthemeTime,
  .text-black {
    color: #707070 !important;
  }

  .iconBackground {
    background-color: #2b2b2b !important;
    border: 1px solid #242424 !important;
  }

  .iconBackgroundTip {
    border-radius: 30px;
  }

  .darkthemeButton {
    color: #111 !important;
    background-color: #ffff !important;
  }

  .modal-content {
    background: #111;
  }

  .modal-header {
    border-bottom: 1px solid #242424;
  }

  .modal-footer {
    border-top: 1px solid #242424;
  }

  .sound-wave {
    background-color: #111111;
  }

  .shedulePost {
    background-color: #2b2b2b;
    border: 1px solid #242424;
  }

  .shedulePostHover:hover {
    box-shadow: 0 0 5px 1px #ff2800;
  }

  .darkThemeExpiration {
    background-color: #2b2b2b;
    border-radius: 25rem;
    border: 1px solid #242424;
  }

  .darkthemeDay {
    background-color: #242424;
    color: #fff;
  }

  .trendingPostsection {
    background-color: #111111;
    // position: fixed;
    right: 0;
    bottom: 0;
    top: 64px;
  }

  .trendingTitle,
  .name,
  .darkreactionList,
  .darkThemetext {
    color: #fff;
  }

  ._darkIcon {
    color: #fff;
  }

  .create-plan {
    border-color: #242424;
  }

  .subscribe-count {
    color: #fff;
  }

  .plan-option {
    color: #707070;
  }

  .tab-bordar {
    border-bottom: 1px solid #242424;
  }

  .icon-tab,
  .plan-list {
    background-color: transparent !important;
    color: #707070;
    border-color: #242424;
  }
}

.trendingPostsection {
  width: 280px;
  height: calc(100dvh - 64px);

  .trendingPostsectionContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    gap: 8px;
    flex-wrap: wrap;
    justify-content: left;
    align-items: first baseline;
    align-content: center;
    height: fit-content;
    padding-left: 2rem;
  }
}

.tab-bordar {
  border-bottom: 1px solid #eeee;
}

.darkThemeExpiration {
  border-radius: 25rem;
  border: 1px solid #a3a3a3;
}

.newVault {
  display: flex;
  align-items: baseline;
}

.br-8px {
  border-radius: 8px !important;
}

.custom-border-bottom {
  border-bottom: 1px solid #eee;
}

.creator-cover-image-figure {
  height: 100px;
}

.cover-image-div {
  position: relative;
  border-radius: 8px;
  background-size: cover;
  background-position: center;
  text-align: center;
}

.mr-10px {
  margin-right: 10px;
}

.mr-16px {
  margin-right: 16px;
}

.wallet-secret-text {
  color: var(--gray-day, #a3a3a3);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.42px;
}

.tip-button-selected {
  display: flex;
  padding: 8px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  background-color: transparent;
  border-radius: 8px;
  border: 1px solid var(--primary-red-night, #c00);
  margin-bottom: 16px;
  color: #c80000 !important;
}

.tip-button {
  display: flex;
  padding: 8px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  background-color: transparent;
  border-radius: 8px;
  border: 1px solid var(--border-day, #eee);
  margin-bottom: 16px;
  color: #a3a3a3 !important;
}

.text-payment-method-tip {
  color: var(--text-day, #111);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.42px;
  width: 100%;
}

.fix-tip-button {
  background: var(--primary-red-night, #c00) !important;
  border: 0px;
}

.card-automatic-withdraw {
  display: flex;
  padding: 16px 32px;
  margin-top: 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  background: var(--border-day, #eee);
}

.tip-button-selected:hover {
  background-color: transparent;
  border-radius: 8px;
  border: 1px solid var(--primary-red-night, #c00);
}

.text-area-tip {
  display: flex;
  height: 128px;
  padding: 8px 16px;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--border-day, #eee);
  background: transparent;
}

.select-tip {
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.no-resize {
  resize: none;
  height: 100% !important;
}

.resize-none {
  resize: none;
}

.input-tip {
  width: 83.2px;
  color: black;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  letter-spacing: -0.42px;
  height: 36px;
}

.tip-button-selected:focus {
  background-color: transparent;
  border-radius: 8px;
  border: 1px solid var(--primary-red-night, #c00);
}

.tip-button:hover {
  background-color: transparent;
  border-radius: 8px;
  border: 1px solid var(--primary-red-night, #c00);
}

.tip-button:focus {
  background-color: transparent;
  border-radius: 8px;
  border: 1px solid var(--primary-red-night, #c00);
}

.absolute-left {
  text-align-last: left;
}

.marginBottom12px {
  margin-bottom: 12px !important;
}

.tip-margin-rigth {
  margin-right: 8px;
}

.wallet-url-text {
  color: black;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.42px;
}

.stories-add-story-text {
  color: #ffffff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.42px;
}

.pad-fix-clip {
  padding-left: 240px !important;
  padding-top: 0px !important;
}

.wallet-info-name {
  color: var(--gray-day, #a3a3a3);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.48px;
  width: 50%;
}

.clip-pre-url {
  color: var(--gray-day, #a3a3a3);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.48px;
}

.wallet-m4 {
  margin-bottom: 8px;
}

.block {
  display: block;
}

.wallet-info-detail {
  color: var(--text-day, #111);
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  letter-spacing: -0.48px;
  width: 50%;
}

.wallet-url-div {
  color: black;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.42px;
  padding-left: 12px;
  padding-right: 12px;
}

.border-clip-schedule {
  margin-top: 8px !important;
  border-radius: 40px !important;
  overflow: hidden;
}

.mr-8px {
  margin-right: 8px;
}

.ml-8px {
  margin-left: 8px;
}

.discount-date-upload-clip {
  color: black;
  border-radius: 32px;
  height: 33.5px;
  border: 1px solid var(--border-day, #eee);
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
}

.input-discount-upload-clip {
  width: 100%;
  margin-right: 8px;
  margin-top: 16px;
}

.calendar-discount-upload-clip {
  display: inline-grid;
  background-color: green;
  justify-content: right;
}

.div-filter-clip {
  width: 200px;
  height: 100vh;
  padding-left: 24px;
  overflow-y: scroll;
  z-index: 9999;
}

@media (max-width: 512px) {
  .div-filter-clip {
    position: absolute;
    left: 0px;
    top: 0px;
  }

  .list-comment-clip {
    margin-left: 8px !important;
  }
}

.z-index-fix-mobile-clip {
  z-index: 9999 !important;
  position: absolute;
  top: 0px;
  left: 24px;
}

.padding-top-4rem {
  margin-top: 4rem;
}

.padding-tb-6px {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.padding-tb-8px {
  padding: 8px 0;
}

.padding-tb-12px {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.absolute-center {
  text-align: center !important;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

@media (min-height: 513px) {
  .scroll-like-story {
    overflow-y: scroll;
    max-height: 500px;
  }
}

.name-user-story {
  color: var(--background-night, #111);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.48px;
}

.theme-dark {
  .name-user-story {
    color: var(--background-night, #ffffff);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.48px;
  }
}

.upload-media-text {
  color: var(--background-night, #111);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.48px;
  margin-top: 16px;
  margin-bottom: 8px;
}

.no-white-space {
  white-space: nowrap;
}

.padding-8px {
  padding: 8px;
}

.h35 {
  height: 35px !important;
}

.h-50vh {
  height: 50vh;
}

.h-100px {
  height: 100px;
}

.h-200px {
  height: 200px;
}

.h-250px {
  height: 250px;
}

.h-400px {
  height: 400px;
}

.h-auto {
  height: auto;
}

.creator-profile-figure {
  width: 100px !important;
  height: 100px !important;
  border-radius: 50%;
  border: 3px solid rgb(255, 255, 255);
}

.creator-profile-figure-subscription-plan {
  width: 100px !important;
  height: 100px !important;
  border-radius: 50%;
  border: 3px solid rgb(255, 255, 255);
  margin-top: -40px;
}

.user-profile-figure {
  width: 36px;
  height: 36px;
  border-radius: 100%;
}

.chat-menu-profile-figure {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.verify-button {
  width: 175px;
}

.subscribed-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.user-profile-figure-menubar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: white !important;
}

.overlap-image {
  margin-left: -15px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.theme-dark .form-group label .photo-id {
  background-color: #333 !important;
  border-color: var(--theme-color) !important;
  color: #515184 !important;
  line-height: 1.5 !important;
}

.label-font-14 {
  font-size: 14px;
}

input::placeholder {
  color: #a3a3a3;
  opacity: 1;
}

#option-null {
  color: #eeeeee;
}

.w-45 {
  width: 45%;
}

.lh-05 {
  line-height: 0.5;
}

.s-48 {
  border-radius: 50%;
  height: 48px;
  width: 48px;
  margin-bottom: 0 !important;
}

.pt-5px {
  padding-top: 5px;
}

.lh-1 {
  line-height: 1;
}

.ml-10px {
  margin-left: 10px;
}

.ml-34px {
  margin-left: 34px;
}

.responsive-padding-twostep {
  padding: 32px 48px;
}

.background-color-white-avatar {
  background-color: white !important;
}

.responsive-button {
  width: auto;
}

.modal-footer {
  justify-content: flex-end;
}

@media (min-width: 513px) {
  .fix-desktop-user-page {
    padding-left: 48px !important;
    padding-top: 24px !important;
  }

  .fix-name-profile-align {
    display: "flex";
    align-items: "center";
  }
}

// .theme-dark .background-color-white-avatar {
//   background-color: black !important;
// }

.new-pencil-profile {
  font-size: 22px !important;
  padding: 2px;
}

.creator-name {
  font-size: 14px;
  display: flex;
  align-items: center;
}

.img-subscription {
  height: 32px !important;
  width: 32px !important;
  border-radius: 50%;
}

img.img-subscription {
  background-color: white !important;
}

.responsive-size-14-16 {
  font-size: 14px;
}

.image-media-not-found {
  width: 85%;
}

.support-page {
  padding: 34px 120px;
}

.notification-text {
  color: #111;
  font-size: 14px;
  font-weight: 400;
}

.p-absolute {
  position: absolute;
}

.p-relative {
  position: relative;
}

.multiple-image-notifications,
.multiple-avatar-notifications {
  min-width: 29px !important;
  height: 29px !important;
  object-fit: cover !important;
  border-radius: 50% !important;
  background-color: #ffffff;
  max-width: 29px !important;
}

.notification-profile-image,
.notification-profile-avatar {
  min-width: 40px !important;
  height: 40px !important;
  object-fit: cover !important;
  border-radius: 50% !important;
  background-color: #ffffff;
  max-width: 40px !important;
}

.input-with-suffix {
  padding: 8px 16px;
  height: 36px;
  border: 1px solid #eeeeee;
  border-radius: 28px;
  height: 36px;
  width: 160px;

  #search-support {
    height: 20px;
    border: 0px !important;
    padding: 0px !important;
  }

  .suffix {
    color: #a3a3a3 !important;
  }
}

.responsive-font-18-16 {
  font-size: 18px;
}

.f-black {
  color: #111 !important;
}

.select-support-conteiner {
  margin-left: 10px;
  border: 1px solid #eee;
  border-radius: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 128px;
  height: 36px;

  select {
    height: 33px;
    border: 0px !important;
    padding: 0px !important;
  }
}

#create-support-btn {
  .btn {
    padding: 8px 16px;
    font-weight: 400 !important;
  }
}

.support-page {
  input {
    padding: 0px 16px;
  }
}

.submit-create-support {
  padding: 8px 16px;

  button {
    width: 100%;
    font-weight: 400 !important;
  }
}

.select-create-support-conteiner {
  border: 1px solid #eee;
  border-radius: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;

  padding: 0px 16px;

  select {
    height: 33px;
    border: 0px !important;
    padding: 0px !important;
    font-size: 14px;
  }
}

.generate-col-8 {
  flex: 0 0 auto;
  width: 66.6666666667%;
}

.support-form {
  .form-control {
    border: 1px solid #eee;
    border-radius: 40px;
    height: 36px;
  }

  .form-control.textarea {
    border-radius: 8px !important;
    padding: 8px 16px;
  }
}

.p-16px {
  padding: 16px;
}

.f-32px {
  font-size: 32px;
}

.pills.solved {
  background-color: #4ecc6a;
}

.form-select:focus {
  border: 1px solid #eee;
}

.f-12px {
  font-size: 12px;
}

.select-account-growth {
  padding: 8px 36px 8px 16px !important;
  font-size: 14px !important;
  line-height: 20px;
  max-height: -moz-fit-content;
  max-height: fit-content;
  height: 36px !important;
  width: 100%;
  border: 1px solid #eee;
  border-radius: 40px;
}

.trend-red {
  display: flex;
  padding: 2px 4px;
  align-items: center;
  gap: 2px;
  border-radius: 2px;
  background: var(--tertiary-green-white-90-day, #fae5e5);
  margin: 0px 15px;
  padding: 0px 5px;
  height: 20px;
  color: #cc0000;
}

.trend-green {
  display: flex;
  padding: 2px 4px;
  align-items: center;
  gap: 2px;
  border-radius: 2px;
  background: var(--tertiary-green-white-90-day, #edfaf0);
  margin: 0px 15px;
  padding: 0px 5px;
  height: 20px;
  color: #4ecc6a;
}

.mt-10px {
  margin-top: 10px;
}

.disclaimer {
  font-size: 10px;
  color: #111;
}

.linear-red {
  background: var(--linear-red, linear-gradient(268deg, #c00 0%, #900 100%));
}

.group-subscribers {
  width: 33%;
  padding: 16px 24px;
}

.mr-12px {
  margin-right: 12px;
}

.ml-12px {
  margin-left: 12px;
}

.w-49 {
  width: 49%;
}

.f-large-average {
  color: var(--text-day, #111);
  /* Heading/05/md-800 */
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 32px;
  /* 133.333% */
}

.f-gray {
  color: #a3a3a3;
}

.f-normal {
  /* Body/01/400 */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.mb-8px {
  margin-bottom: 8px;
}

.line-card {
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 24px;
  border: 1px solid #eee;
  background-color: white;
}

.p-32px-48px {
  padding: 32px 48px;
}

.p-24px {
  padding: 24px;
}

.flex-pill {
  padding-right: 20px;
}

.p-24px-16px {
  padding: 24px;
}

.f-20 {
  font-size: 20px;
}

.f-14px-16px {
  font-size: 14px !important;
}

.browse-button {
  background-color: #a3a3a3;
  border-radius: 40px;
  padding: 0px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  font-weight: 700;
  color: white;
  height: 36px;
}

.fc-creator {
  padding: 8px 16px !important;
  font-size: 14px !important;
  line-height: 20px;
  max-height: fit-content;
  height: 36px !important;
  width: 100%;
  border: 1px solid #eee;
  border-radius: 40px;
}

.form-group-creator {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.my-8px {
  margin: 8px 0px;
}

.w-70-100 {
  width: 70%;
}

.ml-10px {
  margin-left: 10px;
}

.f-16px-14px {
  font-size: 16px;
}

.mr-10px {
  margin-right: 10px;
}

.p-5px {
  padding: 5px;
}

.bookmark-video-mobile {
  width: 100px !important;
  height: 200px !important;
}

.mw-332px {
  max-width: 332px;
}

.bookmark-video-web {
  max-width: 332px;
}

@media (max-width: 600px) {
  .fix-card-profile-mobile {
    padding: 1rem 0rem !important;
  }

  .show-post-image {
    height: 100px !important;
    width: 100px !important;
  }

  .f-16px-14px {
    font-size: 14px;
  }

  .group-subscribers {
    margin: 16px;
    width: auto;
    border: 1px solid #eee;
    border-radius: 8px;
  }

  .line-card {
    margin-bottom: 20px;
  }

  .submit-creator {
    button {
      width: 100%;
    }
  }

  .w-70-100 {
    width: 100%;
  }

  .form-group-creator {
    display: block;
    input {
      font-size: 16px !important;
      width: 100% !important;
      height: auto !important;
    }

    select {
      font-size: 16px !important;
      width: 100% !important;
      height: auto !important;
    }
  }

  .d-flex-block {
    display: block !important;
  }

  .f-14px-16px {
    font-size: 16px !important;
  }

  .padding-responsive-0 {
    padding: 0 !important;
  }

  .Toastify__toast--success,
  .Toastify__toast--error {
    margin: 20px;
  }

  .generate-col-8 {
    flex: 0 0 auto;
    width: 100%;
  }

  .f-12px-16px {
    font-size: 16px;
  }

  .scrollable-table-support {
    padding: 0px 16px;
    width: 500px !important;
    overflow-x: scroll;
  }

  .flex-pill {
    padding: 0;
  }

  .p-24px-16px {
    padding: 16px;
  }

  .select-support-conteiner {
    margin: 0px;
    width: 100%;
    padding: 0px 16px;
  }

  .input-with-suffix {
    width: 100%;
  }

  .support-page {
    padding: 20px !important;
  }

  .support-header {
    .nav-top {
      padding: 12px 18px;
      display: flex;
      align-items: center;
    }

    .nav-center {
      font-size: 32px !important;
      line-height: 40px !important;
      margin-top: 15%;
    }
  }

  .responsive-font-18-16 {
    font-size: 16px !important;
  }

  .mediaNotfound {
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100% !important;
      margin: 15px 0px;
      font-size: 16px !important;
      height: 48px;
    }
  }

  .notFoundmessage {
    font-size: 16px !important;
    line-height: 20px;
    width: 100% !important;
  }

  .image-media-not-found {
    width: 100%;
  }

  .alretSection-mobile {
    margin: 15px 0px !important;

    p {
      font-size: 16px !important;
      line-height: 20px;
    }
  }

  .img-subscription {
    height: 64px !important;
    width: 64px !important;
    margin-bottom: 10px !important;
  }

  .username {
    font-size: 14px !important;
  }

  .responsive-size-14-16 {
    font-size: 16px;
  }

  .creator-name {
    font-size: 18px;
    line-height: 24px;
  }

  #placeholder-search-subscription {
    font-size: 16px !important;
    padding-left: 50px !important;
    background: transparent !important;
  }

  #search-subscription {
    transform: translateY(-4px);
  }

  .verify-button {
    width: 100%;
  }

  .name {
    font-size: 18px !important;
  }

  .userId {
    font-size: 16px !important;
  }

  .card {
    border-radius: 10px;
    border: 1px solid var(--border-day, #eee) !important;
  }
}

.f-14px {
  font-size: 14px !important;
}

.submit-creator {
  button {
    font-size: 16px;
    padding: 14px 24px;
  }
}

.d-flex-block {
  display: flex;
}

.check-creator {
  height: 20px !important;
  width: 20px !important;
  min-height: 20px !important;
  min-width: 20px !important;
}

.check-creator:checked {
  background-color: #c80000 !important;
}

.w-70 {
  width: 70% !important;
}

.f-16px {
  font-size: 16px !important;
}

.f-white {
  color: white;
}

.mw-90 {
  max-width: 90%;
}

.p-5px-30px {
  padding: 5px 30px;
}

.t-start {
  text-align: start;
}

.theme-dark .content-color {
  color: #fff !important;
}

.theme-dark {
  .checklist-color-base {
    label {
      color: #fff !important;
    }
  }
}

.checklist-color-base {
  label {
    color: #111 !important;
  }
}

.theme-dark .checklist-color ul {
  color: #fff !important;

  li {
    list-style: disc !important;
  }
}

.checklist-color ul {
  li {
    list-style: disc !important;
  }
}

.mlr-5 {
  margin-left: 5px;
  margin-right: 5px;
}

.queue-section {
  width: 100% !important;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
}

.grid-item {
  text-align: center !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.queue-datepicker {
  &.react-datepicker-popper {
    transform: translate(180px, 119px) !important;

    &.react-datepicker__triangle {
      transform: translate(0px, 0px) !important;
    }
  }
}

.react-datepicker__triangle {
  position: absolute;
  left: 30px !important;
  transform: translate3d(0px, 0px, 0px) !important;
}

.mt-100px {
  margin-top: 100px;
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .p-xs-0 {
    padding: 0 !important;
  }

  .rounded-xxl {
    border-radius: 0 !important;
  }
}

@media (min-width: 992px) {
  .grUxXV {
    width: 75% !important;
  }

  .hHJTFD {
    overflow: visible !important;
  }
}

.guIbWC {
  //Testa da rimuovere
  display: none;
}

@media (min-width: 576px) {
  .w-sm-25 {
    width: 25% !important;
  }

  .w-sm-50 {
    width: 50% !important;
  }

  .w-sm-75 {
    width: 75% !important;
  }

  .w-sm-100 {
    width: 100% !important;
  }
}

@media (min-width: 768px) {
  .w-md-25 {
    width: 25% !important;
  }

  .w-md-50 {
    width: 50% !important;
  }

  .w-md-75 {
    width: 75% !important;
  }

  .w-md-100 {
    width: 100% !important;
  }
}

@media (min-width: 992px) {
  .w-lg-25 {
    width: 25% !important;
  }

  .w-lg-50 {
    width: 50% !important;
  }

  .w-lg-75 {
    width: 75% !important;
  }

  .w-lg-100 {
    width: 100% !important;
  }

  .theme-dark {
    .all-bookmarks-header {
      position: fixed;
      z-index: 5;
      background-color: #1a2236;
    }

    .all-bookmarks-spacing {
      padding-top: 50px;
    }
  }

  .all-bookmarks-header {
    position: fixed;
    z-index: 5;
    background-color: #fbfcfe;
  }

  .all-bookmarks-spacing {
    padding-top: 50px;
  }
}

@media (min-width: 1200px) {
  .w-xl-25 {
    width: 25% !important;
  }

  .w-xl-50 {
    width: 50% !important;
  }

  .w-xl-75 {
    width: 75% !important;
  }

  .w-xl-100 {
    width: 100% !important;
  }
}

.account-form .react-tel-input {
  width: 80%;
}

.react-tel-input {
  .form-control {
    & {
      line-height: 50px;
      height: 50px;
      font-size: 14px;
      display: inline-block;
      border: 1px #eee solid;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      width: 100%;
      padding-left: 60px;
      border-radius: 25rem;
    }

    &:focus {
      border: 2px var(--theme-color) solid;
    }
  }

  .selected-flag {
    & {
      width: 50px;
      height: 100%;
      padding: 0 0 0 13px;
      border-radius: 0.25rem;
    }
    &:focus {
      background-color: #ffffff;
    }

    &:hover {
      background-color: #ffffff;
    }
  }

  .flag-dropdown {
    & {
      background-color: #ffffff;
      border: 2px solid #eee;
      border-radius: 25rem;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
    &:focus {
      border: 2px var(--theme-color) solid;
    }
  }

  .flag-dropdown.open {
    background: #ffffff;
    border-radius: 0.25rem;

    .selected-flag {
      & {
        background: #ffffff;
        border-radius: 0.25rem;
      }
      &:focus {
        border: 2px var(--theme-color) solid;
      }
    }
  }
}

.theme-dark .react-tel-input {
  .form-control {
    line-height: 50px;
    height: 50px;
    font-size: 16px;
    display: inline-block;
    border: 2px solid #242424;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 100%;
    border-radius: 25rem;
  }

  .selected-flag {
    & {
      width: 50px;
      height: 100%;
      padding: 0 0 0 13px;
      border-radius: 0.25rem;
    }

    &:focus {
      background-color: transparent !important;
    }

    &:hover {
      background-color: transparent !important;
    }
  }

  .flag-dropdown {
    background-color: transparent !important;
    border: 2px solid #242424;
    border-radius: 25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .flag-dropdown .open {
    background: #1a2236 !important;
    border-radius: 0.25rem;

    .selected-flag .open {
      background: #1a2236 !important;
      border-radius: 0.25rem;
    }
  }
}

.h-400px {
  height: 400px;
}

.h-50vh {
  height: 50vh;
}

.setting-icon {
  right: 20px;
}

.trendingImage {
  height: 100px;
  width: 100px;
}

.moon-icon {
  right: 70px;
}

.p-15px {
  padding: 10px !important;
}

.queue-media {
  border-radius: 10px;
  font-size: 0.875rem;
  height: 50px;
  position: relative;
  width: 50px;
  z-index: 2;
  object-fit: cover;
}

.bbrr-0 {
  border-bottom-right-radius: 0px !important;
}

.category-media {
  position: relative;
  z-index: 2;
  width: 50px;
  display: inline-block;

  img {
    width: 50px;
    height: 50px;
    border-radius: 10px;
  }
}

.item-container {
  .react-video-thumbnail-image {
    position: relative;
    z-index: 2;
    width: 50px;
    display: inline-block;
    margin-left: -15px;

    .category-media-image {
      width: 50px;
      height: 50px;
      border-radius: 10px;
    }
  }
}

.queue-list-wrapper {
  height: calc(100vh - 350px);
}

.scheduled-label {
  border-radius: 5px;
  color: white;
}

th[role="columnheader"] {
  font-size: 12px;
  font-weight: 700;
}

.fc-theme-standard td {
  border: 0 !important;
}

.fc-theme-standard th {
  border: 0 !important;
}

.fc-view {
  background-color: white;
}

.filter-clip {
  border: 1px solid var(--border-day, #eee);
  background: var(--background-day, #fff);
}

.fc-toolbar-title {
  font-size: 18px !important;
  font-weight: 700 !important;
  text-transform: capitalize;
}

.large-calendar .fc-daygrid-day-frame,
.large-calendar.fc-theme-standard td {
  height: 80px !important;
}

.side-bar-calendar .fc .fc-scrollgrid-section-header > * .fc-scroller-harness {
  border: none !important;
}

.side-bar-calendar td {
  max-height: 33px;
}

.side-bar-calendar .fc .fc-daygrid-day-frame {
  max-height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fc .fc-daygrid-day-number {
  color: var(--text-day, #111) !important;
  /* Extra Small/400 */
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  /* 120% */
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: transparent !important;

  .fc-daygrid-day-number {
    color: #ffffff !important;
    background: #2e69ff;
    border-radius: 50%;
    text-align: center;
    width: 23px;
    height: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.fc-daygrid-event-dot {
  display: none;
}

.fc-event-time {
  display: none;
}

.text-blue {
  color: var(--tertiary-blue-day, #2e69ff);
}

.mb-16px {
  margin-bottom: 16px;
}

.fc-daygrid-dot-event .fc-event-title {
  text-align: center;
  background-color: #c80000;
  color: white;
}

.fc {
  .fc-col-header-cell-cushion {
    color: gray !important;
  }
}

.voice-record {
  display: flex;
  justify-content: space-evenly;
  width: 50%;
  margin-left: auto;

  i {
    color: rgba(1, 75, 128, 1);
    font-size: 50px;
    margin-left: 10px;
    border-radius: 50%;
    border: none;
    background-color: rgba(255, 255, 255, 1);
  }

  .audioRecordButton {
    color: var(--text-day, #111);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: -0.42px;
  }
}

.voice-record {
  .active {
    i {
      color: #c80000;
    }
  }
}

.theme-dark {
  .audioRecordButton {
    color: #fff;
  }
}

.queue-box li a {
  padding-left: unset !important;
}

.flex-wrap {
  flex-wrap: wrap;
}

.loader2 {
  z-index: 999999;
  position: absolute;
  top: 0%;
  left: 0%;
  // transform: translate(-50%, -50%);
}

.dydPIA {
  margin: 0px !important;
}

.loader {
  z-index: 999999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-loader {
  position: relative;
  height: 25vh;
  z-index: 800;
  background-color: transparent;
  width: 100%;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  top: 0;
  left: 0;
}

.middle-sidebar-bottom {
  .loader {
    position: fixed;
    background: none;
  }

  .modal-loader {
    position: fixed;
    background: none;
  }
}

.main-overlay {
  position: fixed;

  width: 100%;

  height: 100%;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;

  z-index: 100;

  cursor: pointer;

  opacity: 0.4;
}

.theme-dark .main-overlay {
  // background-color: white;
  background-color: black;
  opacity: 0.7;
}

.cross-icon {
  right: 2px;
  top: 2px;
  z-index: 1;
}

.post-image-video {
  height: auto;
  width: 100%;
}

.chat-image-video {
  height: 100px;
  width: 100px;
  border-radius: 0.3rem;
  object-fit: cover;
}

.z-index-15 {
  z-index: 15 !important;
}

.z-index-99 {
  z-index: 99;
}

.block {
  display: block;
}

.reply-clip-confirm {
  position: absolute;
  width: calc(100%);
  height: 40px;
  color: black;
  background-color: #eeeeee;
  border-radius: 1px;
  bottom: 108px;
  left: 0px;
  padding-left: 10px;
  display: grid;
  align-content: center;
}

.final-amount-withdraw {
  color: var(--text-day, #111);
  text-align: right;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 28px;
  width: 50%;
}

.right {
  justify-content: right;
  align-items: center;
}

.feather-icon-color {
  color: #adb5bd !important;
}

.theme-dark.feather-icon-color {
  color: white !important;
}

.disable {
  pointer-events: none;
}

.comment-section {
  line-height: 1.5;
  text-align: left;
}

.comment-input {
  line-height: 50px;
  height: 50px;
  font-size: 14px;
  display: inline-block;
  border: 2px #eee solid;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  color: #515184;
  background-color: #fff;
  background-clip: padding-box;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.comment-area {
  .position-absolute__control {
    border-radius: 10px;

    textarea {
      line-height: 1.9;
    }
  }
}

.comment-area {
  .position-relative__control {
    border-radius: 10px;

    .position-relative__highlighter {
      min-height: 100px !important;
    }

    textarea {
      min-height: 100px !important;
      line-height: 1.7;
    }
  }
}

.hide-post-scroll {
  &::-webkit-scrollbar {
    width: 0.5px;
    background-color: white;
  }

  &::-webkit-scrollbar-thumb {
    background-color: white;
  }
}

.theme-dark {
  .hide-post-scroll {
    &::-webkit-scrollbar {
      width: 0.5px;
      background-color: #1a2236;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #1a2236;
    }
  }
}

.edit-post-text-new-fix-tab {
  color: #a3a3a3 !important;
  margin-left: 0px !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.hide-scroll {
  &::-webkit-scrollbar {
    width: 5px;
    background-color: white;
  }

  &::-webkit-scrollbar-thumb {
    background-color: white;
  }
}

.edit-post-text {
  color: #a3a3a3 !important;
  margin-left: 0.5rem;
}

.theme-dark {
  .edit-post-text {
    color: #707070 !important;
  }
}

.sort-by-container {
  padding: 0 !important;
}

.text-notifications-title {
  color: black;
  margin-left: 0.5rem;
}

.input-placeholder-text {
  color: black !important;
}

.theme-dark {
  .input-placeholder-text {
    color: white !important;
  }
}

.bookmark-color {
  color: #feca28;
}

.star-icon {
  z-index: 1;
  left: 40px;
  bottom: -6px;
}

.f-a3 {
  color: #a3a3a3 !important;
}

.comment-user-profile-figure {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.comment-emoji-wrap {
  position: absolute;

  width: 80%;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
  width: auto;
  background-color: #fff;
  // padding: 16px 16px;
  padding-top: 16px;
  border-radius: 30px;
  display: none;
  z-index: 9999;
}

.theme-dark {
  .comment-emoji-wrap {
    background: var(--background-night, #111);
  }

  .emoji-wrap {
    background: var(--background-night, #111);
  }
}

.comment-emoji-wrap.active {
  display: block;
}

.post-image-container {
  border-radius: 8px !important;
  height: auto !important;
  width: auto !important;
  object-fit: cover !important;
  // min-height: 200px;

  video {
    height: 100px !important;
    max-width: 150px !important;
    object-fit: cover !important;
  }

  .react-player__preview {
    height: 100px !important;
    max-width: 150px !important;
    border-radius: 0.3rem !important;
  }
}

.chat-image-container {
  height: 200px !important;
  width: 200px !important;
  object-fit: cover !important;

  video {
    height: 200px !important;
    max-width: 200px !important;
    object-fit: cover !important;
    border-radius: 0.6rem !important;
  }

  .react-player__preview {
    height: 200px !important;
    max-width: 200px !important;
    border-radius: 0.6rem !important;
  }
}

.kdCslO {
  display: none !important;
}

.jNVyoz {
  align-items: center;
}

.show-post-image {
  height: 215px;
  width: 230px;
  object-fit: cover !important;

  video {
    height: 215px !important;
    width: 230px !important;
    object-fit: cover !important;
  }

  .react-player__preview {
    height: 215px !important;
    width: 230px !important;
    border-radius: 0.3rem !important;
  }
}

.modal-post-image {
  height: 150px !important;
  width: 175px !important;
  object-fit: cover !important;

  video {
    height: 150px !important;
    width: 160px !important;
    object-fit: cover !important;
  }

  .react-player__preview {
    height: 150px !important;
    width: 175px !important;
    border-radius: 0.3rem !important;
  }
}

.activeCard {
  font-weight: 700;
  color: var(--theme-color) !important;
  border-bottom: 1px solid var(--theme-color) !important;

  .count-subscription-man {
    background-color: #d5e1ff;
  }
}

.bookmark-image {
  height: 215px !important;
  width: 200px !important;
  object-fit: cover !important;
}

.bookmark-video {
  video {
    height: 215px !important;
    width: 200px !important;
    object-fit: cover !important;
  }

  .react-player__preview {
    height: 215px !important;
    width: 210px !important;
    border-radius: 0.3rem !important;
  }
}

.react-loading-skeleton {
  --base-color: #eee !important;
  --highlight-color: #adb5bd !important;
}

.theme-dark {
  .react-loading-skeleton {
    --base-color: #eee !important;
    --highlight-color: #adb5bd !important;
  }
}

.chat-skeleton-color {
  .react-loading-skeleton {
    --base-color: #eee !important;
    --highlight-color: #adb5bd !important;
  }
}

.theme-dark {
  .chat-skeleton-color {
    .react-loading-skeleton {
      --base-color: #1a2236 !important;
      --highlight-color: #293145 !important;
    }
  }
}

.chat-body-skeleton-color {
  .react-loading-skeleton {
    --base-color: #eee !important;
    --highlight-color: #adb5bd !important;
  }
}

.theme-dark {
  .chat-body-skeleton-color {
    .react-loading-skeleton {
      --base-color: #293145 !important;
      --highlight-color: #1a2236 !important;
    }
  }
}

.video-overlay {
  position: absolute;
  height: calc(100vh - 20px);
  max-width: 1000px;
  background: #fff !important;
  z-index: 800;
}

.theme-dark {
  .video-overlay {
    position: absolute;
    height: calc(100vh - 20px);
    max-width: 1000px;
    background: #1a2236 !important;
    z-index: 800;
  }
}

.blur-box {
  filter: blur(6px);
  user-select: none;
  pointer-events: none;
  height: 300px;
}

.unlock-text {
  width: 80%;
  position: absolute;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  background-color: #262d3482;
  border-radius: 15px;
}

.theme-dark {
  .unlock-text {
    width: 80%;
    position: absolute;
    z-index: 100;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    background-color: #e8f4ff4f;
    border-radius: 15px;
  }
}

.h30 {
  height: 30px !important;
}

.pt-50px {
  padding-top: 50px;
}

.pt-70px {
  padding-top: 70px;
}

.pt-100px {
  padding-top: 100px;
}

.pb-100px {
  padding-bottom: 100px;
}

.pt-120px {
  padding-top: 120px;
}

.pt-150px {
  padding-top: 150px;
}

.left-30 {
  left: 30px;
}

.bookmark-dropdown-icon {
  padding-left: 0;
  padding-right: 25px;
}

.bookmark-dropdown-list {
  right: 10% !important;
  top: 85px !important;
}

.chat-dropdown-list {
  right: 5% !important;
  top: 13% !important;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.bookmark-list-icon {
  position: absolute;
  z-index: 15;
  right: 0% !important;
  top: 49px !important;
}

.withdraw-select {
  border: 1px solid var(--border-day, #eee);
  background: var(--background-day, #fff);
  padding: 16px;
}

.bg-green {
  background-color: #4cd964;
}

.bg-cyan-gradiant {
  background: linear-gradient(to right, #00a9a8, #086e6e) !important;
}

.bg-darkpink-gradiant {
  background: linear-gradient(to right, #e2446c, #721d32) !important;
}

.bg-blue-green-gradiant {
  background: linear-gradient(to left, #8fff85, #39a0ff);
}

.bg-gray {
  background-color: #eeeeee;
}

.text-gray {
  color: #858585 !important;
}

.text-green {
  color: #4ecc6a !important;
}

.vault-modal-checkbox-image-div {
  top: 0% !important;
  right: 4% !important;
  z-index: 5;

  .form-check-input:checked {
    background-color: #c80000 !important;
    border-color: #c80000 !important;
  }

  .form-check-input:focus {
    border-color: #000000 !important;
  }
}

.chat-checkbox {
  padding-top: 3px;

  .form-check-input {
    border-color: #eeeeee;
    border-radius: 50% !important;
    cursor: pointer;
  }

  .form-check-input:checked {
    background-color: #c80000 !important;
    border-color: #c80000 !important;
  }
}

.checkbox-image-div {
  top: 0% !important;
  right: 4% !important;
  z-index: 5;

  .form-check-input:checked {
    background-color: #c80000 !important;
    border-color: #c80000 !important;
  }

  .form-check-input:focus {
    border-color: #000000 !important;
  }
}

.checkbox-image {
  background-color: rgba(17, 17, 17, 0.5) !important;
  border: 1px solid #c80000;
  height: 22px !important;
  width: 22px !important;
}

.vault-heading {
  background: #fbfcfe;
  z-index: 9;
  height: 100px;
}

.theme-dark {
  .vault-heading {
    background: #1a2236;
    z-index: 9;
    height: 100px;
  }

  .notificationCard {
    background-color: #111;
    border: 1px solid #242424;
  }

  .notification-rest {
    color: #707070;
  }

  .count {
    background-color: #242424;
  }
}

.mt-30px {
  margin-top: 30px;
}

.mt-50px {
  margin-top: 50px;
}

.mt-100px {
  margin-top: 100px;
}

.mt-110px {
  margin-top: 110px;
}

.add-new-category {
  color: #1683ff;
}

.blurModal {
  opacity: 0.5;
}

.modal-300w {
  width: 300px;
}

.mh-380px {
  max-height: 380px;
}

.vertical-center {
  vertical-align: middle !important;
}

.btn-small {
  width: 30px !important;
  height: 30px !important;
  border-radius: 45px;
  display: inline-block;
  line-height: 30px !important;
  text-align: center;
}

.mediaCategory-dropdown-list {
  position: absolute;
  right: 45% !important;
}

.category-list-div {
  width: 100% !important;
  margin: 0px 10px;
  display: block;

  span {
    margin-top: 10px;
  }
}

.notification-profile-image {
  min-width: 40px !important;
  height: 40px !important;
  object-fit: cover !important;
  border-radius: 50% !important;
  background-color: #ffffff;
  max-width: 40px !important;
}

.notification-count {
  top: 0;
  left: 24px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  padding-left: 3px !important;
  padding-right: 3px !important;
  line-height: 19px !important;
  color: #1e74fd !important;
  background-color: #d2e3ff !important;
  border-color: #bcd5fe !important;
}

.message-notification-count {
  top: 6px;
  left: 22px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  line-height: 19px !important;
  background-color: #000000 !important;
}

.modal-text-black {
  color: #000 !important;
}

.media-button-chat {
  border: 0;
  width: 40px;
  height: 40px;
  border-radius: 20px;
}

.chat-image-box {
  width: calc(100% - 90px);
  color: #ddd;
  border-radius: 10px;
  // float: left;
  margin: 0 5px 0 0;
  position: relative;
}

.bg-muted {
  background-color: #aeaed5 !important;
}

.bg-grey {
  background-color: rgba(17, 17, 17, 0.1);
}

.chat-send-button {
  border: 0;
  width: 40px;
  height: 40px;
  border-radius: 20px;
}

.chat-media-box {
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.chat-top {
  position: absolute;
  z-index: 11;
  width: 98%;
  background-color: #fff;
}

.no-chat-message {
  height: calc(100vh - 305px) !important;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-unset {
  top: unset !important;
}

.right-4 {
  right: 25px !important;
}

.right-5 {
  right: 15px !important;
}

@media screen and (max-width: 512px) {
  .fc-today-button {
    margin-bottom: 5px !important;
    margin-left: 0.75em !important;
  }
}

.theme-dark {
  .dropdown-menu {
    background-color: #111 !important;
    border: 1px solid #242424;
  }

  .modal-text-black {
    color: #000 !important;
  }
}

.infinite-scroll-component__outerdiv {
  height: 100% !important;
}

.w-270 {
  width: 270px;
}

.pricing-card {
  transition: transform 0.5s;
}

.pricing-card:hover {
  transform: scale(1.05);
  transition: transform 0.5s;
}

.price-header {
  background-image: linear-gradient(
    to right,
    #71c5ee,
    #53a7d8,
    #3889c1,
    #1e6caa,
    #025091
  );
}

.price-button,
.price-button:hover,
.price-button:focus {
  background-color: #99d5f8;
  color: #025091;
  border: none;
}

.clear-search-button,
.clear-search-button:hover,
.clear-search-button:focus {
  background-color: #57525277;
  color: #000000;
  border: none;
}

.stripe-input {
  width: 100%;
  border: 1px solid $dark-white-color;
  font-size: 14px;
}

.bg-payment-white {
  background-color: #fff !important;
}

.theme-dark {
  .bg-payment-white {
    background-color: #111 !important;
  }
}

.payment-checkout-header {
  background-image: linear-gradient(
    to right,
    #71c5ee,
    #53a7d8,
    #3889c1,
    #1e6caa,
    #025091
  );
  border-radius: 5px 5px 0 0;
}

.form-error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.w-95 {
  width: 95%;
}

.w-90 {
  width: 90%;
}

.w-15 {
  width: 15%;
}

.w-25 {
  width: 25%;
}

.w-40 {
  width: 40%;
}

.lh-20 {
  line-height: 20px;
}

.lh-25 {
  line-height: 25px !important;
}

.bg-blue-gradient {
  background-image: linear-gradient(
    to right,
    #71c5ee,
    #53a7d8,
    #3889c1,
    #1e6caa,
    #025091
  );
}

.bg-black-gradient {
  background-image: linear-gradient(
    to right,
    #999999,
    #777777,
    #555555,
    #333333,
    #111111
  );
}

.bg-light-black-gradient {
  background-image: linear-gradient(to right, #111111, #333333, #555555);
}

.border-top-right {
  border-radius: 5px 5px 0 0;
}

.border-bottom-left {
  border-radius: 0 0 5px 5px;
}

.border-bottom-active {
  border-bottom: 1px solid #2e69ff !important;
  // border-radius: 2px;
}

.theme-dark {
  .border-bottom-active {
    border-bottom: 1px solid #2e69ff !important;
    // border-radius: 2px;
  }
}

.edit-subscription-plan-button,
.edit-subscription-plan-button:hover,
.edit-subscription-plan-button:focus {
  background-color: #99d5f8;
  color: #025091;
  border: none;
}

.delete-subscription-plan-button,
.delete-subscription-plan-button:hover,
.delete-subscription-plan-button:focus {
  background-color: #f5aa9c;
  color: #ce2014;
  border: none;
}

.delete-subscription-plan-button-disabled,
.delete-subscription-plan-button-disabled:hover,
.delete-subscription-plan-button-disabled:focus {
  background-color: #f5aa9c;
  color: #ce2014;
  border: none;
  opacity: 0.7;
}

.p-10px {
  padding: 10px !important;
}

.p-6px {
  padding: 6px !important;
}

.p-50px {
  padding: 50px !important;
}

.height-265px {
  min-height: 265px !important;
}

.height-80 {
  height: 80%;
}

.input-search-icon {
  margin-left: -38px !important;
  margin-right: 12.58px !important;
  margin-top: 11px !important;
  z-index: 2 !important;
  display: none !important;
}

.active-status-button {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50% !important;
  display: inline-block;
  line-height: 10px !important;
  text-align: center;
  top: 36px;
  left: 38px;
}

.text-user-name {
  color: #858585;
}

.bottom-3 {
  bottom: 3px;
}

.bottom-40px {
  bottom: 40px;
}

.top-50px {
  top: 50px;
}

.top-100px {
  top: 100px;
}

.left-10px {
  left: 10px;
}

.object-fit-cover {
  object-fit: cover !important;
}

.bg-dark-green {
  background-color: #228b22;
}

.bg-light-red {
  background-color: #fabcbc;
}

.bg-red {
  background-color: #d40000 !important;
}

.border-black {
  border-color: #000;
}

.unsubscribe-button,
.subscriptionSubscibe,
.unsubscribe-button:hover,
.subscriptionSubscibe:active,
.subscriptionSubscibe:focus {
  background-color: #d40000 !important;
  border-color: #d40000 !important;
}

.creatorButtonSubscribe {
  background-color: transparent;
  color: #d40000;
  font-size: 12px !important;
  font-weight: 700;
  border: 1px solid #d40000;
  width: 80% !important;
  margin-bottom: 1.5rem;
}

.creatorButton {
  background-color: #d40000;
  border: 1px solid #d40000;
  width: 80% !important;
  margin-bottom: 1.5rem;
  font-size: 12px !important;
  display: flex !important;
}

.button-sction {
  text-align: center;
}

@media (min-width: 513px) {
  .fix-content-subscription {
    padding-top: 24px;
    padding-left: 120px;
    padding-right: 120px;
    width: 100%;
    height: calc(100dvh - 64px);
    overflow-y: scroll;
  }
}

.lockText {
  align-self: center;
  font-size: 28px;
  font-weight: 800;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}

.padding-right64px {
  padding-right: 64px !important;
}

.creatorHeader {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.becomeACreatorButton {
  height: 48px;
}

@media (min-width: 513px) {
  .becomeACreatorButton {
    height: 36px !important;
  }
}

.subscriptionFilter {
  background-color: transparent;
  color: #a3a3a3;
  border: 1px solid #eeeeee;
  border-radius: 25rem;
  height: 48px;
}

@media (min-width: 513px) {
  .subscriptionFilter {
    height: 36px !important;
  }
}

.subscriptionFilter:hover {
  background-color: transparent;
  color: #a3a3a3;
  border: 1px solid #eeeeee;
  border-radius: 25rem;
}

.subscriptionFilter:focus {
  background-color: transparent;
  color: #a3a3a3;
  border: 1px solid #eeeeee;
  border-radius: 25rem;
}

.creatorModal .btn-primary:hover {
  background-color: transparent;
  color: #a3a3a3;
  border: 1px solid #a3a3a3;
}

.creatorModal .btn-primary:active {
  background-color: transparent;
  color: #a3a3a3;
}

.reSubscribe {
  background-color: #a3a3a3;
  border-color: #a3a3a3;
}

.text-green {
  color: #228b22;
}

.text-red {
  color: #ff2800 !important;
}

.text-light-red {
  color: #d40000 !important;
}

.text-444444 {
  color: #444444;
}

.text-555555 {
  color: #555555;
}

.text-c7c9cc {
  color: #c7c9cc;
}

.theme-btn {
  color: #555555 !important;
}

.theme-dark {
  .theme-btn {
    color: #ffffff !important;
  }
}

.gray-text-important {
  color: gray !important;
}

.opacity-07 {
  opacity: 0.7;
}

.text-dark-gray {
  color: #777777;
}

.chat-people-selected {
  background-color: #eeeeee;
  border-radius: 28px;
}

.nav-content-btn {
  color: #a3a3a3 !important;

  span {
    color: #a3a3a3 !important;
  }
}

.nav-content-btn:hover {
  color: #c80000 !important;

  span {
    color: #c80000 !important;
  }
}

@media (max-width: 512px) {
  .overflow-scroll-mobile {
    overflow-y: scroll;
  }

  .creatorButtonSubscribe {
    margin-bottom: 20px !important;
  }
}

.nav-tab-active {
  color: #c80000 !important;
  padding-left: 20px !important;
  display: inline-block;
  line-height: 45px !important;
  text-align: center;
  border-left: 4px solid #c80000;
}

.error-feedback {
  text-align: left;
  color: var(--bs-danger);
  font-size: 12px;
}

.line-card-table {
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 24px;
  border: 1px solid #eee;
  background-color: white;
  width: 100%;
}

.fakeinput {
  border: 1px solid #eee;
  border-radius: 40px;
  width: 60%;
  padding: 5px 15px;
  display: flex;
  align-items: center;
}

.btn-outline-red {
  border: 2px solid #c80000;
  border-radius: 40px;
  color: #c80000;
  background-color: transparent;
  padding: 5px 15px;
  font-weight: 700;
}

.nav-tab-active span {
  color: #c80000 !important;
}

.bg-white-black {
  background-color: white;
}

.mh-332px {
  max-height: 332px;
}

.bg-fafafa {
  background-color: #fafafa;
}

.fc-theme-standard .fc-popover-header {
  display: none !important;
}

.fc-daygrid-dot-event:hover {
  background-color: transparent !important;
}

.event-clip {
  .fc-event-title {
    background-color: #4ecc6a !important;
  }
}

.event-chat {
  .fc-event-title {
    background-color: #f2994a !important;
  }
}

.event-video {
  .fc-event-title {
    background-color: #cc0000 !important;
  }
}

.event-text {
  .fc-event-title {
    background-color: #2e69ff !important;
  }
}

.ml-2px {
  margin-left: 2px !important;
}

.tip-message-container {
  word-break: break-word;
  border-radius: 8px;
  margin-top: 5px;
  background: var(--border-day, #eee);
  display: flex;
  padding: 8px 16px;
  align-items: flex-start;
  gap: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  max-width: fit-content;
  letter-spacing: -0.42px;
}

.event-image {
  .fc-event-title {
    background-color: #cc0000 !important;
  }
}

.event-message {
  .fc-event-title {
    background-color: #f2994a !important;
  }
}

.fc-theme-standard .fc-popover {
  border-radius: 8px;
  border: 1px solid var(--border-day, #eee) !important;
  background: var(--background-day, #fff) !important;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1) !important;
}

.f-10px {
  font-size: 10px !important;
}

.highlighted-comment {
  background-color: #eee;
  /* o il colore che preferisci */
  transition: background-color 5s ease-in-out;
  /* animazione di dissolvenza */
}

.mx-2px {
  margin: 0px 2px;
}

.mr-2px {
  margin-right: 2px;
}

.MuiClock-pin,
.MuiClockPointer-thumb,
.PrivatePickersYear-yearButton {
  background: #cc0000 !important;
  color: white !important;
}

.p-8px-20px {
  padding: 8px 20px;
}

.p-12px-20px {
  padding: 12px 20px;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #a3a3a3 !important;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #a3a3a3 !important;
}

/* .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #000 !important;
}


.css-u9osun.Mui-focused {
  color: #000 !important;
} */

.fc .fc-daygrid-day-bottom {
  display: flex;
  justify-content: center;
}

.fc .fc-daygrid-more-link {
  position: relative;
  z-index: 4;
  cursor: pointer;
  background-color: #111;
  border-radius: 4px;
  width: 80px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 700;
}

.circle-icon {
  color: #d40000;
  border-radius: 50%;
  padding: 10px;
  border: 3px solid #d40000;
  width: 50px;
  height: 50px;
}

.h-32px {
  height: 32px !important;
}

.w-32px {
  width: 32px !important;
}

.chat-user-image {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  min-width: 36px;
  min-height: 36px;
}

.chat-user-search {
  border: none;
}

input[type="radio"] {
  accent-color: #c80000;
}

input[type="radio"]#Black {
  accent-color: #c80000;
}

.bookmarkModal input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  width: 16px;
  height: 16px;
  padding: 3px;

  background-clip: content-box;
  border: 1px solid #bbbbbb;
  background-color: transparent;
  border-radius: 50%;
}

.subscriptionModal input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  width: 16px;
  height: 16px;
  padding: 3px;

  background-clip: content-box;
  border: 1px solid #bbbbbb;
  background-color: transparent;
  border-radius: 50%;
}

.checkout-option input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  width: 16px;
  height: 16px;
  padding: 3px;

  background-clip: content-box;
  border: 1px solid #bbbbbb;
  background-color: transparent;
  border-radius: 50%;
}

.checkout-option input[type="radio"]:checked {
  background-color: #c80000;
  border: 1px solid #c80000;
}

.chat-user-search::placeholder {
  color: #a3a3a3;
  font-weight: 400;
}

.border-none {
  border: none !important;
}

.new-message-count {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #000000;
}

.pb-30px {
  padding-bottom: 30px;
}

.mb-30px {
  margin-bottom: 35px;
}

.clear-message-button:hover {
  background-color: #ecb0b0;
  color: #d40000 !important;
  border-radius: 50%;
  text-align: center;
  padding: 5px;
}

.bookmarkModal input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  width: 16px;
  height: 16px;
  padding: 3px;

  background-clip: content-box;
  border: 1px solid #bbbbbb;
  background-color: transparent;
  border-radius: 50%;
}

.subscriptionModal input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  width: 16px;
  height: 16px;
  padding: 3px;

  background-clip: content-box;
  border: 1px solid #bbbbbb;
  background-color: transparent;
  border-radius: 50%;
}

.checkout-option input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  width: 16px;
  height: 16px;
  padding: 3px;

  background-clip: content-box;
  border: 1px solid #bbbbbb;
  background-color: transparent;
  border-radius: 50%;
}

.checkout-option input[type="radio"]:checked {
  background-color: #c80000;
  border: 1px solid #c80000;
}

.bookmarkModal input[type="radio"]:checked {
  background-color: #c80000;
  border: 1px solid #c80000;
}

.subscriptionModal input[type="radio"]:checked {
  background-color: #c80000;
  border: 1px solid #c80000;
}

.creatorModal input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  width: 16px;
  height: 16px;
  padding: 3px;

  background-clip: content-box;
  border: 1px solid #bbbbbb;
  background-color: transparent;
  border-radius: 50%;
}

.input-search-clip input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  width: 16px;
  height: 16px;
  padding: 3px;

  background-clip: content-box;
  border: 1px solid #eeeeee;
  background-color: transparent;
  border-radius: 50%;
}

.input-search-clip input[type="radio"]:checked {
  background-color: #c80000;
  border: 1px solid #c80000;
}

.input-search-clip input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 2px solid #eeeeee;
  background-color: white;
  outline: none;
  cursor: pointer;
  position: relative;
}

.input-search-clip input[type="checkbox"]:checked {
  border-color: #c80000;
  background-color: #c80000;
}

.input-search-clip input[type="checkbox"]::before {
  // content: "\2705";
  // content: "✔";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  color: white;
  opacity: 0;
}

.checkbox-check-icon {
  color: white;
  font-size: 9px;
  position: absolute;
  left: 4px;
  z-index: 1;
  cursor: pointer;
  top: -4.5px;
}

.text-filter-clip-second-bar {
  color: var(--text-day, #111) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 20px !important;
  letter-spacing: -0.42px !important;
}

.theme-dark {
  .text-filter-clip-second-bar {
    color: #ffffff !important;
  }
}

.width99px {
  width: 99px !important;
}

.input-search-clip input[type="checkbox"]:checked::before {
  opacity: 1;
}

.creatorModal input[type="radio"]:checked {
  background-color: #c80000;
  border: 1px solid #c80000;
}

.bookmark-post {
  padding: 24px 32px;
}

.empty-state-chat {
  background-color: #1111111a;
  font-size: 14px;
}

.bookmark-menu {
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 14px;
  padding-top: 24px;
  padding-left: 32px;
  width: 90%;
  margin: 0 auto;
}

.search-input {
  background-color: #fafafa;

  input {
    background-color: transparent;
  }
}

.find-creator-avatar {
  height: 80px !important;
  width: 80px !important;
  border-radius: 100%;
  margin: 0 auto;
  object-fit: cover;
  // padding-top: 40px;
  position: relative;
  border: 2px solid white;
}

.creatorprofile {
  margin-top: 4rem;
}

.creatorId {
  font-size: 12;
  font-weight: 400;
  color: #a3a3a3;
}

.profileDetails {
  text-align: center;
}

.detailTitle {
  font-size: 10px !important;
}

.profile-name-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}

.profile-count {
  font-weight: 700;
  font-size: 12px;
}

.profile-title {
  font-weight: 400;
  font-size: 10px;
}

.nav-content {
  background-color: white;
}

.navbar-container {
  margin-top: 24px;
  padding-bottom: 40px;
}

.navbar-container ul {
  display: flex;
  gap: 8px;
}

.navbar-item {
  display: flex;
  align-items: center;
  height: 36px;
  padding-left: 0px;
}

.feeds-title {
  font-weight: 800;
  font-size: 20px;
  color: #111111;
  margin-bottom: 16px;
}

.post-container {
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 24px 0px;
  margin-bottom: 18px;
}

.clip-container {
  border: 1px solid #eee;
  border-radius: 8px 8px 0px 0px;
  padding: 24px 0px;
}

.modal-header-custom {
  color: #111;
  font-size: 12px;
  font-weight: 700;
}

select {
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: "";
}

.post-input {
  border: 0;
  width: 100%;
  height: 50px;
}

.advanced-title {
  font-weight: 700;
  font-size: 10px;
  color: #a3a3a3;
}

.advance-icon-update-clip {
  margin-top: 8px;
}

.advanced-features {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: #d40000 !important;
  border-radius: 50%;
  text-align: center;
}

.vault-container {
  display: flex;
  align-items: center;
  gap: 12px;
}

.creator-card-container {
  background-color: white;
  border: 1px solid #eee;
  margin-top: 16px;
  border-radius: 8px;
}

.settings-item {
  font-size: 14px;
  color: #111111;
}

.padding-l-10 {
  padding-left: 10px;
}

.padding-lr-19 {
  padding: 0 19px;
}

.mh-100px {
  max-height: 100px;
}

.setting-item-active {
  font-weight: 700;
  border-left: 3px solid #111;
}

.theme-dark .setting-item-active {
  border-left: 3px solid #fff;
}

@media (min-width: 513px) {
  .fix-content-bookmark {
    margin: 0 0 0 120px !important;
  }
}

.become-creator-button {
  margin-left: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  padding: 8px 24px;
  color: white;
  border: 0;
  font-size: 14px;
  background: linear-gradient(0.25turn, #111, #cc0000);
}

.setting-item-span {
  padding-left: 2rem;
  display: block;
}

.active-tab-color {
  color: #2e69ff;
}

.chat-menu-item:hover {
  span {
    color: #20a7db !important;
  }

  & {
    color: #20a7db !important;
    background-color: #cfecf7;
    border-radius: 5px;
  }
}

.post-avatar {
  background-color: white !important;
  height: 32px;
  min-width: 32px;
  margin-right: 12px;
  border-radius: 100%;
}

.post-dropdown-item {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #a3a3a3;
  cursor: pointer;
}

.post-dropdown-item:hover {
  color: #c80000;
}

.post-features {
  color: #111111;
}

@media (max-width: 512px) {
  .fix-mobile-picture-creator {
    width: 160px;
    height: 0px;
  }

  .fix-subscription-managment-aling {
    text-align: center !important;
  }

  .ultra-fix-creator-profile {
    text-align: -webkit-center;
  }
}

.comment-preview-text {
  font-size: 12px;
  font-weight: 400;
  color: #111;
  margin-left: 16px;
}

.fix-extra-class-extra-profile {
  text-align: -webkit-center;
  position: relative;
}

@media (min-width: 513px) {
  .post-features-text {
    font-size: 12px;
    font-weight: 400;
    margin-left: 4px;
  }
}

.mb-10px {
  margin-bottom: 10px;
}

.mw-275px {
  max-width: 275px;
}

.name {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (max-width: 512px) {
  .post-features-text {
    font-size: 12px;
    font-weight: 400;
    margin-left: 0px;
  }

  .post-features-text {
    display: inline-block;
    max-width: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.calendarView .fc-view {
  border: 1px solid #eee;
  border-radius: 8px;
}

.notificationCard {
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  margin-top: 1rem;
}

.text-align-end {
  text-align: end;
}

.notifiCationHeading {
  font-size: 20px;
}

.tabSection {
  padding: 1rem 1rem 0 1rem;
}

.border-select-accout {
  border-radius: 36px;
  border: 1px solid var(--border-day, #eee);
  background: var(--background-day, #fff);
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.selected-avatars {
  position: absolute;
  bottom: 30px;
  padding-bottom: 120px !important;
}

.message-received {
  border-radius: 10px 10px 10px 0;
  padding-left: 14px;
  padding-right: 14px;
}

.amount-max {
  right: 25px;
  color: var(--primary-red-night, #c00);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.42px;
}

.amount-max:hover {
  cursor: pointer;
}

.hover {
  cursor: pointer;
}

.stories-list {
  overflow-x: scroll;
  max-width: calc(100vw - 30px);
  padding-top: 6px;
  padding-left: 6px;
}

.clip-lock-box {
  width: 80px;
  height: 80px;
  border-radius: 48px;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 56px;
  font-size: 42px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-lock-box {
  width: 32px;
  height: 32px;
  border-radius: 80px;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 56px;
  font-size: 16px;
  color: white;
  justify-content: center;
  display: flex;
  align-items: center;
}

.chat-lock-box-fancy {
  width: 64px;
  height: 64px;
  border-radius: 80px;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 56px;
  font-size: 32px;
  color: white;
  justify-content: center;
  display: flex;
  align-items: center;
}

.clip-is-locked {
  color: #fff;
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: 36px;
  margin-top: 12px;
}

.width-button-buy-clip {
  margin-top: 12px;
  width: 302px !important;
}

.mt-12px {
  margin-top: 12px;
}

.main-content-clip {
  overflow-x: hidden;
  margin-top: 0px;
  z-index: 99;
  position: relative;
  padding-left: 0px !important;
  padding-left: 0px !important;
  background: var(--background-alt-day, #fafafa);
}

.theme-dark {
  .main-content-clip {
    background: var(--background-alt-day, #2b2b2b);
  }
}

.container-clip {
  width: 100%;
  align-self: center;
  text-align-last: center;
  text-align: -webkit-center;
  overflow: hidden;
  height: 100vh;
  display: grid;
  // justify-items: center;
  align-content: center;
  place-items: center;
}

@media (max-width: 512px) {
  .container-clip {
    width: 100%;
    // margin-top: 64px !important;
    align-self: center;
    text-align-last: center;
    text-align: -webkit-center;
    overflow: hidden;
    height: 100%;
    display: grid;
    justify-items: center;
    align-content: center;
  }
}

.nobar::-webkit-scrollbar {
  width: 0px;
  background: transparent;
  display: none;
}

.showBar {
  &::-webkit-scrollbar {
    width: 5px !important;
    display: inline !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: white !important;
    border-radius: 99px;
  }
}

.theme-light {
  .showBar {
    &::-webkit-scrollbar {
      width: 5px !important;
      display: inline !important;
    }

    &::-webkit-scrollbar-thumb {
      background-color: black !important;
      border-radius: 99px;
    }
  }
}

.stories-page {
  z-index: 1000;
  position: absolute;
  background-color: white;
  width: 100vw;
  height: 100dvh;
  left: 0px;
  top: 0px;
  display: flex;
  align-items: center;
  place-content: center;
  overflow: hidden;
}

.stories-page-single {
  z-index: 1000;
  position: absolute;
  background-color: white;
  width: 100vw;
  height: 100dvh;
  left: 0px;
  top: 0px;
  display: grid;
  align-items: center;
  place-content: center;
  overflow: hidden;
}

.theme-dark {
  .stories-page {
    background: var(--background-alt-night, #2b2b2b);
  }

  .stories-page-single {
    background: var(--background-alt-night, #2b2b2b);
  }
}

.message-story {
  background-color: transparent;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  border-color: transparent;
}

@media (min-width: 513px) {
  .message-story {
    width: calc(48.6111vh - 70px) !important;
  }
}

.border-message-story {
  border-radius: 42px;
  border: 1px solid white;
  z-index: 1000;
}

@media (min-width: 513px) {
  .story-card-current {
    height: 86.6666666vh;
    flex-shrink: 0;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    overflow: visible;

    video {
      width: 100% !important;
      height: 86.6666666vh !important;
      object-fit: cover !important;
    }
  }
}

.relative {
  position: relative;
}

@media (min-width: 513px) {
  .clip-card-current {
    height: 86.6666666vh;
    flex-shrink: 0;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    overflow: visible;
    margin-top: 12px;
    margin-bottom: 12px;

    video {
      width: 100% !important;
      height: 86.6666666vh !important;
      object-fit: cover !important;
    }
  }
}

@media (min-width: 513px) {
  .story-style {
    border-radius: 8px;
    overflow: hidden;
  }

  .fix-width-chat0px {
    width: calc(50% + 0px) !important;
  }
}

@media (max-width: 512px) {
  .mobile-clips {
    height: calc(100dvh - 64px);
    width: 100vw;
    z-index: 666;
    // position: absolute;
    // left: 0px;
    // top: 0px;
    background-color: black;
    margin: 0px !important;
    padding: 0px !important;
  }
}

.guIbWC {
  overflow: visible !important;
}

.h-0 {
  height: 0px !important;
}

.story-creator-icon {
  position: relative;
  top: 23.5px;
  padding-left: 10px;
  z-index: 900;
  width: 0;
}

.clip-creator-icon {
  position: absolute;
  bottom: 10px;
  left: 24px;
  z-index: 5000;
  // width: 0;
}

.clip-creator-icon-profile {
  position: absolute;
  bottom: 10px;
  // left: 4px;
  z-index: 25;
  width: 100%;
}

.mobile-add-clip {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  border: 1px solid var(--border-day, #eee);
  background: var(--background-day, #fff);
  color: black;
  z-index: 999999;
  align-items: center;
  display: inline-flex;
  justify-content: space-evenly;
}

.add-user-to-group {
  width: 32px;
  height: 32px;
  border-radius: 32px;
  border: 1px solid var(--border-day, #eee);
  background: var(--background-day, #fff);
  color: black;
  z-index: 10;
}

.picture-story {
  border-radius: 32px !important;
  overflow: hidden;
  object-fit: cover;
  height: 32px !important;
  width: 32px !important;
}

.comment-user-data {
  margin-bottom: 8px;
}

.story-creators-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--text-night, #fff);
  text-align: left;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.48px;
  width: 200px;
  overflow: hidden;
  margin-left: 8px;
  margin-top: 5px;
  text-align-last: left;
  z-index: 9999;
}

.white-text {
  color: var(--text-night, #fff) !important;
}

.class-test:focus {
  background-color: green;
  color: blue;
}

.fix-button-like {
  border: 0px;
  background-color: transparent;
}

.theme-dark {
  .fix-button-like {
    color: #ffffff;
  }
}

// .class-test:hover{
//   background-color: green;
//   color: blue;
// }

.post-description {
  overflow: hidden;
  max-width: 100%;
}

.container-archive-story {
  overflow: auto;
  padding-bottom: 16px;
  background: white;
  border-radius: 0px 0px 8px 8px;
  display: flex;
  flex-wrap: wrap;
}

.description-clip {
  margin-top: 8px;
  color: var(--text-night, #fff);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.42px;
  width: auto !important;
  text-align: left;
  text-align-last: left;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.footer-story-icon {
  z-index: 1000;
  color: white;
  font-size: 32px;
  width: 50px;
  align-items: center;
  text-align: center;
}

.fix-story-icon {
  margin-bottom: 12px;
}

@media (min-width: 513px) {
  .footer-story {
    display: flex;
    width: 350px;
    justify-content: center;
    align-items: center;
  }
}

.fix-story-icon {
  width: 250px;
}

@media (max-width: 512px) {
  .footer-story {
    display: flex;
    width: 100vw !important;
    justify-content: center;
    align-items: center;
  }
}

.footer-story-extra {
  position: relative;
  // bottom: 65px;
  top: -64px;
  height: 0px;
  text-align: -webkit-center;
}

.arrow-story-left {
  align-self: center;
  height: 20px;
  width: 20px;
  margin-right: 10px;
  border-radius: 28px;
  background: var(--background-day, #fff);
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.15);
  color: black;
  font-size: 12px;
  text-align-last: center;
  z-index: 800;
  display: flex;
  justify-content: center;
  align-items: center;
}

.theme-dark {
  .arrow-story-left {
    background: var(--background-night, #111);
    color: white;
  }

  .arrow-story-right {
    background: var(--background-night, #111);
    color: white;
  }
}

.arrow-story-right {
  align-self: center;
  height: 20px;
  width: 20px;
  margin-left: 10px;
  border-radius: 28px;
  background: var(--background-day, #fff);
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.15);
  color: black;
  font-size: 12px;
  text-align-last: center;
  z-index: 800;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pl-10px {
  padding-left: 10px;
}

.mute-button {
  position: relative;
  top: 20px;
  right: 90px;
  z-index: 1001;
  width: 0;
  color: white;
  font-size: 20px;
}

.story-option-button {
  position: relative;
  top: 20px;
  right: 60px;
  z-index: 1001;
  width: 0;
  color: white;
  font-size: 20px;
}

.close-story-button {
  position: relative;
  top: 20px;
  right: 30px;
  z-index: 1001;
  width: 0;
  color: white;
  font-size: 20px;
}

.clip-option-button {
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: 1001;
  width: 0px;
  color: black;
  font-size: 20px;
}

.internal-3point {
  width: 50px;
  display: block;
}

.fix-containter-clip {
  width: 50px;
}

.icon-for-clip {
  width: 50px;
}

.icon-for-clip-mobile {
  width: 100%;
}

.theme-dark {
  .icon-for-clip {
    color: #ffffff;
  }
}

.icons-clip-mobile {
  color: white;
  width: 100vw;
  position: relative;
  left: -24px;
}

.text-shadow-20px-black {
  text-shadow: 0 0 20px black;
}

.option-story-div {
  z-index: 900;
  position: relative;
  top: 5px;
  right: 115px;
  width: 146px;
  border-radius: 8px;
  border: 1px solid var(--border-day, #eee);
  background: var(--background-day, #fff);
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
  color: var(--gray-day, #a3a3a3);
  padding: 2px 8px;
  display: grid;
  align-content: center;
  font-size: 17px;
}

.theme-dark {
  .option-story-div {
    background: var(--background-night, #111);
    border: 1px solid var(--border-night, #242424);
  }
}

.option-clip-div {
  z-index: 99999;
  position: absolute;
  bottom: 18px;
  right: 20px;
  width: fit-content;
  min-width: 136px !important;
  height: 88px;
  border-radius: 8px;
  border: 1px solid var(--border-day, #eee);
  background: var(--background-day, #fff);
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
  color: var(--gray-day, #a3a3a3);
  text-align: center;
  display: grid;
  align-content: center;
  font-size: 17px;
  text-align-last: left;
  padding-left: 4px;
  padding-right: 4px;

  div {
    text-align: left;
  }
}

.pause-internal-button-clip {
  height: 52px;
  width: 52px;
  border-radius: 60px;
  background: transparent;
  border: 6px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.option-clip-div-desk {
  z-index: 99999;
  position: absolute;
  bottom: 25px;
  right: -145px;
  width: fit-content;
  min-width: 136px !important;
  height: 88px;
  border-radius: 8px;
  border: 1px solid var(--border-day, #eee);
  background: var(--background-day, #fff);
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
  color: var(--gray-day, #a3a3a3);
  text-align: center;
  display: grid;
  align-content: center;
  font-size: 17px;
}

.theme-dark {
  .option-clip-div-desk {
    background: var(--background-day, #111);
    border: 1px solid var(--border-night, #242424);
  }

  .option-clip-div {
    background: var(--background-day, #111);
    border: 1px solid var(--border-night, #242424);
  }
}

.gNublD {
  overflow: visible !important;
}

// @media(max-width:512px)

@media (max-width: 512px) {
  .pause-button-mobile {
    position: absolute;
    height: 36px;
    max-height: 100vh;
    width: 60px;
    z-index: 100;
    display: flex;
    align-items: center;
    place-content: center;
    z-index: 700;
  }

  .containter-button-mobile-pause {
    height: 100vh;
    width: 100vw;
    align-items: center;
    display: flex;
    justify-content: center;
    z-index: 1000;
    // background-color: black;
    // opacity: 0.5;
    position: absolute;
    // top: -64px;
    left: 0px;
  }

  .containter-button-mobile-pause-profile {
    // height: 100vh;
    // width: 100vw;
    // align-items: center;
    // display: flex;
    // justify-content: center;
    // z-index: 25;
    // // background-color: black;
    // // opacity: 0.5;
    // position: absolute;
    // top: -64px;
    // left: 0px;
    height: 100%;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    z-index: 25;
    position: absolute;
    left: 0px;
  }

  .story-card-current {
    height: 100dvh !important;
    width: 100vw !important;
  }
}

@media (max-width: 512px) {
  .margin-modal-comment-clip {
    .modal-dialog {
      margin: 16px !important;
    }
  }

  .clip-card-current {
    // height: calc(100% - 96px) !important;
    // height: 100%;
    height: calc(100dvh - 64px);
    width: 100vw !important;
    z-index: 1000;
    margin-top: 32px !important;
  }

  .rec-carousel-item {
    // height: calc(100dvh - 64px) !important;
    height: 100dvh !important; //test da vedere

    // margin-top: 32px !important; se si mette si scassano le storie quindi da non suare
    // assolutamente e se serve inserirlo solo nelle clip tramite una classe padre
  }
}

@media (min-width: 513px) {
  .containter-button-mobile-pause {
    height: 100%;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1000;
  }

  .containter-button-mobile-pause-profile {
    height: 100%;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 25;
  }

  .container-button-clip {
    width: 0px;
    display: grid;
    justify-items: center;
    position: relative;
  }
}

.add-clip-button {
  width: 36px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid var(--background-night, #111);
  font-size: 18px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.theme-dark {
  .add-clip-button {
    border: 1px solid var(--background-night, #ffffff);
    color: #ffffff;
  }
}

.hHJTFD {
  margin: 0px !important;
}

.story-card-background {
  width: 224px;
  height: 400px;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--border-night, #242424);
  align-items: center;
  display: flex;
  justify-content: center;
}

@media (max-width: 512px) {
  .story-card-background {
    width: 100vw;
    height: 100dvh;
    flex-shrink: 0;
    border-radius: 0px;
    background: var(--border-night, #242424);
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .sc-jSMfEi {
    height: 100% !important;
    position: absolute;
    top: 0px;
  }

  .eLPooy {
    height: 100dvh !important;
  }
}

@media (min-width: 513px) {
  .clip-card-background {
    width: calc(86.6666666vh * 0.560897);
    height: 86.6666666vh;
    flex-shrink: 0;
    margin-bottom: 12px;
    margin-top: 12px;
    border-radius: 8px;
    overflow: hidden;
    // background: var(--border-night, #242424);
    align-items: center;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 512px) {
  .clip-card-background {
    width: 100vw;
    height: 100vh;
    flex-shrink: 0;
    border-radius: 8px;
    background: var(--border-night, #242424);
    align-items: center;
    display: flex;
    justify-content: center;
  }
}

.red-circle-story {
  position: absolute;
  top: 48px;
  left: 48px;
}

.close-story {
  position: absolute;
  top: 44px;
  right: 48px;
  color: black;
  font-size: 24px;
}

.story-card-blank {
  width: 224px;
  height: 400px;
  flex-shrink: 0;
  border-radius: 8px;
  background: transparent;
}

.clip-card-blank {
  width: calc(86.6666666vh * 0.560897);
  height: 86.6666666vh;
  flex-shrink: 0;
  border-radius: 8px;
  background: transparent;
}

.story-add {
  width: 56px;
  height: 56px;
  border-radius: 48px;
  background: var(--background-day, #fff);
  border: 1px solid var(--border-day, #eee);
  text-align: center;
  place-content: center;
  display: grid;
  font-size: 16px;
  color: black;
}

.story-box-container-red {
  border: 1px solid #ff2800 !important;
  border-radius: 50%;
}

.story-box-container {
  border: 1px solid #eee !important;
  border-radius: 50%;
}

.story-add-extra {
  width: 56px;
  height: 56px;
  border-radius: 48px;
  border: 1px solid var(--border-day, #eee);
  place-content: center;
  display: grid;
  background-color: transparent;
}

.story-box-extra-red {
  overflow: hidden;
  width: 56px;
  height: 56px;
  border-radius: 56px;
  border: 1px solid #ff2800 !important;
  place-content: center;
  display: grid;
  animation: pulse-animation 2s infinite;
  background-color: transparent;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 4px #ff2800;
  }

  50% {
    box-shadow: 0 0 8px #ff2800;
  }

  100% {
    box-shadow: 0 0 4px #ff2800;
  }
}

.story-box-extra-gray {
  width: 56px;
  height: 56px;
  border-radius: 56px;
  border: 1px solid gray;
  place-content: center;
  display: grid;
  background-color: transparent;
}

.name-story-creator {
  height: 12px;
  align-self: stretch;
  overflow: hidden;
  color: var(--gray-day, #a3a3a3);
  text-align: center;
  text-overflow: ellipsis;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  margin-top: 4px;
}

.reaction-preview-comment {
  color: var(--gray-day, #a3a3a3);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-left: 92px;
  display: flex;
  align-items: center;
}

.icon-find-creator {
  color: #a3a3a3;
  font-size: 13px;
  display: flex;
}

.mt-4px {
  margin-top: 4px;
}

.mb-4px {
  margin-bottom: 4px;
}

.group-selected {
  border: 1px solid #cc0000 !important;
}

@media (max-width: 512px) {
  .fix-width-button {
    width: 100%;

    button {
      width: 100%;
    }
  }

  .mobile-filter-ticket {
    display: block !important;
  }

  .overflow-scroll-mobile {
    overflow: scroll;
  }
}

.find-creator-search-bar {
  border-radius: 40px;
  border: 0px;
  background: transparent;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

.find-creator-search-div {
  margin-top: 16px;
  width: 100%;
  border-radius: 40px;
  border: 1px solid var(--border-day, #eee);
  background: transparent;
  padding: 8px 12px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  height: 44px;
}

@media (min-width: 512px) {
  .find-creator-search-div {
    height: 32px !important;
  }
}

.box-stories {
  width: 56px;
  margin-right: 16px;
}

.wallet-select-iban {
  color: var(--text-day, #111);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.48px;
}

.advance-upload-clip-text {
  color: var(--text-day, #111);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

$width-screen: 100vw;

.comment-clip-text {
  color: var(--text-day, #111);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.48px;
  overflow-wrap: break-word;
  margin-left: 40px;
}

.theme-dark {
  .comment-clip-text {
    color: var(--text-night, #fff);
  }
}

@media (min-width: 513px) {
  .comment-clip-text {
    width: 450px;
  }
}

@media (max-width: 512px) {
  .comment-clip-text {
    width: calc(#{$width-screen} - 80px) !important;
  }
}

.comment-reply-clip-text {
  color: var(--text-day, #111);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.48px;
  overflow-wrap: break-word;
}

@media (min-width: 513px) {
  .comment-reply-clip-text {
    width: 425px;
  }
}

@media (max-width: 512px) {
  .comment-reply-clip-text {
    width: calc(#{$width-screen} - 112px) !important;
  }
}

.tabHr {
  margin-top: 0px;
  width: 90%;
}

.tabTitle {
  display: inline-block;
  margin-right: 16px;
}

.notificationTime {
  color: #a3a3a3;
}

.theme-dark .notificationDropdown {
  width: 100%;
  background-color: #111;
  border-radius: 36px;
  font-size: 14px;
  font-weight: 700;
  color: #2e69ff;
  padding: 0.7rem 1rem;
  margin: 1rem 0;
  border: 1px solid #111;
}

.notificationDropdown {
  width: 100%;
  background-color: #fff;
  border-radius: 36px;
  font-size: 14px;
  font-weight: 700;
  color: #2e69ff;
  padding: 0.7rem 1rem;
  margin: 1rem 0;
  border: 1px solid #eee;
}

.w-96 {
  width: 96% !important;
}

.filterButton {
  padding: 1rem 0;
  text-align: right;
  width: 90%;
  position: relative;
}

.filterButton .btn-primary {
  background-color: transparent;
  color: #a3a3a3;
  border-radius: 25rem;
  border: 1px solid #a3a3a3;
  padding: 8px 14px;
}

.p-8px-12px {
  padding: 8px 12px;
}

.p-12px-24px {
  padding: 12px 24px;
}

.sortButton {
  width: auto;
}

.sortByButton {
  background-color: transparent;
  color: #a3a3a3;
  font-weight: 400 !important;
  border: 0 !important;
  padding: 0 !important;
}

.sortButton .btn-primary {
  background-color: transparent;
  color: #a3a3a3;
  border-radius: 25rem;
  border: 1px solid #c4c4c4;
}

.theme-dark .filterButton .btn-primary {
  border: 1px solid #111;
}

.theme-dark .bookmarkText {
  font-size: 12px;
  font-weight: 400 !important;
  color: #fff;
}

.plan-details {
  font-size: 14px;
  font-weight: 700;
  color: #111;
}

.theme-dark .plan-details {
  color: #fff;
}

.mainlabel {
  font-size: 10px;
  font-weight: 700;
  color: #a3a3a3;
}

.theme-dark .bookmarkModal {
  width: 14%;
  position: absolute;
  background: #111;
  z-index: 1;
  // right: 31% !important;
  top: 17rem !important;
  border: 1px solid #242424 !important;
}

.bookmarkModal {
  width: 14%;
  position: absolute;
  background: #ffff;
  z-index: 1;
  // right: 31% !important;
  top: 17rem !important;
  border: 1px solid #eee !important;
}

.border-card {
  border: 1px solid #eee !important;
  border-radius: 8px !important;
}

.subscriptionModal {
  width: 14%;
  padding: 1rem !important;
  position: absolute;
  background: #ffff;
  z-index: 1;
  left: 24%;
  top: 16rem !important;
  border: 1px solid #eee !important;
}

.theme-dark .subscriptionModal {
  width: 14%;
  padding: 1rem !important;
  position: absolute;
  background: #111;
  z-index: 1;
  left: 24%;
  top: 16rem !important;
  border: 1px solid #242424 !important;
}

.bookmarkText {
  font-size: 12px;
  font-weight: 400 !important;
  color: #111111 !important;
}

.mb-6px {
  margin-bottom: 6px !important;
}

.theme-dark .bookmarkTitle {
  color: #fff;
}

.theme-dark .filterButton .btn-primary {
  border: 1px solid #707070;
}

.gap-92px {
  gap: 92px;
}

.top-110px {
  top: 110px;
}

.top-90px {
  top: 90px;
}

.h-160px {
  height: 160px;
}

.fc .fc-daygrid-event-harness {
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  max-width: 80px;
}

.fc-daygrid-dot-event .fc-event-title {
  background-color: #111;
  border-radius: 4px;
  width: 80px;
}

.subscriptionHeading {
  font-size: 20px;
  font-weight: 800;
}

.subscriptionCard {
  text-align: -webkit-center;
  background-color: #fff;
  border: 1px solid #eee;
  height: auto;
  padding: 24px 32px;
  border-radius: 8px;
  padding-bottom: 4rem;
}

.of-cover {
  object-fit: cover;
}

.w-140px {
  width: 140px !important;
}

.pointer-events-none {
  pointer-events: none !important;
}

.message-on-chat {
  display: flex;
  padding: 8px 12px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  border-radius: 12px 12px 0px 12px;
  background: var(--primary-red-white-90-day, #fae5e5);
}

.mh-200px {
  max-height: 200px;
}

/* 
.css-j88s13-MuiPickersToolbar-root-MuiDatePickerToolbar-root,
.css-zv8h38 {
  display: none !important;
}

.css-ocommy,
.css-dgcnuc {}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper,
.css-1nf2ro8 .MuiDialog-paper {
  border-radius: 10px !important;
  background: white;
}
 */
.guIbWC {
  width: 100% !important;
}

/* .css-1bn53lx {
  border: 1px solid #eee;
  border-radius: 40px !important;
  height: 36px;
} */

.w-20 {
  width: 20%;
}

.fc .fc-col-header-cell-cushion {
  color: #a3a3a3 !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;

  div {
    text-transform: capitalize;
  }
}

.mt-20px {
  margin-top: 20px;
}

tbody,
.fc .fc-scrollgrid-section,
.fc .fc-scrollgrid-section-liquid > td,
.fc .fc-scroller-harness-liquid,
.fc .fc-scroller-liquid-absolute,
.fc .fc-daygrid-body,
.fc .fc-scrollgrid-section-body table {
  width: 100% !important;
  height: 100% !important;
}

.fc .fc-highlight {
  background-color: transparent !important;
}

/* 
.theme-dark {

  .css-m1gykc {
    color: white !important;
  }

  .css-yylhor {
    color: white !important;
  }

  .css-1bn53lx {
    border: 1px solid #242424;
    border-radius: 40px !important;
    height: 36px;
    color: white !important;
  }

  .css-1nf2ro8 .MuiDialog-paper {
    background-color: #242424;
  }

  .css-ub1r1 {
    background-color: #2b2b2b;
    border: 1px solid #111;
    color: white;
  }

  .css-3eghsz-PrivatePickersYear-button {
    color: white !important;
  }

  .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
    color: white;
  }

  .css-1bx5ylf,
  .css-10qkmc3,
  .css-vubbuv,
  .css-ub1r1,
  .css-sldnni,
  .css-1ujsas3 {
    color: white;
  }

  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root,
  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root,
  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover,
  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:active,
  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:focus,
  .css-1v4ccyo,
  .css-1v4ccyo:hover,
  .css-1v4ccyo:active,
  .css-1v4ccyo:focus {
    border: 1px solid #242424;
    border-radius: 40px !important;
    height: 36px;
    color: white;

    .css-i4bv87-MuiSvgIcon-root {
      color: #a3a3a3;
    }
  }

  .css-wed0tz:not(.Mui-selected) {
    background-color: #111;
    color: white;
  }

  .css-wed0tz {
    background-color: #2b2b2b;
    border: 1px solid #111;
    color: white;
  }

  .css-1brzq0m {
    background-color: #2b2b2b;
  }

  .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    border-radius: 10px;
    background: #242424;
  }
}
 */
/* 


.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline,
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline,
.css-1d3z3hw-MuiOutlinedInput-notchedOutline,
.css-1v4ccyo.Mui-error .MuiOutlinedInput-notchedOutline,
.css-igs3ac {
  border: none !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root,
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root,
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover,
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:active,
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:focus,
.css-1v4ccyo,
.css-1v4ccyo:hover,
.css-1v4ccyo:active,
.css-1v4ccyo:focus {
  border: 1px solid #eee;
  border-radius: 40px !important;
  height: 36px;
  color: #111;

  .css-i4bv87-MuiSvgIcon-root {
    color: #a3a3a3;
  }
}

.theme-dark .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  border: 1px solid #242424;
  border-radius: 40px;
  height: 36px;
  color: white;

  .css-i4bv87-MuiSvgIcon-root {
    color: white;
  }
} */

.theme-dark .subscriptionCard {
  background-color: #111;
  border: 1px solid var(--border-night, #242424) !important;
}

.theme-dark .subscriptionHeading {
  color: #fff;
}

.subscription-menu {
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 14px;
  width: 98%;
  margin: 0 auto;
  font-size: 14px;
  font-weight: 700;
}

.searchIconSubscription {
  top: 12px !important;
  color: #a3a3a3;
}

.w-22 {
  width: 22%;
}

.submit-button-lang {
  width: 96px;
  height: 36px;
  font-size: 14px;
  padding: 8px 12px !important;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    #c80000;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.theme-dark .userCard {
  background-color: #2b2b2b;
  border: 1px solid var(--border-night, #242424) !important;
}

.cardDetails {
  font-size: 12px;
  font-weight: 400;
  color: #a3a3a3;
}

.alretSection {
  display: flex;
  align-items: baseline;
  padding: 8px 12px;
  margin: 1rem;
  background-color: #fae5e5;
  border-radius: 8px;
  margin-bottom: 0;
}

.message {
  margin: 0 10px;
  font-size: 12px;
  font-weight: 400;
  color: #111;
}

.middleSection {
  padding-left: 4rem;
}

.mediaNotfound {
  text-align: center;
}

.notFoundtitle {
  font-size: 20px;
  font-weight: 800;
  color: #111;
  padding-top: 1rem;
}

.notFoundmessage {
  font-size: 14px;
  font-weight: 400;
  width: 60%;
  margin: 0 auto;
  padding: 0.5rem 0;
}

.Subscription-text {
  color: #a3a3a3;
}

.theme-dark {
  .notFoundmessage,
  .text-darkblack,
  .price-text,
  .message,
  .detailTitle {
    color: #fff;
  }

  .features-container {
    flex-direction: column;
  }

  .dark-heading {
    color: #fff;
  }

  .custome-check {
    background: #242424;
    border: 1px solid #242424;
    border-radius: 24px;
    background-image: url("https://res.cloudinary.com/dun2j5pyv/image/upload/v1675420234/dark_pteqk0.png");
    background-repeat: no-repeat;
    background-position: left center;
  }
}

.price-text {
  color: #000;
}

.responsiveDropdown {
  display: none;
}

.display-none {
  display: none !important;
}

.modalButton {
  width: auto !important;
}

.creatorModal {
  width: 20%;
  padding: 1rem !important;
  position: absolute;
  background: #ffff;
  z-index: 1;
  top: 8rem !important;
  border: 1px solid #eee !important;
  right: 22% !important;
}

.theme-dark .creatorModal {
  background-color: #111;
  border-color: #242424 !important;
}

.theme-dark .subscriptionFilter {
  border-color: #707070;
}

.archive-menu {
  font-size: 14px;
  font-weight: 400;
  color: #a3a3a3;
}

.theme-dark {
  .archive-menu {
    color: white;
  }
}

.main-archived-title {
  font-size: 20px;
  font-weight: 800;
  color: #111;
}

.theme-dark .main-archived-title {
  color: #fff;
}

.archived-section {
  padding-left: 3rem;
}

.filter-input-label {
  font-weight: 700;
  font-size: 14px;
  color: #111111;
  padding: 4px 8px 4px 12px;
  background-color: #a3a3a3;
  border-radius: 20px 0 0 20px;
}

.filter-input {
  border: 1px solid #eeeeee;
  padding: 3px 5px;
  border-radius: 0 20px 20px 0;
  width: 80%;
  font-size: 14px;
}

.theme-dark .filter-input {
  background-color: #111111;
  border: 1px solid #242424;
}

.creator-section {
  width: 100%;
}

.private-button {
  background-color: #d40000;
  border: 1px solid #d40000;
  width: 100%;
  margin-bottom: 1.5rem;
  font-size: 12px !important;
}

.responsive-img {
  width: 100%;
  height: auto;
}

.label-font {
  font-size: 12px;
}

.label-responsive {
  width: 20%;
}

.account-form {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
}

.account-inputtype {
  width: 80%;
  margin-left: 0rem;
  color: #111 !important;
  border-radius: 25rem;
}

.h-120px {
  height: 120px !important;
}

.description-inputtype-container {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;

  .description-inputtype {
    border-radius: 8px;
    width: 100%;
    background-color: transparent !important;
  }
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #fafafa;
  opacity: 1;
}

.lable-font {
  font-size: 14px;
  font-weight: 700;
  color: #111;
  text-align: right;
}

.form-submit {
  font-size: 14px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    #c80000;
  border-radius: 40px;
  padding: 0.7rem 1.5rem;
}

.theme-dark .form-submit {
  background: #290000;
  color: #a3a3a3 !important;
}

.delete-account {
  font-size: 14px;
  color: #c80000;
}

.form-submission {
  padding: 1rem 2rem;
}

.profile-image {
  padding: 0 9rem 0.5rem;
}

.account-sidebar {
  padding: 2rem;
  height: 80vh;
  position: relative;
}

.log-out {
  position: absolute;
  bottom: 0;
  padding-left: 2rem;
}

.sidebar-option {
  border-right: 1px solid #eeeeee;
}

.theme-dark .sidebar-option {
  background-color: #111 !important;
  border-color: #242424;
}

.create-plan {
  background-color: transparent;
  border: 1px solid #eeee;
  border-radius: 8px;
  padding: 0;
}

.plan-card {
  background: linear-gradient(267.77deg, #c80000 0%, #990000 100%);
  border-radius: 8px;
  padding: 1.5rem;
}

.archive-plan {
  background: linear-gradient(261.14deg, #111111 0%, #3c3c3c 100%);
}

.subscribe-count {
  font-size: 32px;
  font-weight: 400;
  display: flex;
  align-items: center;
  color: #111;
}

.plan-amount {
  font-size: 40px;
  font-weight: 800;
}

.plan-option {
  display: flex;
  align-items: center;
}

.plan-list {
  border-radius: 25rem;
  padding: 0.5rem !important;
  border: 1px solid #eeeeee;
}

.session-history {
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  margin: 15px 0px;
}

._two-step-auth {
  height: 100vh;
}

.basic-features {
  display: flex;
  align-items: center;
}

.advanced-title {
  font-weight: 700;
  font-size: 10px;
  color: #a3a3a3;
}

.advanced-features {
  display: flex;
  align-items: center;

  color: #d40000 !important;
  border-radius: 50%;
  text-align: center;
}

.vault-container {
  display: flex;
  align-items: center;
  gap: 12px;
}

.creator-card-container {
  background-color: white;
  border: 1px solid #eee;
  margin-top: 16px;
  border-radius: 8px;
}

.settings-item {
  font-size: 14px;
  color: #111111;
}

.active-tab-color {
  color: #2e69ff;
}

.chat-menu-item:hover {
  span {
    color: #20a7db !important;
  }

  & {
    color: #20a7db !important;
    background-color: #cfecf7;
    border-radius: 5px;
  }
}

.chat-menu-item-danger:hover {
  span,
  i {
    color: #db2020 !important;
  }
  & {
    background-color: #f7d0cf;
    color: #db2020 !important;
    border-radius: 5px;
  }
}

.h-290px {
  height: 290px;
}

.message-search {
  border: 1px solid gray;
}

.chat-message-dropdown-item:hover {
  color: #9e9a9a !important;
}

.created-post-container {
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 24px 0px;
  margin-bottom: 18px;
}

.created-post-avatar-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.created-post-avatar {
  position: relative;
  width: 36px;
  height: 36px;
  margin-bottom: 0;
  border-radius: 100%;
  align-self: center;
  background-color: white !important;
}

.created-post-content {
  font-weight: 400 !important;
  font-size: 12px !important;
  width: 100% !important;
  color: #111111 !important;
  padding-left: 44px;
  word-break: break-word;
}

.created-post-content > span {
  width: 100% !important;
}

.created-post-features {
  // justify-content: space-between;
  justify-content: center;
  margin-top: 16px;
}

.carousel {
  .control-dots {
    margin-top: 100px !important;
  }
}

.day-selected {
  border-radius: 50%;
  text-align: center;
  min-width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.day-selected-text-black {
  color: black !important;
}

.day-selected-text-white {
  color: white;
}

.control-disabled {
  display: none !important;
}

.hideDots {
  .control-dots {
    display: none;
  }
}

.react-tel-input {
  .form-control {
    & {
      line-height: 50px;
      height: 50px;
      font-size: 16px;
      display: inline-block;
      border: 2px #eee solid;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      width: 100%;
      padding-left: 60px;
      border-radius: 25rem;
    }

    &:focus {
      border: 2px #eee solid;
    }
  }
}

.cap-style-div {
  width: 150px;
  border-radius: 12px;
  overflow: hidden;
  background: #eee;
  padding-top: 16px;
  padding-left: 0px;
  padding-right: 4px;
}

.responsive-post-text {
  width: 25%;
  justify-content: center;
}

.mt-16px {
  margin-top: 16px;
}

.mtb-8px {
  margin-bottom: 8px !important;
  margin-top: 8px !important;
}

.customeValidation {
  display: flex;
  align-items: center;
}

.icons-add-card {
  font-size: 23px;
  color: #a3a3a3;
  width: 0px;
  position: relative;
  right: 40px;
}

.btn-close {
  font-size: 9px !important;
}

.fix-canvas-cap {
  display: flex;
  justify-content: center;
}

.scheduleInputtype {
  border-radius: 25rem;
  border: 1px solid #a3a3a3;
}

.box-shadow {
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
}

.user-chat-more {
  background-color: #ffffff;
  border: 1px solid #eee;
  border-radius: 8px;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1) !important;
  padding: 8px 16px;
  width: fit-content;
  max-width: 100%;
  min-width: 201px;
}

.scheduleLable {
  font-size: 12px;
  display: flex;
  width: 50%;
  margin: 0 auto;
  font-weight: 700;
}

.fc .fc-button {
  background-color: transparent !important;
  color: #111 !important;
  opacity: 1 !important;
  border: 1px solid #111 !important;
  border-radius: 25rem !important;
  padding: 3px 15px !important;
  font-weight: 700 !important;
  font-size: 12px;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 16px;
  align-items: flex-end;
}

.calendarView .fc-direction-ltr .fc-button-group > .fc-button:not(:last-child),
.fc-direction-ltr .fc-button-group > .fc-button:not(:first-child) {
  margin-right: 1rem;
  border-radius: 100%;
  width: 24px !important;
  height: 24px !important;
  padding: 2px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fc .fc-scrollgrid-section-header > *,
.fc .fc-scrollgrid-section-footer > * {
  .fc-scroller-harness {
    border-bottom: 1px solid #eee;
    padding: 8px 0px;
  }
}

.calendarView .fc-view {
  border: 1px solid #eee;
  border-radius: 8px;
}

.notificationCard {
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  margin-top: 1rem;
}

.notifiCationHeading {
  font-size: 20px;
}

.pb-5px {
  padding-bottom: 5px;
}

.tabSection {
  padding: 1rem 1rem 0 1rem;
}

.count-subscription-man {
  padding: 4px 8px;
  background: #eee;
  border-radius: 4px;
  font-weight: 400;
  margin-left: 5px;
  margin-bottom: 5px;
}

.count {
  padding: 2px 6px;
  background: #eee;
  border-radius: 4px;
  margin-left: 5px;
  padding-bottom: 1px;
}

.tabHr {
  margin-top: 0px;
  width: 100%;
}

.tabTitle {
  display: inline-block;
  margin-right: 16px;
}

@media (min-width: 992px) {
  .fix-main-content-home {
    padding-top: 48px;
    padding-left: 360px;
  }
}

.notificationTime {
  color: #a3a3a3;
}

.bottom-grey {
  border-bottom: solid 1px #eee;
}

.support-page {
  background-color: #fafafa;
}

.submit-box {
  margin-left: 48px;
  width: 200px;
  max-height: 200px;
  min-width: 200px;
  min-height: 150px;
  background-color: #ffffff;
}

.theme-dark {
  .submit-box {
    background-color: #111111;
  }
}

.support-header {
  height: 320px;

  .nav-top {
    padding: 48px;
    display: flex;
    align-items: center;
  }

  .nav-center {
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 48px;
    width: 100%;
    text-align: center;
  }
}

.container-table {
  // min-height: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 16px;
  background: #ffffff;
  margin-top: 24px;
  border: 1px solid #eeeeee;
  border-radius: 8px;

  .table-container {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
  }

  table {
    font-family: "HelveticaNowDisplay";
    margin-bottom: auto;
    letter-spacing: -0.03em;
    width: 100%;

    tr {
      width: 100%;
      height: 52px;

      th {
        & {
          color: #a3a3a3;

          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          border-bottom: 1px solid #eeeeee;
        }

        &.center {
          text-align: center;
        }
      }

      td {
        & {
          color: #111111;

          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
        }

        &.center {
          text-align: center;
        }

        &.avatar {
          display: inline-flex;
          align-items: center;
          height: 52px;

          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 10px;
          }
        }

        div.subdata {
          color: #a3a3a3;
          font-size: 14px;
        }

        div.linked {
          color: #2e69ff;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    .action {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 52px;

      .subaction {
        justify-content: center;
        width: 100%;
        align-items: center;
        display: inline-flex;
        gap: 10px;
      }
    }
  }

  .paginator {
    margin-top: 20px;
  }
}

.theme-dark .notificationDropdown {
  width: 100%;
  background-color: #111;
  border-radius: 36px;
  font-size: 14px;
  font-weight: 700;
  color: #2e69ff;
  padding: 0.7rem 1rem;
  margin: 1rem 0;
  border: 1px solid #111;
}

.notificationDropdown {
  width: 100%;
  background-color: #fff;
  border-radius: 36px;
  font-size: 14px;
  font-weight: 700;
  color: #2e69ff;
  padding: 0.7rem 1rem;
  margin: 1rem 0;
  border: 1px solid #eee;
}

.filterButton {
  padding: 1rem 0;
  text-align: right;
  width: 90%;
}

.filterButton .btn-primary {
  background-color: transparent;
  color: #a3a3a3;
  border-radius: 25rem;
  border: 1px solid #a3a3a3;
  padding: 8px 14px;
}

.p-0 {
  padding: 0px !important;
}

.sortSection {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 95%;
  padding: 0 !important;
  margin: 0 auto;
  position: relative;

  .bookmark-dropdown-list {
    left: 15px;
    top: 68px !important;
    transform: translate(-15px, -10px);
    padding: 12px 16px !important;
  }
}

.sortButton {
  width: auto;
}

.sortButton .btn-primary {
  background-color: transparent;
  color: #a3a3a3;
  border-radius: 25rem;
  border: 1px solid #c4c4c4;
}

.theme-dark .filterButton .btn-primary {
  border: 1px solid #111;
}

.theme-dark .bookmarkText {
  font-size: 12px;
  font-weight: 400;
  color: #ffffff !important;
}

.plan-details {
  font-size: 14px;
  font-weight: 700;
  color: #111;
}

.theme-dark .plan-details {
  color: #fff;
}

.mainlabel {
  font-size: 10px;
  font-weight: 700;
  color: #a3a3a3;
}

.button-right {
  margin-left: auto;
  gap: 16px;
  display: flex;
}

.theme-dark .bookmarkModal {
  width: 260px;
  padding: 1rem;
  position: absolute;
  background: #111;
  z-index: 1;
  // right: 31% !important;
  top: 17rem !important;
  border: 1px solid #242424 !important;
}

.bookmarkModal {
  width: 14%;
  padding: 1rem;
  position: absolute;
  background: #ffff;
  z-index: 1;
  right: 31% !important;
  top: 17rem !important;
  border: 1px solid #eee !important;
}

.subscriptionModal {
  width: 14%;
  padding: 1rem !important;
  position: absolute;
  background: #ffff;
  z-index: 1;
  left: 24%;
  top: 16rem !important;
  border: 1px solid #eee !important;
}

.theme-dark .subscriptionModal {
  width: 14%;
  padding: 1rem !important;
  position: absolute;
  background: #111;
  z-index: 1;
  left: 24% !important;
  top: 16rem !important;
  border: 1px solid #242424 !important;
}

.bookmarkTitle {
  font-size: 20px;
  font-weight: 800;
  padding-top: 24px !important;
  padding-bottom: 16px;
  padding-left: 120px;
  color: #111;
}

.theme-dark .bookmarkTitle {
  color: #fff;
}

.theme-dark .filterButton .btn-primary {
  border: 1px solid #707070;
}

.subscriptionHeading {
  font-size: 20px;
  font-weight: 800;
  color: #111;
}

.subscriptionCard {
  background-color: #fff;
  height: auto;
  border-radius: 8px;
  padding-bottom: 4rem;
}

.theme-dark .subscriptionCard {
  background-color: #111;
}

.mb-16px {
  margin-bottom: 16px;
}

.theme-dark .subscriptionHeading {
  color: #fff;
}

.search-icon {
  color: #a3a3a3;
  top: 11px;
}

#search-subscription {
  top: 11px !important;
}

.theme-dark .userCard {
  background-color: #2b2b2b;
}

.userCard {
  background-color: #fafafa;
  padding: 12px 24px;
  margin: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
}

.cardDetails {
  font-size: 12px;
  font-weight: 400;
  color: #a3a3a3;
}

.message {
  margin: 0 10px;
  font-size: 12px;
  font-weight: 400;
  color: #111;
}

.middleSection {
  padding-left: 4rem;
}

.mediaNotfound {
  text-align: center;
}

.notFoundtitle {
  font-size: 20px;
  font-weight: 800;
  color: #111;
  padding-top: 1rem;
}

.mt-15 {
  margin-top: 15px;
}

.width60 {
  width: 60%;
}

.Subscription-text {
  color: #a3a3a3;
}

.theme-dark {
  .notFoundmessage,
  .text-darkblack,
  .price-text,
  .message,
  .detailTitle {
    color: #fff;
  }

  .alretSection {
    background-color: #290000;
  }
}

.menudropdown {
  a > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dropdown-menu {
    width: max-content;
    left: 25px !important;
    padding: 0;

    a {
      height: 45px;
    }
  }

  .dropdown-toggle::after {
    content: none;
  }
}

.price-text {
  color: #000;
}

.responsiveDropdown {
  display: none;
}

.modalButton {
  width: auto !important;
}

.creatorModal {
  width: 20%;
  padding: 1rem !important;
  position: absolute;
  background: #ffff;
  z-index: 1;
  top: 8rem !important;
  border: 1px solid #eee !important;
  right: 22% !important;
}

.theme-dark .creatorModal {
  background-color: #111;
  border-color: #242424 !important;
}

.theme-dark .subscriptionFilter {
  border-color: #707070;
}

.archive-menu {
  font-size: 14px;
  font-weight: 400;
  color: #a3a3a3;
}

.p-1-3 {
  padding: 0.5rem 1rem;
}

.mw-75 {
  max-width: 80%;
}

.bread-crumb {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;

  a {
    color: #a3a3a3;
  }

  span {
    color: #a3a3a3;
  }
}

.group-form {
  display: flex;
  flex-direction: column;
  width: 80%;

  .account-inputtype {
    width: 100%;
  }
}

.session-active {
  margin-right: 15px;
  width: 10px !important;
  height: 10px !important;
  border-radius: 50% !important;
  display: inline-block;
  line-height: 10px !important;
  text-align: center;
  background-color: #4cd964;
}

.session-inactive {
  margin-right: 15px;
  width: 10px !important;
  height: 10px !important;
  border-radius: 50% !important;
  display: inline-block;
  line-height: 10px !important;
  text-align: center;
  background-color: #a3a3a3 !important;
}

.button-group {
  display: flex;
  flex-direction: row;
  width: 80%;
  align-items: center;

  .account-inputtype {
    margin-right: auto;
    background-color: #fff;
  }

  button {
    margin-left: auto;
  }
}

.main-archived-title {
  font-size: 20px;
  font-weight: 800;
  color: #111;
}

.theme-dark .main-archived-title {
  color: #fff;
}

.archived-section {
  padding-left: 3rem;
}

.filter-input-label {
  font-weight: 700;
  font-size: 14px;
  color: #111111;
  padding: 4px 8px 4px 12px;
  background-color: #a3a3a3;
  border-radius: 20px 0 0 20px;
}

.qrcode-settings {
  width: 160px;
  height: 160px;
}

.filter-input {
  border: 1px solid #eeeeee;
  padding: 3px 5px;
  border-radius: 0 20px 20px 0;
  width: 80%;
  font-size: 14px;
}

.theme-dark .filter-input {
  background-color: #111111;
  border: 1px solid #242424;
}

.creator-section {
  width: 100%;
}

.private-button {
  background-color: #d40000;
  border: 1px solid #d40000;
  width: 100%;
  margin-bottom: 1.5rem;
  font-size: 12px !important;
}

.responsive-img {
  width: 100%;
  height: auto;
}

.account-inputtype {
  width: 80%;
  margin-left: 0rem;
  border-radius: 25rem;
}

.form-submit {
  font-size: 14px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    #c80000;
  border-radius: 40px;
  padding: 0.7rem 1.5rem;
}

.delete-account {
  font-size: 14px;
  color: #c80000;
}

.form-submission {
  padding: 1rem 2rem;
}

textarea.form-control {
  color: #111 !important;
}

.account-sidebar {
  padding: 2rem;
  height: 80vh;
  position: relative;
}

.log-out {
  position: absolute;
  bottom: 0;
}

.sidebar-option {
  border-right: 1px solid #eeeeee;
}

.create-plan {
  background-color: transparent;
  border: 1px solid #eeee;
  border-radius: 8px;
  padding: 0;
}

.plan-card {
  background: linear-gradient(267.77deg, #c80000 0%, #990000 100%);
  border-radius: 8px;
  padding: 1.5rem;
}

.archive-plan {
  background: linear-gradient(261.14deg, #111111 0%, #3c3c3c 100%);
}

.subscribe-count {
  font-size: 40px;
  font-weight: 400;
  color: #111;
}

.plan-amount {
  font-size: 40px;
  font-weight: 800;
}

.plan-option {
  display: flex;
  align-items: center;
}

.plan-list {
  border-radius: 25rem !important;
  padding: 0.5rem !important;
  border: 1px solid #eeeeee !important;
}

.plan-text-list {
  border-radius: 12px !important;
  padding: 0.5rem !important;
  border: 1px solid #eeeeee !important;
}

.help-text {
  color: #111;
  font-family: "HelveticaNowDisplay";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.basic-features {
  display: flex;
  align-items: center;
}

.features-container {
  flex-direction: column;
}

@media screen and (min-width: 280px) and (max-width: 390px) {
  .checkbox-image-div {
    top: 0% !important;
    right: 20% !important;
    z-index: 5;

    .form-check-input:checked {
      background-color: #1e74fd !important;
      border-color: rgb(119, 123, 128) !important;
    }

    .form-check-input:focus {
      border-color: rgb(119, 123, 128) !important;
    }
  }

  .fc .fc-daygrid-day.fc-day-today {
    .fc-daygrid-day-number {
      width: 23px;
      height: 23px;
    }
  }
}

@media screen and (min-width: 390px) and (max-width: 512px) {
  .responsive-paginator {
    height: fit-content !important;
  }

  .checkbox-image-div {
    top: 0% !important;
    right: 22% !important;
    z-index: 5;

    .form-check-input:checked {
      background-color: #1e74fd !important;
      border-color: rgb(119, 123, 128) !important;
    }

    .form-check-input:focus {
      border-color: rgb(119, 123, 128) !important;
    }
  }
}

@media screen and (min-width: 540px) and (max-width: 576px) {
  .checkbox-image-div {
    top: 0% !important;
    right: 30% !important;
    z-index: 5;

    .form-check-input:checked {
      background-color: #1e74fd !important;
      border-color: rgb(119, 123, 128) !important;
    }

    .form-check-input:focus {
      border-color: rgb(119, 123, 128) !important;
    }
  }
}

@media screen and (max-width: 400px) {
  .modal-post-image {
    height: 110px !important;
    width: 175px !important;
    object-fit: cover !important;

    video {
      height: 110px !important;
      width: 100px !important;
      object-fit: cover !important;
    }

    .react-player__preview {
      height: 110px !important;
      width: 175px !important;
      border-radius: 0.3rem !important;
    }
  }
}

@media screen and (max-width: 512px) {
  .fc-today-button {
    margin-bottom: 5px !important;
    margin-left: 0.75em !important;
  }

  .multiple-image-notifications,
  .multiple-avatar-notifications {
    min-width: 29px !important;
    height: 29px !important;
    object-fit: cover !important;
    border-radius: 50% !important;
    background-color: #ffffff;
    max-width: 29px !important;
  }

  .notification-profile-image,
  .notification-profile-avatar {
    min-width: 35px !important;
    height: 35px !important;
    object-fit: cover !important;
    border-radius: 50% !important;
    background-color: #ffffff;
    max-width: 35px !important;
  }

  .notification-count {
    top: -6px;
    left: 20px;
    height: 17px;
    width: 17px;
    border-radius: 50%;
    padding-left: 3px !important;
    padding-right: 3px !important;
    line-height: 18px !important;
    color: #1e74fd !important;
    background-color: #d2e3ff !important;
    border-color: #bcd5fe !important;
    text-align: center;
  }

  .message-notification-count {
    top: 6px;
    left: 22px;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    line-height: 19px !important;
    background-color: #000000 !important;
  }
}

@media (max-width: 576px) {
  .post-image-container {
    // height: 150px !important;
    // width: 230px !important;
    object-fit: cover !important;
    border-radius: 8px !important;

    video {
      height: 150px !important;
      max-width: 162px !important;
      object-fit: cover !important;
    }

    .react-player__preview {
      height: 150px !important;
      max-width: 162px !important;
      border-radius: 0.3rem !important;
    }
  }
}

@media only screen and (max-width: 992px) {
  .mt-100px {
    margin-top: 0;
  }

  .bookmark-dropdown-list {
    right: 1% !important;
    top: 30px !important;
  }

  .mediaCategory-dropdown-list {
    position: absolute;
    right: 0 !important;
  }
}

.bookmark-dropdown-list {
  right: 0 !important;
  top: 50px !important;
  width: 260px;
}

@media screen and (min-width: 992px) and (max-width: 1400px) {
  .mediaCategory-dropdown-list {
    position: absolute;
    right: 60% !important;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1600px) {
  .mediaCategory-dropdown-list {
    position: absolute;
    right: 10% !important;
  }
}

@media screen and (min-width: 1800px) {
  .bookmark-sidebar-sticky {
    position: fixed !important;
    width: 11% !important;
  }
}

@media only screen and (min-width: 992px) {
  .bookmark-sidebar-sticky {
    position: fixed !important;
    width: 18% !important;
  }
}

@media only screen and (min-width: 1530px) and (max-width: 1774px) {
  .bookmark-sidebar-sticky {
    position: fixed !important;
    width: 15% !important;
  }
}

@media only screen and (min-width: 1775px) {
  .bookmark-sidebar-sticky {
    position: fixed !important;
    width: 13% !important;
  }
}

@media only screen and (min-width: 1200px) {
  .mt-xl-100px {
    margin-top: 100px;
  }
}

@media only screen and (min-width: 1400px) {
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 25%;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .icon-end {
    text-align: end;
  }
}

@media only screen and (min-width: 1600px) {
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }
}

.mw-fc {
  max-width: fit-content;
}

.dropdown-for-subscription,
.dropdown-for-bookmark {
  position: absolute;
  background: #ffff;
  z-index: 1;
  border: 1px solid #eee !important;
}

.theme-dark {
  .dropdown-for-bookmark {
    background-color: #111111;
    border: 1px solid var(--border-night, #242424) !important;
  }
}

@media screen and (max-width: 600px) {
  .navbar-container ul {
    flex-direction: column;
  }

  .bookmark-post {
    margin-bottom: 1rem !important;
  }

  .dropdown-for-subscription {
    right: -10px !important;
    left: 20px !important;
    z-index: 2 !important;
    top: 14rem !important;
    max-width: -webkit-fill-available !important;
    width: 100% !important;
    border-radius: 8px !important;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);

    .mainlabel,
    label {
      font-size: 14px;
    }

    .mb-2 {
      margin-bottom: 0.3rem !important;
    }
  }

  .dropdown-for-bookmark {
    right: -10px !important;
    left: 0px !important;
    z-index: 2 !important;
    top: 14rem !important;
    max-width: -webkit-fill-available !important;
    width: 100% !important;
    border-radius: 8px !important;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);

    .mainlabel,
    label {
      font-size: 14px;
    }

    .mb-2 {
      margin-bottom: 0.3rem !important;
    }
  }

  .fix-content-subscription {
    padding: 20px 20px 20px 20px;
    width: 100%;
  }

  .row-paginator {
    display: flex;
    justify-content: center;
  }

  .responsive-padding-twostep {
    padding: 16px;
  }

  .label-responsive {
    width: 100%;
    text-align: left !important;
  }

  .input-responsive {
    display: flex;
    justify-content: center;

    .form-group {
      width: 90%;
    }
  }

  .button-responsive {
    width: 100%;

    button {
      width: 100%;
    }
  }

  .card-body {
    padding: 16px !important;
  }

  .form-submit {
    font-size: 16px;
  }

  .bread-crumb-delete-account {
    padding: 0px 0px 20px 0px !important;
    margin-left: 0 !important;
    margin-top: -10px;

    .bread-crumb {
      font-size: 16px;
    }
  }

  .m-20px-30px {
    margin: 20px;
  }

  .lable-font {
    font-size: 16px;
    font-weight: 700;
    color: #111;
    text-align: right;
  }

  .schedule-post-button {
    width: 100%;
  }

  .post-features {
    flex-direction: column;
  }

  .responsive-button {
    width: 45%;
  }

  .basic-features {
    justify-content: space-between;
  }

  .responsive-post {
    flex-direction: row;
  }

  .profile-count {
    font-size: 16px;
    font-weight: 700;
    color: #111;
  }

  .profile-title {
    font-size: 14px;
    font-weight: 400;
  }

  .voice-record {
    width: 100%;
    justify-content: space-around;
  }

  .newVault {
    display: block;
  }

  .theme-dark .nav-content {
    margin-top: 0px;
  }

  .scheduleInputtype {
    width: 80%;
  }

  .scheduleLable {
    width: 80%;
  }

  .add-card-extra {
    padding: 1rem;
    border: 1px solid;
    border-radius: 8px;
  }

  .editCardbody .card-body {
    padding: 0 !important;
  }

  .tabSection {
    display: none;
  }

  .notificationCard {
    margin: 1rem 0 0 0;
  }

  .notifiCationHeading {
    font-size: 18px;
  }

  .bookmark-post {
    padding: 24px 0;
  }

  .bookmark-menu {
    display: none;
  }

  .responsive-bookmark-card {
    padding: 0 !important;
    margin-top: 0rem;
    border: 0 !important;
    background-color: transparent;
  }

  .filter-button-bookmark {
    padding: 0 !important;

    button {
      border: 1px solid #eee !important;
      padding: 14px 24px !important;
      font-size: 16px !important;
      height: 48px !important;
    }
  }

  .theme-dark .responsive-bookmark-card {
    margin-top: 0rem;
    background-color: transparent !important;
  }

  .responsiveHr {
    display: none;
  }

  .filterButton,
  .btn-primary {
    width: 100%;
  }

  .theme-dark .bookmarkModal {
    padding: 0rem !important;
    z-index: 2 !important;
    top: 17rem !important;
    right: 8% !important;
    width: 85% !important;
    border-radius: 8px !important;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
  }

  .bookmarkModal {
    padding: 0rem !important;
    z-index: 2 !important;
    top: 17rem !important;
    right: 8% !important;
    width: 85% !important;
    border-radius: 8px !important;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
  }

  .subscriptionModal {
    padding: 0rem !important;
    z-index: 2 !important;
    top: 14rem !important;
    left: 1.8rem !important;
    max-width: -webkit-fill-available !important;
    width: 100% !important;
    border-radius: 8px !important;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);

    .mainlabel,
    label {
      font-size: 14px;
    }

    .mb-2 {
      margin-bottom: 0.3rem !important;
    }
  }

  .theme-dark .subscriptionModal {
    padding: 0rem !important;
    z-index: 2 !important;
    top: 14rem !important;
    left: 6%;
    width: 85% !important;
    border-radius: 8px !important;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
  }

  .theme-dark .bookmarkTitle {
    color: #fff;
  }

  .userCard {
    flex-direction: column;
    text-align: center;
    padding: 1rem 0.5rem;
  }

  .userData {
    display: flex;
    flex-direction: column;
    padding: 0 0 1rem 0;
  }

  .responsiveclass {
    margin: 0 auto;
  }

  .cardDetails {
    padding: 0 0 1rem 0;
  }

  .middleSection {
    padding: 0;
    margin: 5px 10px;
  }

  .theme-dark .subscriptionCard {
    background-color: #2b2b2b;
  }

  .subscriptionCard {
    background-color: transparent;
    border: 0px;
    padding: 0px;
  }

  .sortByButton {
    height: 48px;
    font-size: 16px;
    font-weight: 700 !important;
    border: 1px solid rgba(238, 238, 238, 1) !important;
    border-radius: 25rem;
    padding: 14px 24px 14px 24px !important;
  }

  #sub-plan-title {
    margin-bottom: 0 !important;
  }

  .theme-dark .userCard {
    background-color: #111;
  }

  .resize-with-block {
    display: block !important;
  }

  .responsiveForm {
    float: none !important;
    justify-content: center;
  }

  .responsiveDropdown {
    display: block;
  }

  .subscriptionSearch {
    width: 100% !important;
    font-size: 16px !important;
  }

  .responsive-prfile-section {
    flex-direction: column;
  }

  .profileDetails {
    display: flex;
    align-items: baseline;
    padding-left: 1rem;
  }

  .responsive-text {
    font-size: 14px !important;
    padding-right: 10px;
  }

  .select-container {
    background: white;
    border: 1px solid #eee;
    border-radius: 10px;
    box-shadow: 0px 0px 6px 0px #0000000f;
    position: absolute;
    z-index: 99;
    padding: 10px 15px;
    left: 20px;
    right: 20px;

    .select-items {
      & {
        text-align: start;
        border-bottom: #000;
        margin-bottom: 10px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      span.f-14px {
        font-weight: 700 !important;
      }
    }

    .f-14px {
      font-size: 14px !important;
    }
  }

  .box-selected {
    padding: 4px 8px;
    background: #d5e1ff;
    border-radius: 4px;
    font-weight: 400;
    margin-left: 5px;
    margin-bottom: 5px;
  }

  .color-theme-color {
    color: var(--theme-color) !important;
  }

  .detailTitle {
    font-size: 12px;
  }

  .creatorButton,
  .creatorButtonSubscribe {
    width: 90% !important;
  }

  .main-section {
    margin: 4rem 1rem 0;
  }

  .creatorHeader {
    flex-direction: column;
  }

  .subscription-card {
    background-color: white;
    border: 1px solid #eee;
    padding: 16px !important;
    border-radius: 8px;
    max-width: 160px;
  }

  .main-title {
    padding: 1rem 0;
  }

  .creator-card-container {
    margin-top: 24px;
  }

  .creatorModal {
    padding: 0rem !important;
    z-index: 2 !important;
    top: 7.5rem !important;
    right: 5% !important;
    width: 90% !important;
    border-radius: 8px !important;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
  }

  .theme-dark .subscriptionFilter {
    border-color: #242424;
  }

  .archived-section {
    padding-left: 0;
  }

  .creator-section {
    width: auto;
  }

  .fc-daygrid-day-events {
    display: none;
  }

  .fc-scrollgrid-sync-table {
    height: 200px;
  }

  .fc-daygrid-day-top {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .container-items {
    display: flex;
    flex-wrap: wrap;
    /* flex-flow: row wrap;
    align-content: space-between;
    justify-content: space-between; */
  }

  .body-items {
    margin: 0px -7px;
  }

  .subscribed-button {
    font-size: 16px;
  }

  .item {
    margin: 0px 7px 15px 7px;
  }

  .sortSection {
    width: 100%;
  }

  #search-subscription::before {
    font-size: 23px;
  }

  .fc-daygrid-day-top a {
    color: #111111 !important;
  }

  .theme-dark .fc-daygrid-day-top a {
    color: #fff !important;
  }

  .responsive-text {
    font-size: 14px !important;
    padding-right: 10px;
  }

  .detailTitle {
    font-size: 12px;
  }

  .creatorButton,
  .creatorButtonSubscribe {
    width: 90% !important;
  }

  .main-section {
    margin: 4rem 1rem 0;
  }

  .creatorHeader {
    flex-direction: column;
  }

  .main-title {
    padding: 1rem 0;
  }

  .creator-card-container {
    margin-top: 26px;
  }

  .creatorModal {
    padding: 0rem !important;
    z-index: 2 !important;
    top: 7.5rem !important;
    right: 5% !important;
    width: 90% !important;
    border-radius: 8px !important;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
  }

  .theme-dark .subscriptionFilter {
    border-color: #242424;
  }

  .archived-section {
    padding-left: 0;
  }

  .creator-section {
    width: auto;
  }

  .profile-main-section {
    padding: 24px 20px 0 20px;
  }

  .profile-card {
    padding: 0 !important;
  }

  .profile {
    padding-bottom: 2rem;
    padding-top: 1rem;
  }

  .responsive-bio {
    justify-content: space-around !important;
    padding-top: 0.5rem;
  }

  .responsive-alignment {
    text-align: center;
  }

  .social-media-section {
    justify-content: space-between;
    padding-left: 0;
  }

  .responsive-media {
    padding: 0rem !important;
    padding-top: 0px !important;
    // padding-left: 0 !important;
  }

  .sub-section {
    justify-content: center;
  }

  .customStyle {
    font-weight: 700;
    color: #000;
  }

  .responsive-title {
    font-size: 18px;
    font-weight: 800;
    color: #111;
  }

  .responsive-subscription {
    flex-direction: column;
    padding: 0 1.5rem !important;

    margin: 0 !important;
  }

  ._card-mobile {
    padding: 0 2rem;
  }

  .plan-option {
    padding-top: 1.5rem;
  }

  .create-plan {
    margin: 0;
  }

  .support-form {
    flex-direction: row;
    gap: 0;
  }

  .support-inputtype {
    width: 100%;
    border-radius: 25rem;
  }

  .account-form {
    display: block;
  }

  .mx-10px {
    margin: 0px 10px;
  }

  .w-60 {
    width: 60%;
  }

  .w-40 {
    width: 40%;
  }

  .account-inputtype {
    width: 100%;
  }

  .account-form .react-tel-input,
  .description-inputtype-container,
  .description-inputtype {
    width: 100%;
  }

  .f-16 {
    font-size: 16px;
  }

  .form-submission {
    padding: 0 !important;
  }

  .language-card {
    padding: 1rem 0 !important;
  }

  .form-submit {
    padding: 0.7rem 8rem;
  }

  .custom-button {
    text-align: center !important;
    margin-top: 1rem;
  }

  .profile-image {
    padding: 0 1rem 0.5rem;
  }

  .responsive-width {
    width: auto !important;
  }

  .sidebar-option {
    border-right: 0 !important;
    background-color: transparent !important;
  }

  .responsive-modal {
    padding: 1rem 0;
  }

  .form-control {
    height: 45px;
  }

  .submit-button-lang {
    width: 100%;
    height: 44px;
    padding: 12px 24px !important;
    font-size: 14px;
  }

  .session-details {
    flex-direction: column;
    align-items: baseline !important;
  }

  .session-status {
    margin-left: 0 !important;
    margin-top: 0.5rem;
  }

  .ms-me {
    margin: 0 auto;
  }

  .mt-mb {
    margin: 1rem 0;
  }

  .session-history {
    margin: 15px 8px;
  }

  .responsive-hide {
    display: none;
  }

  .responsive-section {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    color: #111;
  }

  .modal-footer {
    justify-content: center;
  }

  .label-font {
    font-size: 16px;
  }

  .label-font-14 {
    font-size: 16px;
  }

  .theme-dark .responsive-sidebar {
    background-color: transparent !important;
  }

  .theme-dark .customStyle {
    color: #fff;
  }

  ._two-step-auth {
    height: auto;
  }

  .chat-container {
    margin: 0px !important;
    display: auto !important;
    width: 100%;
  }
}

.MuiRating-icon.MuiRating-iconEmpty,
.MuiRating-icon.MuiRating-iconFilled {
  color: #f2994a;
}

.side-bar-calendar > .fc .fc-toolbar-title {
  font-size: 14px !important;
  text-transform: capitalize;
}

.side-bar-calendar .fc-daygrid-day-events {
  display: none;
}

.side-bar-calendar .fc-scrollgrid-sync-table {
  height: 200px;
}

.side-bar-calendar .fc-daygrid-day-top {
  display: flex;
  justify-content: center;
  align-items: center;
}

.side-bar-calendar .fc-daygrid-day-top a {
  color: #111111;
}

.theme-dark .side-bar-calendar .fc-daygrid-day-top a {
  color: #fff !important;
}

div.schedule-input-type.border-0.d-sm-flex > div.me-sm-3 > div > div > div,
div.schedule-input-type.border-0.d-sm-flex > div > div > div {
  border-radius: 50rem;
}

.theme-dark
  div.schedule-input-type.border-0.d-sm-flex
  > div.me-sm-3
  > div
  > div
  > div {
  color: #fff;
}

.theme-dark div.schedule-input-type.border-0.d-sm-flex > div > div > div {
  color: #fff;
  border: 1px solid #333333;
}

.MuiRating-icon.MuiRating-iconEmpty,
.MuiRating-icon.MuiRating-iconFilled {
  color: #f2994a;
}

.side-bar-calendar .fc-daygrid-day-events {
  display: none;
}

.side-bar-calendar .fc-scrollgrid-sync-table {
  height: 200px;
}

.exist-pending-request-container {
  background: #fae5e5;
  border-radius: 10px;
  min-height: 28px;
  width: fit-content;
  padding: 0px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.theme-dark .schedule-input-type .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
  color: #fff;
}

.schedule-modal .modal-dialog {
  max-width: 500px;
  width: auto;
}

// CALENDAR QUEUE

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100% !important;
}

.css-116hegc.Mui-selected {
  background-color: transparent !important;
}

.css-116hegc.Mui-selected,
.css-1qtp7a4.Mui-selected,
.css-1kq7u7p.Mui-selected {
  background-color: transparent !important;
}

.css-1kq7u7p,
.css-1o5xgfj,
.css-1bzq5ag,
.css-19xycpm,
.css-1v2gfp5 {
  color: #000 !important;
}

.PrivatePickersToolbarText-root {
  background-color: transparent !important;
}

.side-bar-schedule {
  overflow-y: scroll;
  padding: 0px 24px;
  padding-bottom: 30px;
  position: fixed;
  background-color: #fff;
  right: 0;
  bottom: 0;
  top: 64px;
  width: fit-content;
  width: 20vw;
}

.theme-dark .side-bar-schedule {
  background-color: #111111;
}

.side-bar-calendar > .fc .fc-button .fc-icon {
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.side-bar-calendar .fc-prev-button.fc-button.fc-button-primary,
.side-bar-calendar .fc-next-button.fc-button.fc-button-primary {
  margin-right: 1rem;
  border-radius: 100%;
  width: 20px !important;
  height: 20px !important;
  padding: 2px !important;
}

.theme-dark .side-bar-calendar .fc-button.fc-button-primary {
  background-color: #2b2b2b !important;
  color: #a3a3a3 !important;
}

.side-bar-calendar .fc-button.fc-button-primary {
  background-color: #fafafa !important;
  color: #111111 !important;
  border: 1px solid #eeeeee !important;
  margin-right: 1rem;
  border-radius: 100%;
  width: 2rem !important;
  height: 2rem !important;
  padding: 2px !important;
}

.fc-scroller.fc-scroller-liquid-absolute,
.fc-scroller {
  -ms-overflow-style: none;

  scrollbar-width: none;

  overflow-y: scroll;
}

.fc-scroller.fc-scroller-liquid-absolute::-webkit-scrollbar,
.fc-scroller::-webkit-scrollbar {
  display: none;

  align-items: baseline;
  padding-left: 1rem;
}

.main-content.right-chat-active {
  overflow-x: hidden;
  margin-top: 4rem;
  min-height: 100% !important;
}

@media screen and (max-width: 576px) {
  .main-content.right-chat-active {
    overflow-x: hidden;
    margin-top: 0rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .navbar-container ul {
    flex-direction: column;
  }

  .middleSection {
    padding-top: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .creator-section {
    // width: 100vw;
    padding-left: 2rem;

    .creatorHeader {
      padding-top: 1rem;
    }
  }

  .profile-main-section {
    padding-right: 0 !important;

    .sub-section {
      div {
        .user-info-card {
          margin: 0 3rem auto 2rem;
          width: unset !important;
        }
      }
    }
  }

  .archived-section {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .main-content {
    .middle-sidebar-bottom {
      padding: 1rem 1rem !important;

      .middle-sidebar-left {
        margin: 0 1rem !important;
      }
    }
  }

  .userCard {
    width: 95% !important;
    margin: 0.5rem auto !important;
    max-width: 95%;
  }

  .find-creator-dropdown {
    top: 65px !important;
  }

  .navbar-search-icon {
    top: 11px !important;
  }

  .filter-button-archieve {
    padding-right: 2rem;
    width: 100%;
  }

  .archive-filter {
    margin-left: 1rem;
  }

  .subscription-filter-button {
    margin-left: 1rem;
  }

  .w-198px {
    width: 198px;
  }

  @media (max-width: 768px) {
    .w-198px {
      width: 100%;
    }
  }

  .sortButton {
    width: 50% !important;
    flex: none;
  }

  .subscription-search-button {
    width: 50% !important;
    flex: none;
  }

  .subscriptionSubscibe {
    letter-spacing: unset;
    font-size: 12px !important;
  }

  .feather-bell.tab {
    color: #a3a3a3 !important;
    border: 1px solid #eeeeee !important;
    background-color: #fafafa !important;
    zoom: 70%;
  }

  .theme-dark .feather-bell.tab {
    background-color: #2b2b2b !important;
    border: 1px solid #242424 !important;
  }
}

.filterButton .bookmark-dropdown-list {
  top: 68px !important;
}

.text1 {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  letter-spacing: -0.03em;

  /* text[day] */

  color: #111111;
}

.theme-dark {
  .text1 {
    color: #ffffff;
  }
}

.text2 {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  letter-spacing: -0.03em;

  /* text[day] */

  color: #111111;
}

.theme-dark {
  .text2 {
    color: #ffffff;
  }
}

.text3 {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  letter-spacing: -0.03em;

  /* gray[day] */

  color: #a3a3a3;
}

.text4 {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  letter-spacing: -0.03em;

  /* text[day] */

  color: #111111;
}

.theme-dark {
  .text4 {
    color: #ffffff;
  }
}

.modal-card-support {
  min-height: 100px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  z-index: 999;
  border-radius: 8px;

  font-family: "HelveticaNowDisplay";

  .header-view {
    display: flex;
    width: 100%;
    letter-spacing: -0.03em;
    flex-direction: column;
    gap: 4px;

    .title-view {
      color: #111111;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      color: #111111;
    }

    .sub-title-view {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #a3a3a3;
    }
  }

  .body-view {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;

    .input-card {
      textarea {
        width: 100%;
        height: 120px;
        background: #ffffff;
        border: 1px solid #eeeeee;
        border-radius: 8px;
        padding: 8px 16px;

        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
      }
    }

    .sub-withdraw-amount {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #a3a3a3;
    }

    .withdraw-amount {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      h4 {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: #a3a3a3;
      }

      h2 {
        font-style: normal;
        font-weight: 800;
        font-size: 28px;
        line-height: 36px;
        color: #111111;
      }
    }

    .card-attachment {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #2e69ff;
    }

    .image-view {
      img {
        width: 100%;
      }
    }

    .link-view {
      ul {
        li {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: -0.03em;
          color: #111111;
          margin-bottom: 5px;
          display: inline-flex;
          gap: 5px;
        }
      }
    }
  }

  .footer-view {
    width: 100%;

    .footer-right-button {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 16px;
      justify-content: flex-end;

      span {
        font-family: "HelveticaNowDisplay";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.03em;
        color: #111111;
      }
    }

    .footer-left-button {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 16px;
      justify-content: flex-start;

      span {
        font-family: "HelveticaNowDisplay";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.03em;
        color: #111111;
      }
    }
  }
}

.theme-dark {
  .modal-card-support {
    .body-view {
      .input-card {
        textarea {
          border: 1px solid var(--border-night, #242424);
          color: #eee;
        }
      }
    }
  }
}

.right {
  text-align: -webkit-right;
  justify-content: right;
}

.support-footer {
  @media (max-width: 512px) {
    display: block !important;
    padding-top: 24px;
  }

  & {
    display: inline-flex;
    background-color: #fafafa;
    width: 100%;
    justify-content: space-between;
    height: 100px;
    align-items: center;
    text-align: center;
  }

  .support-footer-left {
    color: #a3a3a3;

    @media (max-width: 512px) {
      justify-content: center;
    }

    a {
      color: #a3a3a3;

      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      /* 125% */
      letter-spacing: -0.48px;
    }
  }

  @media (min-width: 513px) {
    .support-footer-left {
      margin-left: 8%;
    }
  }

  @media (min-width: 513px) {
    .support-footer-right {
      margin-right: 8%;
    }
  }

  .support-footer-right {
    color: #a3a3a3;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 125% */
    letter-spacing: -0.48px;
  }
}

.attachment-text {
  cursor: pointer;
  color: #2e69ff;
}

.blue-text {
  color: #2e69ff !important;
}

.reply-text {
  color: #a3a3a3;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: -0.42px;
  margin-top: 24px;
}

.pill {
  align-self: center;
  background-color: #eeeeee;
  border-radius: 32px;
  color: #a3a3a3;
  font-size: 10px;
  font-weight: 700;
  margin-bottom: 20px;
  padding: 4px 8px 4px 8px;
  text-transform: uppercase;
}

.broadcast-message-sent {
  align-items: center;
  background-color: #d5e1ff;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  margin: 0.5rem;
  padding: 8px 16px 8px 16px;
}

.filter-bar {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.theme-dark .paginator-box .selected {
  color: white;
  background-color: #2b2b2b;
  border-radius: 4px;
}

/* 
.theme-dark .css-169iwlq-MuiCalendarPicker-root {
  background-color: #2b2b2b;
}

.theme-dark .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border: none;
}

.theme-dark .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  color: white;
}

.theme-dark .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:focus {
  border: none;
}


.theme-dark .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  border: 1px solid #242424;
  height: 36px;
  border-radius: 40px;
}

.theme-dark .css-195y93z-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected) {
  background-color: #111;
  color: white;
}

.theme-dark .css-195y93z-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected):hover {
  background-color: #c80000;
  color: white;
}

.theme-dark .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root {
  background-color: #2b2b2b;
  border: 1px solid #111;
  color: white;
}

.theme-dark .css-raiqh1-MuiTypography-root-MuiDayPicker-weekDayLabel,
.theme-dark .css-dplwbx-MuiPickersCalendarHeader-label,
.theme-dark .css-1tkx1wf-MuiSvgIcon-root-MuiPickersCalendarHeader-switchViewIcon,
.theme-dark .css-i4bv87-MuiSvgIcon-root {
  color: white;
}

.theme-dark .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root:hover {
  background-color: #c80000;
} */

@media (max-width: 512px) {
  .no-top0px {
    top: auto !important;
  }

  .side-bar-schedule {
    max-width: 100vw;
  }

  #splash-logo-circle {
    height: 80% !important;
    max-width: 160%;
  }

  #splash-logo-text {
    height: 20% !important;
    width: 75%;
  }

  .fix-width-comment-mobile {
    width: calc(100% - 52px) !important;
  }

  .subscribe-count {
    position: relative;
    top: -8px;
  }

  .fix-padding-card-subscription-list {
    padding: 1rem 1.5rem !important;
  }

  .manage-plan-creator-center-no-plan-mobile {
    display: flex !important;
    text-align: center;
  }

  .notification-ball {
    height: 8px !important;
    width: 8px !important;
    background-color: #cc0000 !important;
    top: 0px !important;
  }

  .remove-padding-left-right-mobile {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .fix-card-dropdown-wallet {
    position: absolute;
    width: 80vw;
    border-radius: 8px;
    max-height: 270px;
    z-index: 10;
    left: calc(-100vw + 74px);
    top: 50px;
  }

  .wallet-label {
    display: flex;
    width: 128px;
    padding-right: 0px;
    align-items: flex-start;
    flex-shrink: 0;
    color: var(--text-day, #111);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.42px;
  }

  .amount-max {
    top: 36px;
  }

  .card-money-wallet {
    width: 100%;
    border-radius: 8px;
    border: 1px solid var(--border-day, #eee);
    background: var(--background-day, #fff);
    padding: 16px;
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-top: 16px !important;
  }

  .content-message-notification {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 8px;
    background: var(--border-day, #eee);
    width: fit-content;
    max-width: calc(100% - 24px);
  }

  .w-100-mobile {
    width: 100% !important;
  }

  .pad-b-0 {
    padding-bottom: 0px !important;
  }

  .pad-t-0 {
    padding-top: 0px !important;
  }

  .padding-image-post {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  .created-post-content {
    padding-left: 0px;
    margin-left: 44px;
    margin-top: 0px !important;
  }
  .ml0px-mobile-imp {
    margin-left: 0px !important;
  }

  .post-avatar {
    min-width: 32px !important;
    height: 32px !important;
    background-color: white !important;
  }

  .post-container {
    padding: 0px;
  }

  .queue-input-padding {
    padding-left: 56px !important;
  }

  .logo-red-circle {
    height: 40px;
  }

  .logo-red-circle-login {
    height: 110px;
    text-align: -webkit-center;
  }

  .fix-mobile-comment-area {
    padding: 1rem 1.5rem !important;
  }

  .rec-carousel {
    overflow: hidden;
  }

  .gNublD {
    margin: 0px !important;
  }

  .test-extreme-div-height {
    div {
      height: 100%;
    }
  }

  .title-isolated-page {
    margin-top: 40px;
    color: var(--text-day, #111);
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 40px;
  }

  .isolated-page-content {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 24px;
  }

  .bottomClipAndroid {
    bottom: 60px !important;
  }

  .pause-button-clip {
    max-height: calc(100dvh - 64px);
  }

  .pause-button-custom-video {
    max-height: calc(100dvh - 64px);
  }

  .fix-comment-post-send {
    padding-top: 0px !important;
  }

  .ultra-fix-post-image {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .created-post-container {
    padding-top: 0px;
    padding-bottom: 16px;
  }

  .very-important-card-vic {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin-top: 0px !important;
  }

  .social-media-section {
    padding-top: 20px !important;
  }

  .width-button-buy-clip {
    width: 85vw !important;
  }

  .finalContainer {
    video {
      // max-height: calc(100dvh - 64px) !important;
      // width: 100vw !important;
      // z-index: 5;
      // size: cover;
      object-fit: cover !important;
      width: 100vw !important;
      height: calc(100dvh - 64px) !important;
      position: absolute !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
      z-index: 5 !important;
    }
  }

  .find-creator-dropdown {
    top: 182px !important;
    width: 100% !important;
    left: 0px !important;
  }

  .width-button-buy-clip-mobile-fix {
    width: 90% !important;
  }

  // .fix-mobile-subscribe-card-margin{

  // }
}

.width-button-buy-clip {
  font-size: 14px !important;
}

@media (min-width: 513px) {
  .creator-name {
    font-size: 14px;
    width: 200px !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;

    display: flex !important;
  }

  .fix-notification-title {
    padding-left: 0px !important;
    margin-left: 0px !important;
  }

  .main-content-single-post {
    padding-left: 120px;
    padding-top: 140px;
    padding-right: 120px;
  }

  .fix-padding-card-subscription-list {
    padding-top: 0px !important;
  }

  .notification-ball {
    height: 8px !important;
    width: 8px !important;
    background-color: #cc0000 !important;
    top: 8px !important;
    left: 28px !important;
  }

  .fix-card-your-wallet {
    padding-left: 48px !important;
    padding-right: 48px !important;
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }

  .wallet-label {
    display: flex;
    width: 128px;
    padding-right: 0px;
    justify-content: flex-end;
    align-items: flex-start;
    flex-shrink: 0;
    color: var(--text-day, #111);
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.42px;
  }

  .padding-image-post {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }

  .rec-carousel {
    min-width: 100%;
  }

  .title-isolated-page {
    color: var(--text-day, #111);
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 48px;
    margin-top: 40px;
  }

  .isolated-page-content {
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 20px;
  }

  .ultra-fix-story {
    z-index: 999999 !important;
    left: 47px !important;
  }
}

.ultra-fix-story {
  z-index: 999999 !important;
}

.obliterate-padding-footer-modal {
  .modal-footer {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .modal-footer > * {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

.emoji-wrap {
  left: 30px;
  padding: 8px;
  border-radius: 32px;
}

.font-weight700 {
  font-weight: 700 !important;
}

.fix-response-div-comment-post {
  top: -32px !important;
}

.wrapping-text-logo,
.wrapping-circle-logo {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

#splash-logo-text {
  height: 10%;
}

#splash-logo-circle {
  height: 35%;
}

.splash {
  height: 100vh;
  width: 100%;
}

.remove-margin-rigth {
  margin-right: 0px !important;
}

.height24px {
  height: 24px !important;
}

.Toastify__progress-bar--success,
.Toastify__progress-bar--error {
  background-color: transparent !important;
}

.Toastify__close-button {
  align-self: center;
}

.Toastify__toast-body {
  padding: 0px 10px 0px 5px;
}

.theme-dark .Toastify__toast-body > div:last-child,
.theme-dark .Toastify__close-button > svg {
  color: #ffffff;
}

.Toastify__toast-body > div:last-child,
.Toastify__close-button > svg {
  color: #111;
  display: flex;
}

.theme-dark .Toastify__toast--error {
  background: #000000;
  border-radius: 10px;
  border: 1px solid #e74c3c;
  min-height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px #e74c3c;
}

.theme-dark .Toastify__toast--success {
  background: #000000;
  border-radius: 10px;
  border: 1px solid #4ecc6a;
  min-height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px #4ecc6a;
}

.Toastify__toast--error {
  background: #faeded;
  border-radius: 10px;
  border: 1px solid #e74c3c;
  min-height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px #e74c3c;
}

.Toastify__toast--success {
  background: #edfaf0;
  border-radius: 10px;
  border: 1px solid #4ecc6a;
  min-height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px #4ecc6a;
}

.Toastify__zoom-enter {
  height: 28px;
  width: 28px;
}

.close {
  color: #fff !important;
  opacity: 1;
}

.fix-test-700 {
  font-weight: 700 !important;
}

.form-control {
  height: 50px;
}

.isolated-page {
  z-index: 1000;
  position: absolute;
  background-color: white;
  width: 100vw;
  height: 100vh;
  left: 0px;
  top: 0px;
  overflow: hidden;
}

.border-radius-10px {
  border-radius: 10px !important;
}

/* 
.MuiSvgIcon-root {
  color: rgba(0, 0, 0, 0.6);
} */

.MuiTabs-indicator {
  background-color: #cc0000 !important;
}

.MuiTypography-root.MuiTypography-caption.Mui-selected {
  color: white !important;
}

.MuiButtonBase-root.MuiButton-root {
  color: #cc0000;
}

.MuiClockPointer-thumb {
  border: 16px solid #cc0000 !important;
}

.container-trending-post {
  position: fixed;
  right: 0;
  top: 64px;
}

.MuiButtonBase-root.MuiPickersDay-root.Mui-selected,
.MuiClockPointer-root {
  background-color: #cc0000 !important;
}

.theme-dark .MuiTypography-root {
  color: white !important;
}

/* 
.css-umzx0k-MuiClock-pin {
  background-color: #cc0000 !important;
}

.css-d0vs79-MuiClockPointer-root {
  background-color: #cc0000 !important;
}

.css-h2z9v1-MuiButtonBase-root-MuiIconButton-root-MuiClock-amButton {
  background-color: #cc0000 !important;
  color: #111111 !important;
} */

/* .css-1sn4lm3-MuiTypography-root {
  color: #111111 !important;
  font-size: 14px !important;
} */

.css-rjqbug-MuiButtonBase-root-MuiIconButton-root-MuiClock-pmButton {
  background-color: #cc0000 !important;
}

.css-12t0dn4-MuiClockPointer-thumb {
  border: 16px solid #cc0000 !important;
}

.chat-group {
  background-color: #eeeeee;
  border-radius: 32px;
  max-width: 100%;
  padding: 4px 8px 4px 4px;
}

.group-avatar {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.g1 {
  background-color: #81d7ae;
}

.g2 {
  background-color: #81bdd7;
}

.g3 {
  background-color: #9781d7;
}

.g4 {
  background-color: #d5d781;
}

.g5 {
  background-color: #d7b481;
}

.g6 {
  background-color: #d78181;
}

.mw-51 {
  max-width: 51px;
}

@media (min-width: 513px) {
  .fix-card-dropdown-wallet {
    position: absolute;
    width: 100%;
    border-radius: 8px;
    max-height: 270px;
    z-index: 10;
  }

  .card-money-wallet {
    width: 50%;
    border-radius: 8px;
    border: 1px solid var(--border-day, #eee);
    background: var(--background-day, #fff);
    padding: 16px;
  }

  .notificationCard {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .content-message-notification {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 8px;
    background: var(--border-day, #eee);
    width: fit-content;
    max-width: calc(100% - 24px);
  }

  .padding-left-0px {
    padding-left: 0px !important;
  }

  .margin-left-0px {
    margin-left: 0px !important;
  }

  .logo-red-circle {
    height: 50px;
  }

  .logo-red-circle-login {
    height: 150px;
  }

  .notification {
    justify-content: space-between;
    height: calc(100dvh - 64px);
  }

  .rec-item-wrapper {
    // justify-content: left !important; Da ripristinare per avere le clip fissate a sinistra
    // padding-left: 146px !important;
    justify-content: center !important;
  }
}

.box-mobile-notification {
  position: relative;
  top: 26px;
  background-color: #d5e1ff;
  color: #2e69ff;
  width: 24px;
  text-align: center;
  border-radius: 8px;
  // left: 136px;
  height: 24px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  align-items: center;
  place-content: center;
}

img {
  max-width: 100%;
}

// .card-overflow-visible{
//   .card{
//     overflow: visible !important;
//   }
// }
.card {
  overflow: visible !important;
}

.margin-mobile-edit {
  margin-left: 120px;
  padding-top: 48px !important;
  padding-right: 120px !important;
}

.pause-button-clip {
  position: absolute !important;
  height: 100% !important;
  max-height: 100vh !important;
  width: 100% !important;
  z-index: 100 !important;
  display: flex !important;
  align-items: center !important;
  place-content: center !important;
  z-index: 25 !important;
}

.pause-button-custom-video {
  position: absolute !important;
  width: 100% !important;
  z-index: 100 !important;
  display: flex !important;
  align-items: center !important;
  place-content: center !important;
  z-index: 25 !important;
}

.cancel-post-image {
  height: 0px;
  width: 0px;
  position: absolute;
  right: 55px;
  margin-right: 16px;
  margin-top: 10px;
}

.icon-cancel-image-post {
  width: 24px;
  height: 24px;
  color: #111111cc;
}

.text-signal-card {
  color: var(--text-day, #111);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.48px;
}

.theme-dark {
  .text-signal-card {
    color: var(--text-day, #ffffff);
  }
}

.message-notification {
  color: var(--text-day, #111);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.42px;
}

.emojis-post {
  display: flex;
  align-items: center;
  // padding-top: 8px;
  // padding-bottom: 0px !important;
}

.emoji-wrap li {
  margin-right: 8px !important;
}

.border-radius8px {
  border-radius: 8px !important;
}

.gap0 {
  gap: 0px !important;
}

.mt-8px-fix-wallet {
  margin-top: 8px;
  display: flex;
}

.bg-button-disable {
  background: var(
    --primary-red-alt-day,
    linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0.5) 100%
    ),
    #c00
  );
}

.fix-modal-withdrawal-confirm-padding-body {
  padding-left: 0px !important;
  padding-top: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 16px !important;
}

.fix-modal-withdrawal-confirm-padding-footer {
  padding-left: 0px !important;
  padding-top: 16px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
}

.color-disable-gray {
  color: #a3a3a3 !important;
}

.wallet-form-control {
  border: 1px solid #eee;
  border-radius: 40px;
  opacity: 1;
  background-color: #fafafa !important;
}

.fix-card-modal-wallet-confirmation {
  .modal-content {
    padding: 24px !important;
  }
}

.payment-details {
  color: black !important;
  cursor: pointer;
}

textarea::placeholder {
  color: #a3a3a3 !important;
}

textarea {
  line-height: normal !important;
}

textarea::-webkit-scrollbar {
  width: 0px;
  background: transparent;
  display: none;
}

.resize-modal-create-prmotion {
  .modal-dialog {
    max-width: 680px !important;
  }
}

.mass-message-statistic-container {
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  margin-left: 3rem;
  margin-right: 3rem;
}

.mass-message-statistic-header {
  border-bottom: 1px solid #eeeeee;
  display: flex;
  justify-content: space-between;
  padding: 12px 20px;
}

.mass-message-statistic-content {
  overflow-x: scroll;
  padding: 16px;

  th,
  td {
    padding: 8px 12px;
  }

  thead th {
    color: #a3a3a3;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
  }

  tbody td {
    color: #111111;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    max-width: 170px;
    text-align: center;
  }

  .unsent {
    color: #cc0000;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .message-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

input {
  line-height: normal !important;
}

.emoji {
  align-items: center !important;
  align-content: center !important;
  align-self: center !important;
  display: flex !important;
}

.emojis {
  display: flex !important;
  flex-wrap: wrap !important;
  width: max-content;
}

.emojis-fix-clip {
  .emojis {
    padding-bottom: 4px !important;
  }
}

// .nav-content{
//   margin-bottom: 120px !important;
// }
.disable-reload-clip {
  div {
    overscroll-behavior: none;
    touch-action: none;
  }
}

.main-content {
  max-height: 100dvh !important;
}

// .main-content::-webkit-scrollbar{
//     width: 0px;
//     background: transparent;
//     display: none;
// }
.table-subscription-plans {
  th {
    border: 0px !important;
  }
}

div::-webkit-scrollbar {
  display: none !important;
}

body::-webkit-scrollbar {
  display: none !important;
}

.overflowHidden {
  .overflowHidden {
    overflow: hidden !important;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #111111;
    transition: background-color 5000s ease-in-out 0s;
  }

  .theme-dark {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-background-clip: text;
      -webkit-text-fill-color: #ffffff;
      transition: background-color 5000s ease-in-out 0s;
    }
  }

  .border-r16px {
    border-radius: 16px;
  }

  .theme-dark {
    .dropdown-for-subscription {
      background: #111111;
      border: 1px solid var(--border-night, #242424) !important;
    }
  }

  .fix-theme-archive-post {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    background-color: #ffffff;
  }

  .theme-dark {
    .fix-theme-archive-post {
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      background-color: #111111;
    }
  }

  .break-text {
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
  }

  div {
    overscroll-behavior: none !important;
  }

  body {
    overscroll-behavior: none !important;
  }

  html {
    overscroll-behavior: none !important;
  }
}

.text-color-theme {
  color: #111111;
}

.text-color-grey-dark {
  color: #a3a3a3;
}

.theme-dark {
  .text-color-theme {
    color: #ffffff;
  }

  .text-color-grey-dark {
    color: #ffffff;
  }
}

.fix-border-support-ticket-details {
  border: 1px solid var(--border-day, #eee);
}

.theme-dark {
  .fix-border-support-ticket-details {
    border: 1px solid var(--border-night, #242424);
  }
}

.fix-border-top-support-ticket-details {
  border: 1px solid var(--border-night, #eee);
}

.theme-dark {
  .fix-border-top-support-ticket-details {
    border: 1px solid var(--border-night, #242424);
  }
}

.fix-text-area-dark-tip {
  padding-left: 0px !important;
}

.p-8px-12px {
  padding: 8px 12px;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: #c80000 !important;
}

.chat-container {
  display: flex;
  justify-content: center;
  margin-left: 32px;
  margin-right: 32px;
  margin-top: 26px;
}

.border-grey {
  border: 1px solid #eeeeee;
}

.see-all-comment {
  color: var(--tertiary-blue-day, black) !important;
  margin-left: 2.2rem;
  font-size: 12px;
}

.theme-dark {
  .see-all-comment {
    color: var(--tertiary-blue-day, #eeeeee) !important;
    margin-left: 2.2rem;
    font-size: 12px;
  }
}

.fix-subscription-managment-aling {
  width: 110px !important;
  text-align: -webkit-left;
}

.theme-dark {
  textarea {
    background-color: transparent !important;
    color: white !important;
  }

  .form-group {
    input {
      color: white !important;
    }
  }

  .form-select:focus {
    border-color: #242424;
  }
}

.write-message-button-container {
  position: absolute;
  bottom: 16px;
  padding: 8px 16px;
  width: 100%;
}

.chat-message-container {
  // position: absolute;
  // bottom: 0px;
  // width: 100%;
  height: fit-content;
}

@media screen and (max-width: 512px) {
  .filter-clip {
    max-height: calc(100vh - 64px);
  }
}

.text-overflow-ellipsis {
  text-overflow: ellipsis;
}

.selected-message-mobile {
  background-color: rgba(204, 0, 0, 0.2);
}

.selected-message-mobile .message-chat {
  opacity: 0.6;
}

.absolute-date-message {
  float: right;
  margin-top: 4px;
  min-height: 24px;
}

.toast-bottom {
  bottom: 150px;
  text-align: center;
}

.theme-dark {
  .withdraw-select {
    border: 1px solid var(--border-night, #242424);
    background: var(--background-night, #111);
  }

  .withdraw-method {
    border: 1px solid var(--border-night, #242424);
    background: var(--background-night, #111);
  }

  .card-money-choose {
    color: #ffffff;
  }

  .card-money-wallet {
    border: 1px solid var(--border-night, #242424);
    background: var(--background-night, #111);
  }

  .card-automatic-withdraw {
    border: 1px solid var(--border-night, #242424);
    background: var(--background-night, #111);
  }

  .card-money-money {
    color: #ffffff;
  }

  .wallet-url-text {
    color: #ffffff;
  }

  .card-money-available {
    color: #ffffff;
  }

  .card-money-request {
    color: #ffffff;
  }

  .card-money-settings {
    color: #ffffff;
  }

  .special-input-clip {
    color: #ffffff;
  }

  .wallet-label {
    color: #ffffff;
  }

  .border-none {
    border: none !important;
  }

  .border-top-grey {
    border-top: 1px solid #242424;
  }

  .chat-checkbox .form-check-input {
    border-color: #242424 !important;
  }

  .chat-checkbox .form-check-input:checked {
    border-color: #c80000 !important;
  }

  .chat-dropdown-list {
    label {
      color: #ffffff !important;
    }

    hr {
      color: #242424 !important;
      opacity: 1;
    }
  }

  .chat-gray {
    color: #707070 !important;
  }

  .chat-group {
    background-color: #242424 !important;

    div {
      color: #ffffff !important;
    }
  }

  .chat-input {
    background-color: transparent !important;
  }

  .chat-input::placeholder,
  .chat-user-search::placeholder {
    color: #707070 !important;
  }

  .chat-people-selected {
    background-color: #242424 !important;
  }

  .chat-schedule {
    border-color: #707070 !important;

    input::placeholder,
    .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root
      .css-i4bv87-MuiSvgIcon-root {
      color: #707070 !important;
    }
  }

  .chat-text {
    color: #111111 !important;

    .text-silver {
      color: #111111 !important;
    }
  }

  .chat-wrapper,
  .middle-sidebar-right-content {
    background-color: #111111 !important;
    border: 1px solid #242424 !important;
  }

  .find-group-modal {
    border-color: #242424 !important;
  }

  .find-people {
    background-color: #2b2b2b !important;
  }

  label > .text-secondary-dark {
    color: #ffffff !important;
  }

  .mass-message-statistic-container {
    background-color: #111111 !important;
    border-color: #242424 !important;
  }

  .mass-message-statistic-content {
    tbody td {
      color: #ffffff;
    }

    thead th {
      color: #707070;
    }

    .paginator-box {
      color: #707070;
    }
  }

  .mass-message-statistic-header {
    border-bottom-color: #242424 !important;

    .text-secondary-dark {
      color: #ffffff !important;
    }
  }

  .messages {
    color: #ffffff !important;
  }

  .pill {
    background-color: #242424 !important;
    color: #707070 !important;
  }

  .select-any-conv {
    background-color: rgba(17, 17, 17, 0.1) !important;
  }
}

.py-8px {
  padding: 8px 0px !important;
}

.mr-8px {
  margin-right: 8px;
}

.p-8px-16px {
  padding: 8px 16px;
}

// .dropdown-menu{
//   max-width: 192px;
//   height: auto;
// }

.dropdown-menu {
  min-width: max-content;
  height: auto;
}

.fix-padding-reaction-modal {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.fix-select-support {
  background: transparent !important;
}

.display-none {
  display: none !important;
}

.number-of-media-post {
  position: absolute;
  top: 0px;
  right: 0px;
}

div,
p,
span,
a {
  font-family: "HelveticaNowDisplay" !important;
}

.image-colletion {
  width: 100% !important;
  height: 100% !important;
  // background-color: #00a9a8 !important;
  object-fit: cover !important;
  position: absolute;
  top: 0px;
}

.div-image-colletion {
  width: 50% !important;
  padding-top: 50% !important;
  height: 0px;
  position: relative;
}

.image-collection {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 512px) {
  .responsive-padding-twostep {
    border-radius: 8px !important;
    overflow: hidden;
  }

  .fix-padding-title-language {
    padding-left: 16px;
  }

  .craete-collection-input {
    height: 44px;
  }

  .image-clip-thumbnail-profile-div {
    place-content: center;
    border-radius: 8px !important;
    overflow: visible !important;
    margin-bottom: 48px !important;
    align-items: center !important;
    display: flex !important;
    height: 86.6666666vh !important;
    width: 100% !important;
    background: rgb(17, 17, 17) !important;
  }

  .image-clip-thumbnail-profile-video {
    border-radius: 8px !important;
    overflow: hidden !important;
    margin-bottom: 0px !important;
    align-items: center !important;
    display: flex !important;
    height: 86.6666666vh !important;
    width: 100% !important;
    background: rgb(17, 17, 17) !important;
  }
}

.image-clip-thumbnail-profile-video {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}

@media (min-width: 513px) {
  .chat-container {
    max-height: calc(100vh - 110px);
  }

  .subscriptionSubscibe {
    letter-spacing: unset;
    font-size: 12px !important;
    width: fit-content;
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  .craete-collection-input {
    height: 36px;
  }

  .image-clip-thumbnail-profile-div {
    place-content: center;
    width: calc(86.6666666vh * 0.560897) !important;
    height: 86.6666666vh !important;
    border-radius: 8px !important;
    overflow: hidden !important;
    margin-bottom: 48px !important;
    align-items: center !important;
    display: flex !important;
    background: rgb(17, 17, 17) !important;
  }
}

.fix-story-100-width {
  align-content: center !important;
  display: flex;
  align-self: center;

  div {
    width: 100%;
  }

  img {
    width: 100% !important;
  }
}

.collection-name {
  overflow: hidden;
  text-overflow: ellipsis;

  .collection-name-empty {
    font-size: 10px;
  }
}

.trendingPostFooter {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin-top: auto;
}

.theme-dark {
  .skeleton-placeholder {
    & {
      background-color: #2b2b2b !important;
    }

    &.story-placeholder {
      background-color: grey !important;
    }
  }
}

.theme-dark {
  .skeleton-placeholder-card {
    background-color: #111 !important;
  }
}

.image-clip-thumbnail-profile {
  width: 100% !important;
  vertical-align: middle;
  // border-radius: 8px;
  overflow: hidden;
}

.center-webkit {
  text-align: -webkit-center;
}

.fix-border-setting {
  border-radius: 8px !important;
  overflow: hidden;
}

.image-collection {
  background: var(--background-day, #ffffff);
}

.theme-dark {
  .image-collection {
    background: var(--background-day, #111);
  }

  .comment-reply-clip-text {
    color: #ffffff;
  }
}

.lined-text {
  text-decoration-line: line-through;
}

.chat-medias-container {
  border-top: 1px solid #eeeeee;
  display: flex;
  padding: 8px 20px;
  width: max-content;
}

.chat-remove-media-button {
  color: #cc0000;
  cursor: pointer;
  font-size: 16px;
  position: absolute;
  right: 8px;
  top: -4px;
}

.chat-media-container {
  margin-right: 10px;
  position: relative;
  width: fit-content;
}

.chat-bottom-buttons-container {
  position: absolute;
  bottom: 10px;
  width: 100%;
  padding-right: 35px;
}

.height-fit-content {
  height: fit-content;
}

.container {
  height: calc(100dvh - 64px);
  display: flex !important;
  flex-direction: column !important;
}

.fixed-height {
  height: 70px !important;
  /* Altezza fissa di 70px */
}

.remaining-space {
  flex: 1 !important;
  /* Si espanderà per riempire lo spazio rimanente */
  height: 100%;
  overflow: scroll;
}

.horizontal-scroll {
  overflow-x: auto;
}

.setting-manage-sub-group {
  height: 60px;
  width: 24%;
  margin-right: 1%;
  border-radius: 8px;
  border: 1px solid var(--border-day, #eee);
  align-items: center;
  display: flex;
  margin-bottom: 8px;
  overflow: hidden;
}

@media (max-width: 512px) {
  .mass-message-statistic-container {
    margin-left: 0rem;
    margin-right: 0rem;
  }

  .mobile-fix-order {
    left: 0px !important;
    top: 48px !important;
  }

  .setting-manage-sub-group {
    height: 60px;
    width: 48%;
    margin-right: 2%;
    border-radius: 8px;
    border: 1px solid var(--border-day, #eee);
    align-items: center;
    display: flex;
    margin-bottom: 16px;
  }
}

.card-manage-sub-user {
  display: flex;
  height: 58px;
  padding: 8px;
  gap: 8px;
  align-items: center;
}

.fix-paginator {
  .container {
    height: auto !important;
  }
}

.name-user-manage-sub {
  color: var(--text-day, #111);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.42px;
}

.theme-dark {
  .name-user-manage-sub {
    color: var(--text-day, #ffffff);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.42px;
  }
}

.title-table-manage-sub {
  color: var(--gray-day, #a3a3a3);

  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  /* 133.333% */
}

.subscription-title-manage-sub {
  color: var(--text-day, #111);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: -0.42px;
}

.theme-dark {
  .subscription-title-manage-sub {
    color: var(--text-day, #ffffff);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: -0.42px;
  }
}

.subscription-price-manage-sub {
  color: var(--gray-day, #a3a3a3);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: -0.42px;
}

.number-groups-manage-sub {
  color: var(--gray-day, #a3a3a3);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: -0.42px;
  text-decoration-line: underline;
}

.add-to-group-manage-sub {
  color: var(--primary-red-night, #c00);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 133.333% */
}

.groups-number-manage-sub {
  color: var(--text-day, #111);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  /* 133.333% */
}

.content-paid-media-chat {
  color: var(--text-day, #111);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.theme-dark {
  .groups-number-manage-sub {
    color: var(--text-day, #ffffff);
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    /* 133.333% */
  }
}

.breadcrumb-prev {
  color: var(--gray-day, #a3a3a3);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 133.333% */
}

.breadcrumb-current {
  color: var(--background-night, #111);

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 133.333% */
}

.theme-dark {
  .breadcrumb-current {
    color: var(--background-night, #ffffff);

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 133.333% */
  }
}

.show-more-or-less-manage-sub {
  color: var(--gray-day, #a3a3a3);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  /* 133.333% */
}

.search-fee-creator {
  width: 100%;
  padding-right: 32px;
  border-radius: 248px;
  border: 1px solid #eee;
  padding-left: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  background: transparent;
  color: #111111;
}

.theme-dark {
  .search-fee-creator {
    width: 100%;
    padding-right: 32px;
    border-radius: 248px;
    border: 1px solid #eee;
    padding-left: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
    background: transparent;
    color: #ffffff;
  }
}

.flex {
  display: flex;
}

.label-fee-add {
  color: var(--text-day, #111);
  text-align: right;

  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.42px;
  width: 96px;
  text-align: right;
  margin-right: 24px;
  align-items: center;
  display: grid;
}

.box-dropdown-fee-creator {
  height: 24px;
  gap: 8px;
  align-items: center;
  color: var(--text-day, #111);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.42px;
}

.theme-dark {
  .box-dropdown-fee-creator {
    color: var(--text-day, #ffffff);
  }
}

.name-fee-creator {
  color: var(--text-day, #111);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.36px;
}

.theme-dark {
  .name-fee-creator {
    color: var(--text-day, #ffffff);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.36px;
  }
}

.dropdown-select-fee-creator {
  display: grid;
  position: absolute;
  top: 40px;
  background-color: black;
  width: 100%;
  padding: 8px 16px;
  gap: 12px;
  border-radius: 8px;
  z-index: 2;
  border: 1px solid var(--border-day, #eee);
  background: var(--background-day, #fff);
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.15);
  color: #111111;
}

.theme-dark {
  .dropdown-select-fee-creator {
    color: #ffffff;
    border: 1px solid var(--border-day, #eee);
    background: var(--background-day, #111);
  }
}

input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #fff;
  border: 2px solid #eee;
  border-radius: 4px;
  cursor: pointer;
  height: 16px;
  outline: none;
  position: relative;
  width: 16px;
}

input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

input[type="checkbox"]:checked {
  background-color: #c80000;
  border-color: #c80000;
}

.box-select-creator-fee {
  width: calc(100% - 120px);
  display: flex;
  flex-wrap: wrap;
  padding: 6px 16px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid #eee;
  gap: 8px;
  margin-top: 16px;
}

.box-creator-fee {
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 12px;
  border-radius: 20px;
  border: 1px solid var(--border-day, #eee);
}

.theme-dark {
  .label-fee-add {
    color: #ffffff;
  }
}

.groups-user-manage-sub {
  background: white;
  width: 115px;
  height: auto;
  max-height: 200px;
  overflow-y: scroll;
  position: absolute;
  top: 30px;
  left: -32px;
  border-radius: 8px;
  border: 1px solid var(--border-day, #eee);
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
  z-index: 1;
  font-size: 14px;
  font-weight: 700;
}

.theme-dark {
  .groups-user-manage-sub {
    background: #111111;
  }
}

.button-send-message-manage-sub {
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 40px;
  border: 1px solid var(--primary-red-night, #c00);
  color: var(--primary-red-night, #c00);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.42px;
  background: transparent;
}

.defaultAvatarBadge {
  .MuiBadge-badge {
    box-shadow: 0 0 0 2px white !important;
  }
}

.tip-section {
  align-items: center;
  gap: 8px;
}

.tipCreator,
.tip-section {
  margin-right: 10px;

  .chipTip {
    color: black;
  }
}

.close-story {
  .close-x {
    color: black;
  }
}

.report-category:hover {
  color: #ff2800 !important;
}

.theme-dark {
  .defaultAvatarBadge {
    .MuiBadge-badge {
      box-shadow: 0 0 0 2px black !important;
    }
  }

  .tipCreator,
  .tip-section {
    margin-right: 10px;

    .chipTip {
      color: white;
    }
  }

  .close-story {
    .close-x {
      color: white;
    }
  }
}

.not-paid-text-chat {
  color: var(--gray-day, #a3a3a3);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  /* 120% */
}

.not-paid-text-locked {
  color: #111;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  /* 120% */
}

.locked-content-text {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px;
  /* 150% */
}

.chatButtonPurchase {
  background-color: #d40000;
  border: 1px solid #d40000;
  width: 100% !important;
  font-size: 12px !important;
  display: flex !important;
  margin-bottom: 2px;
}

.title-modal-buy-chat {
  color: var(--text-day, #111);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 133.333% */
  letter-spacing: -0.54px;
}

.theme-dark {
  .title-modal-buy-chat {
    color: var(--text-day, #ffffff);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 133.333% */
    letter-spacing: -0.54px;
  }
}

.payment-method-modal-text {
  color: var(--gray-day, #a3a3a3);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  /* 133.333% */
}

.fix-style-payment-chat {
  .payment-card {
    border: 0px solid #eeeeee;
    padding: 0px;
    border-radius: 8px;
  }

  .payment-page {
    background-color: transparent;
    background: transparent;
    margin: 0;
    min-height: auto;
  }
}

.tip-chat-user-detail {
  color: var(--tertiary-blue-day, #2e69ff);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 125% */
  letter-spacing: -0.48px;
}

.absolute-gray {
  color: var(--gray-day, #a3a3a3) !important;
}

.manage-chat-text-area {
  border-radius: 8px;
  border: 1px solid var(--border-day, #eee);
}

.fix-carousel-chat {
  img {
    max-height: 332px !important;
  }
}

.fix-width-message-chat {
  width: 50% !important;
  margin-left: auto;
}

.fix-width-message-chat-text {
  width: 90% !important;
  margin-left: auto;
}

.theme-dark {
  .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root.Mui-disabled {
    color: white !important;
  }

  .ElementsApp {
    .InputElement {
      input {
        color: white !important;
      }
    }

    .fix-width-content-max {
      width: max-content !important;
    }

    .scroll-arrow-bookmark {
      width: 24px;
      height: 24x;
      background: var(--background-day, #ffffff);
    }

    .theme-dark {
      .scroll-arrow-bookmark {
        background: var(--background-day, #111111);
      }
    }
  }
}

.icon-hover {
  &:hover {
    color: #ff2800 !important;
  }
}

.suggestionProfile,
.fix-image-trending-post,
.creator-card-container,
.setting-manage-sub-group {
  &:hover {
    border-color: #ff2800 !important;
    border-style: solid !important;
    border-width: 0.5px !important;
    -webkit-box-shadow: 0 0 15px #ff2800;
    -moz-box-shadow: 0 0 15px #ff2800;
    box-shadow: 0 0 15px #ff2800;
  }
}

.fix-image-trending-post:hover {
  height: 102px;
  padding-bottom: 0px;
}

.theme-dark {
  .vr-icon {
    filter: drop-shadow(0 0 5px rgba(255, 0, 0, 0.7)) /* Bright pink glow */
      drop-shadow(0 0 8px rgba(255, 0, 0, 0.7)) /* Bright pink glow */
      drop-shadow(0 0 15px rgba(255, 0, 0, 0.7));
    /* Bright pink glow */
  }

  .editPostStyle {
    border: 1px solid #bdbdbd !important;
  }
}

.editPostStyle {
  & {
    background-color: #fafafa;
    border-radius: 100%;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(189, 189, 189);
  }
  &.editPostStyleBookmark {
    margin-left: 5px;
  }
}

.iconBackgroundTip .chipTip,
.tipCreator .chipTip,
.queue-button,
.iconGlowHover {
  &:hover {
    border-color: #ff2800 !important;
    border-style: solid !important;
    border-width: 0.5px !important;
    -webkit-box-shadow: 0 0 8px #ff2800;
    -moz-box-shadow: 0 0 8px #ff2800;
    box-shadow: 0 0 8px #ff2800;
  }
}

.story-add-extra,
.navbar-circle {
  &:hover {
    border-color: #ff2800;
    border-style: solid;
    border-width: 1px;
    -webkit-box-shadow: 0 0 8px #ff2800;
    -moz-box-shadow: 0 0 8px #ff2800;
    box-shadow: 0 0 8px #ff2800;
  }
}

.theme-dark {
  .suggestionProfile {
    background-color: #2b2b2b;
    border-color: none;
    border-style: none;
  }
}

.suggestionProfile {
  border-style: solid;
  border-width: 1px;
  border-color: #eee;
  background-color: none;
  border-radius: 10px;
  max-height: 75px;
  height: 75px;
  padding-left: 10px;
  margin-top: 10px;
  width: 100%;
  z-index: 1;

  .suggestionProfileNameContainer {
    margin-left: 10px;
    overflow: hidden;

    p {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .name {
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .userId {
      font-size: 0.75em;
    }
  }
}

.suggestionProfileMain {
  display: flex;
  align-items: center;
}

.theme-dark {
  .css-zddqwy-MuiClockNumber-root {
    color: white !important;
  }

  .payment-details {
    color: white !important;
  }

  .wallet-info-detail {
    color: white;
    text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    letter-spacing: -0.48px;
    width: 50%;
  }

  .final-amount-withdraw {
    color: white;
  }
}

.container-media-collection {
  width: calc(33.33333% - 12px);
  height: 0px;
  padding-bottom: calc(33.33333% - 12px);
  background: var(--border-day, #eee);
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}

.container-media-collection-sub2 {
  width: calc(33.33333% - 12px);
  height: 0px;
  padding-bottom: calc(33.33333% - 12px);
  background: var(--border-day, #eee);
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}

.container-media-profile {
  width: calc(33.33333% - 12px);
  height: 0px;
  padding-bottom: calc(33.33333% - 12px);
  background: var(--border-day, #eee);
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}

.container-media-parent {
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
}

.select-media-collection {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 8px;
  right: 8px;
  border-radius: 99px;
  background-color: rgba(17, 17, 17, 0.5);
  border: 2px solid var(--primary-red-night, #c00);
  z-index: 99;
}

.media-collection-date {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 100%;

  p {
    padding: 3px;
    line-height: 11px;
    position: absolute;
    z-index: 100;
    font-size: 11px;
    color: white;
    background-color: rgba(17, 17, 17, 0.5);
    border-color: rgba(17, 17, 17, 0.5);
    border-radius: 5%;
  }
}

.select-media-collection-selected {
  z-index: 99;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 8px;
  right: 8px;
  border-radius: 99px;
  background-color: var(--primary-red-night, #c00);
}

.select-collection {
  padding-top: 0px;
  padding-bottom: 0px;
  height: 36px;
  display: grid;
  align-items: center;
  width: 250px;
  border: 1px solid var(--border-day, #eee);
}

.fc .fc-daygrid-day-number {
  position: relative;
  z-index: 4;
  padding: 4px;
  color: #000 !important;
  background: transparent;
  border-radius: 50%;
  text-align: center;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fix-width-message-chat-second {
  width: 50% !important;
  margin-right: auto;
}

.fix-width-message-chat-second-text {
  width: 90% !important;
  margin-right: auto;
}

@media (max-width: 512px) {
  .stories-list {
    overflow-x: scroll;
    max-width: calc(100vw - 30px);
    padding-top: 24px;
    padding-left: 8px;
  }

  .fix-width-message-chat {
    width: auto !important;
  }

  .fix-width-message-chat-second {
    width: 100% !important;
    margin-right: auto;
  }

  .container-media-collection {
    width: calc(33.33333% - 12px);
    height: 0px;
    padding-bottom: calc(33.33333% - 12px);
  }

  .container-media-collection-sub2 {
    width: calc(50% - 9px);
    height: 0px;
    padding-bottom: calc(50% - 9px);
  }

  .container-media-profile {
    width: calc(33.33333% - 12px);
    height: 0px;
    padding-bottom: calc(33.33333% - 12px);
  }

  .container-media-parent {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: space-between;
  }

  .fc-view-harness {
    height: 500px !important;
  }
}

.activeTab {
  color: #05f !important;
  border-bottom: 1px solid #2e69ff !important;
}

.fix-more-option-message-chat {
  position: absolute !important;
  bottom: 0px;
  width: auto !important;
  max-width: fit-content !important;
  min-width: 201px !important;
  white-space: nowrap;
}

.theme-dark .darkThemeExpiration {
  color: white !important;
}

.theme-dark {
  .scheduleTime {
    color: #ffffff;
  }
}

.title-terms {
  color: #000000;
  font-size: 40px;
  font-weight: 800;
}

.pause-internal-button-collection {
  height: 52px;
  width: 52px;
  border-radius: 60px;
  background: transparent;
  border: 6px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.privacy {
  @import url(https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLWU6uq_pVWCgBLCs0yVne9VWQcMt4LNPAicvr8auQQHLa7Zo1nuZHHjENflJxJ8CTw);

  .lst-kix_list_14-1 > li:before {
    content: "o  ";
  }

  .lst-kix_list_14-3 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_14-0 > li:before {
    content: "\0025cf   ";
  }

  .lst-kix_list_14-4 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_6-1 > li {
    counter-increment: lst-ctn-kix_list_6-1;
  }

  .lst-kix_list_14-5 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_14-7 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_14-6 > li:before {
    content: "\0025aa   ";
  }

  ul.lst-kix_list_9-3 {
    list-style-type: none;
  }

  ul.lst-kix_list_9-4 {
    list-style-type: none;
  }

  ul.lst-kix_list_9-1 {
    list-style-type: none;
  }

  ul.lst-kix_list_9-2 {
    list-style-type: none;
  }

  ul.lst-kix_list_9-7 {
    list-style-type: none;
  }

  ul.lst-kix_list_9-8 {
    list-style-type: none;
  }

  ul.lst-kix_list_9-5 {
    list-style-type: none;
  }

  .lst-kix_list_5-0 > li {
    counter-increment: lst-ctn-kix_list_5-0;
  }

  ul.lst-kix_list_9-6 {
    list-style-type: none;
  }

  ul.lst-kix_list_9-0 {
    list-style-type: none;
  }

  .lst-kix_list_14-2 > li:before {
    content: "\0025aa   ";
  }

  ol.lst-kix_list_5-3.start {
    counter-reset: lst-ctn-kix_list_5-3 0;
  }

  ul.lst-kix_list_17-1 {
    list-style-type: none;
  }

  ol.lst-kix_list_12-0.start {
    counter-reset: lst-ctn-kix_list_12-0 0;
  }

  ul.lst-kix_list_17-0 {
    list-style-type: none;
  }

  ul.lst-kix_list_17-8 {
    list-style-type: none;
  }

  ul.lst-kix_list_17-7 {
    list-style-type: none;
  }

  ul.lst-kix_list_17-6 {
    list-style-type: none;
  }

  ul.lst-kix_list_17-5 {
    list-style-type: none;
  }

  ul.lst-kix_list_17-4 {
    list-style-type: none;
  }

  ul.lst-kix_list_17-3 {
    list-style-type: none;
  }

  .lst-kix_list_14-8 > li:before {
    content: "\0025aa   ";
  }

  ul.lst-kix_list_17-2 {
    list-style-type: none;
  }

  .lst-kix_list_5-0 > li:before {
    content: "" counter(lst-ctn-kix_list_5-0, decimal) ". ";
  }

  ol.lst-kix_list_6-0 {
    list-style-type: none;
  }

  ol.lst-kix_list_6-1 {
    list-style-type: none;
  }

  .lst-kix_list_5-4 > li {
    counter-increment: lst-ctn-kix_list_5-4;
  }

  .lst-kix_list_5-3 > li:before {
    content: "" counter(lst-ctn-kix_list_5-3, decimal) ". ";
  }

  .lst-kix_list_5-2 > li:before {
    content: "" counter(lst-ctn-kix_list_5-2, decimal) ". ";
  }

  .lst-kix_list_5-1 > li:before {
    content: "" counter(lst-ctn-kix_list_5-1, decimal) ". ";
  }

  .lst-kix_list_5-7 > li:before {
    content: "" counter(lst-ctn-kix_list_5-7, decimal) ". ";
  }

  ul.lst-kix_list_8-4 {
    list-style-type: none;
  }

  ul.lst-kix_list_8-5 {
    list-style-type: none;
  }

  .lst-kix_list_5-6 > li:before {
    content: "" counter(lst-ctn-kix_list_5-6, decimal) ". ";
  }

  .lst-kix_list_5-8 > li:before {
    content: "" counter(lst-ctn-kix_list_5-8, decimal) ". ";
  }

  ul.lst-kix_list_8-2 {
    list-style-type: none;
  }

  ul.lst-kix_list_8-3 {
    list-style-type: none;
  }

  ul.lst-kix_list_8-8 {
    list-style-type: none;
  }

  ul.lst-kix_list_8-6 {
    list-style-type: none;
  }

  ul.lst-kix_list_8-7 {
    list-style-type: none;
  }

  ol.lst-kix_list_6-6 {
    list-style-type: none;
  }

  ol.lst-kix_list_6-7 {
    list-style-type: none;
  }

  .lst-kix_list_5-4 > li:before {
    content: "" counter(lst-ctn-kix_list_5-4, decimal) ". ";
  }

  ol.lst-kix_list_6-8 {
    list-style-type: none;
  }

  .lst-kix_list_5-5 > li:before {
    content: "" counter(lst-ctn-kix_list_5-5, decimal) ". ";
  }

  ol.lst-kix_list_6-2 {
    list-style-type: none;
  }

  ul.lst-kix_list_8-0 {
    list-style-type: none;
  }

  ol.lst-kix_list_6-3 {
    list-style-type: none;
  }

  ul.lst-kix_list_8-1 {
    list-style-type: none;
  }

  ol.lst-kix_list_6-4 {
    list-style-type: none;
  }

  ol.lst-kix_list_6-5 {
    list-style-type: none;
  }

  ol.lst-kix_list_12-5.start {
    counter-reset: lst-ctn-kix_list_12-5 0;
  }

  .lst-kix_list_6-1 > li:before {
    content: "" counter(lst-ctn-kix_list_6-1, decimal) ". ";
  }

  .lst-kix_list_6-3 > li:before {
    content: "" counter(lst-ctn-kix_list_6-3, decimal) ". ";
  }

  .lst-kix_list_6-5 > li {
    counter-increment: lst-ctn-kix_list_6-5;
  }

  .lst-kix_list_6-8 > li {
    counter-increment: lst-ctn-kix_list_6-8;
  }

  .lst-kix_list_6-0 > li:before {
    content: "" counter(lst-ctn-kix_list_6-0, decimal) ". ";
  }

  .lst-kix_list_6-4 > li:before {
    content: "" counter(lst-ctn-kix_list_6-4, decimal) ". ";
  }

  ul.lst-kix_list_16-2 {
    list-style-type: none;
  }

  ul.lst-kix_list_16-1 {
    list-style-type: none;
  }

  ul.lst-kix_list_16-0 {
    list-style-type: none;
  }

  .lst-kix_list_6-2 > li:before {
    content: "" counter(lst-ctn-kix_list_6-2, decimal) ". ";
  }

  ul.lst-kix_list_16-8 {
    list-style-type: none;
  }

  ul.lst-kix_list_16-7 {
    list-style-type: none;
  }

  ul.lst-kix_list_16-6 {
    list-style-type: none;
  }

  ul.lst-kix_list_16-5 {
    list-style-type: none;
  }

  ul.lst-kix_list_16-4 {
    list-style-type: none;
  }

  .lst-kix_list_6-8 > li:before {
    content: "" counter(lst-ctn-kix_list_6-8, decimal) ". ";
  }

  ul.lst-kix_list_16-3 {
    list-style-type: none;
  }

  .lst-kix_list_6-5 > li:before {
    content: "" counter(lst-ctn-kix_list_6-5, decimal) ". ";
  }

  .lst-kix_list_6-7 > li:before {
    content: "" counter(lst-ctn-kix_list_6-7, decimal) ". ";
  }

  .lst-kix_list_6-6 > li:before {
    content: "" counter(lst-ctn-kix_list_6-6, decimal) ". ";
  }

  .lst-kix_list_7-4 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_7-6 > li:before {
    content: "\0025aa   ";
  }

  ol.lst-kix_list_19-7.start {
    counter-reset: lst-ctn-kix_list_19-7 0;
  }

  ol.lst-kix_list_6-2.start {
    counter-reset: lst-ctn-kix_list_6-2 0;
  }

  .lst-kix_list_7-2 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_12-6 > li {
    counter-increment: lst-ctn-kix_list_12-6;
  }

  .lst-kix_list_13-7 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_5-7 > li {
    counter-increment: lst-ctn-kix_list_5-7;
  }

  .lst-kix_list_7-8 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_15-5 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_4-1 > li:before {
    content: "o  ";
  }

  .lst-kix_list_15-7 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_4-3 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_4-5 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_15-1 > li:before {
    content: "o  ";
  }

  .lst-kix_list_15-3 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_6-4 > li {
    counter-increment: lst-ctn-kix_list_6-4;
  }

  .lst-kix_list_19-2 > li {
    counter-increment: lst-ctn-kix_list_19-2;
  }

  .lst-kix_list_12-3 > li:before {
    content: "" counter(lst-ctn-kix_list_12-3, decimal) ". ";
  }

  .lst-kix_list_12-1 > li:before {
    content: "o  ";
  }

  .lst-kix_list_13-3 > li:before {
    content: "\0025aa   ";
  }

  ul.lst-kix_list_18-0 {
    list-style-type: none;
  }

  ol.lst-kix_list_5-8.start {
    counter-reset: lst-ctn-kix_list_5-8 0;
  }

  .lst-kix_list_13-5 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_12-5 > li:before {
    content: "" counter(lst-ctn-kix_list_12-5, decimal) ". ";
  }

  ul.lst-kix_list_18-8 {
    list-style-type: none;
  }

  ul.lst-kix_list_18-7 {
    list-style-type: none;
  }

  ul.lst-kix_list_18-6 {
    list-style-type: none;
  }

  ol.lst-kix_list_12-2.start {
    counter-reset: lst-ctn-kix_list_12-2 0;
  }

  ul.lst-kix_list_18-5 {
    list-style-type: none;
  }

  .lst-kix_list_12-7 > li:before {
    content: "" counter(lst-ctn-kix_list_12-7, decimal) ". ";
  }

  ul.lst-kix_list_18-4 {
    list-style-type: none;
  }

  ul.lst-kix_list_18-3 {
    list-style-type: none;
  }

  ul.lst-kix_list_18-2 {
    list-style-type: none;
  }

  ol.lst-kix_list_6-0.start {
    counter-reset: lst-ctn-kix_list_6-0 0;
  }

  ul.lst-kix_list_18-1 {
    list-style-type: none;
  }

  .lst-kix_list_13-1 > li:before {
    content: "o  ";
  }

  .lst-kix_list_5-1 > li {
    counter-increment: lst-ctn-kix_list_5-1;
  }

  ol.lst-kix_list_19-0.start {
    counter-reset: lst-ctn-kix_list_19-0 0;
  }

  .lst-kix_list_3-0 > li:before {
    content: "\0025cf   ";
  }

  .lst-kix_list_3-4 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_3-3 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_8-0 > li:before {
    content: "\002b9a   ";
  }

  .lst-kix_list_8-7 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_3-8 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_8-3 > li:before {
    content: "\0025aa   ";
  }

  ul.lst-kix_list_13-5 {
    list-style-type: none;
  }

  ul.lst-kix_list_13-4 {
    list-style-type: none;
  }

  ul.lst-kix_list_13-3 {
    list-style-type: none;
  }

  ul.lst-kix_list_13-2 {
    list-style-type: none;
  }

  ul.lst-kix_list_13-1 {
    list-style-type: none;
  }

  .lst-kix_list_3-7 > li:before {
    content: "\0025aa   ";
  }

  ul.lst-kix_list_13-0 {
    list-style-type: none;
  }

  .lst-kix_list_8-4 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_19-1 > li {
    counter-increment: lst-ctn-kix_list_19-1;
  }

  ul.lst-kix_list_13-8 {
    list-style-type: none;
  }

  .lst-kix_list_11-1 > li:before {
    content: "o  ";
  }

  ul.lst-kix_list_13-7 {
    list-style-type: none;
  }

  ul.lst-kix_list_13-6 {
    list-style-type: none;
  }

  .lst-kix_list_11-0 > li:before {
    content: "\0025cf   ";
  }

  .lst-kix_list_8-8 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_16-8 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_16-7 > li:before {
    content: "o  ";
  }

  ol.lst-kix_list_19-5.start {
    counter-reset: lst-ctn-kix_list_19-5 0;
  }

  .lst-kix_list_4-8 > li:before {
    content: "\0025aa   ";
  }

  ol.lst-kix_list_12-5 {
    list-style-type: none;
  }

  ol.lst-kix_list_12-6 {
    list-style-type: none;
  }

  .lst-kix_list_4-7 > li:before {
    content: "\0025aa   ";
  }

  ol.lst-kix_list_12-7 {
    list-style-type: none;
  }

  .lst-kix_list_17-0 > li:before {
    content: "\002b9a   ";
  }

  ol.lst-kix_list_12-8 {
    list-style-type: none;
  }

  ol.lst-kix_list_12-2 {
    list-style-type: none;
  }

  ol.lst-kix_list_12-3 {
    list-style-type: none;
  }

  ol.lst-kix_list_12-4 {
    list-style-type: none;
  }

  ul.lst-kix_list_4-8 {
    list-style-type: none;
  }

  .lst-kix_list_16-0 > li:before {
    content: "\002b9a   ";
  }

  ul.lst-kix_list_4-6 {
    list-style-type: none;
  }

  ul.lst-kix_list_4-7 {
    list-style-type: none;
  }

  ol.lst-kix_list_12-0 {
    list-style-type: none;
  }

  ul.lst-kix_list_4-0 {
    list-style-type: none;
  }

  .lst-kix_list_16-4 > li:before {
    content: "o  ";
  }

  ul.lst-kix_list_4-1 {
    list-style-type: none;
  }

  .lst-kix_list_16-3 > li:before {
    content: "\0025cf   ";
  }

  ul.lst-kix_list_4-4 {
    list-style-type: none;
  }

  ul.lst-kix_list_4-5 {
    list-style-type: none;
  }

  ul.lst-kix_list_4-2 {
    list-style-type: none;
  }

  ul.lst-kix_list_4-3 {
    list-style-type: none;
  }

  .lst-kix_list_17-7 > li:before {
    content: "\0025aa   ";
  }

  ul.lst-kix_list_12-1 {
    list-style-type: none;
  }

  .lst-kix_list_17-8 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_17-3 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_17-4 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_7-0 > li:before {
    content: "\0025cf   ";
  }

  ol.lst-kix_list_19-6.start {
    counter-reset: lst-ctn-kix_list_19-6 0;
  }

  ol.lst-kix_list_5-0 {
    list-style-type: none;
  }

  ol.lst-kix_list_5-1 {
    list-style-type: none;
  }

  ol.lst-kix_list_5-2 {
    list-style-type: none;
  }

  .lst-kix_list_2-4 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_2-8 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_6-6 > li {
    counter-increment: lst-ctn-kix_list_6-6;
  }

  .lst-kix_list_7-3 > li:before {
    content: "\0025aa   ";
  }

  ul.lst-kix_list_7-5 {
    list-style-type: none;
  }

  .lst-kix_list_10-0 > li:before {
    content: "\0025cf   ";
  }

  ul.lst-kix_list_7-6 {
    list-style-type: none;
  }

  ul.lst-kix_list_7-3 {
    list-style-type: none;
  }

  ul.lst-kix_list_7-4 {
    list-style-type: none;
  }

  .lst-kix_list_13-8 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_18-3 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_18-7 > li:before {
    content: "\0025aa   ";
  }

  ul.lst-kix_list_7-7 {
    list-style-type: none;
  }

  ul.lst-kix_list_7-8 {
    list-style-type: none;
  }

  ol.lst-kix_list_5-7 {
    list-style-type: none;
  }

  ol.lst-kix_list_5-8 {
    list-style-type: none;
  }

  ol.lst-kix_list_5-3 {
    list-style-type: none;
  }

  ul.lst-kix_list_7-1 {
    list-style-type: none;
  }

  .lst-kix_list_19-6 > li {
    counter-increment: lst-ctn-kix_list_19-6;
  }

  ol.lst-kix_list_5-4 {
    list-style-type: none;
  }

  ul.lst-kix_list_7-2 {
    list-style-type: none;
  }

  ol.lst-kix_list_5-5 {
    list-style-type: none;
  }

  ol.lst-kix_list_5-6 {
    list-style-type: none;
  }

  ul.lst-kix_list_7-0 {
    list-style-type: none;
  }

  .lst-kix_list_7-7 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_15-4 > li:before {
    content: "\0025aa   ";
  }

  ol.lst-kix_list_19-1.start {
    counter-reset: lst-ctn-kix_list_19-1 0;
  }

  .lst-kix_list_5-8 > li {
    counter-increment: lst-ctn-kix_list_5-8;
  }

  .lst-kix_list_10-4 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_10-8 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_4-0 > li:before {
    content: "\0025cf   ";
  }

  ul.lst-kix_list_15-3 {
    list-style-type: none;
  }

  ul.lst-kix_list_15-2 {
    list-style-type: none;
  }

  .lst-kix_list_15-0 > li:before {
    content: "\0025cf   ";
  }

  ul.lst-kix_list_15-1 {
    list-style-type: none;
  }

  .lst-kix_list_15-8 > li:before {
    content: "\0025aa   ";
  }

  ul.lst-kix_list_15-0 {
    list-style-type: none;
  }

  ol.lst-kix_list_19-4.start {
    counter-reset: lst-ctn-kix_list_19-4 0;
  }

  .lst-kix_list_4-4 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_19-4 > li {
    counter-increment: lst-ctn-kix_list_19-4;
  }

  ul.lst-kix_list_15-8 {
    list-style-type: none;
  }

  ul.lst-kix_list_15-7 {
    list-style-type: none;
  }

  ul.lst-kix_list_15-6 {
    list-style-type: none;
  }

  .lst-kix_list_9-3 > li:before {
    content: "\0025aa   ";
  }

  ul.lst-kix_list_15-5 {
    list-style-type: none;
  }

  ul.lst-kix_list_15-4 {
    list-style-type: none;
  }

  .lst-kix_list_12-8 > li {
    counter-increment: lst-ctn-kix_list_12-8;
  }

  .lst-kix_list_9-7 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_11-4 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_12-4 > li:before {
    content: "" counter(lst-ctn-kix_list_12-4, decimal) ". ";
  }

  .lst-kix_list_5-3 > li {
    counter-increment: lst-ctn-kix_list_5-3;
  }

  .lst-kix_list_20-5 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_1-0 > li:before {
    content: "\0025cf   ";
  }

  ol.lst-kix_list_19-2.start {
    counter-reset: lst-ctn-kix_list_19-2 0;
  }

  .lst-kix_list_20-1 > li:before {
    content: "o  ";
  }

  .lst-kix_list_11-8 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_12-3 > li {
    counter-increment: lst-ctn-kix_list_12-3;
  }

  .lst-kix_list_12-0 > li:before {
    content: "" counter(lst-ctn-kix_list_12-0, decimal) ". ";
  }

  .lst-kix_list_1-4 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_13-0 > li:before {
    content: "\0025cf   ";
  }

  ul.lst-kix_list_14-4 {
    list-style-type: none;
  }

  ul.lst-kix_list_14-3 {
    list-style-type: none;
  }

  ul.lst-kix_list_14-2 {
    list-style-type: none;
  }

  .lst-kix_list_13-4 > li:before {
    content: "\0025aa   ";
  }

  ul.lst-kix_list_14-1 {
    list-style-type: none;
  }

  ul.lst-kix_list_14-0 {
    list-style-type: none;
  }

  ol.lst-kix_list_19-3.start {
    counter-reset: lst-ctn-kix_list_19-3 0;
  }

  ul.lst-kix_list_14-8 {
    list-style-type: none;
  }

  ul.lst-kix_list_14-7 {
    list-style-type: none;
  }

  .lst-kix_list_2-0 > li:before {
    content: "\0025cf   ";
  }

  ul.lst-kix_list_14-6 {
    list-style-type: none;
  }

  ul.lst-kix_list_14-5 {
    list-style-type: none;
  }

  .lst-kix_list_1-8 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_12-8 > li:before {
    content: "" counter(lst-ctn-kix_list_12-8, decimal) ". ";
  }

  ul.lst-kix_list_20-2 {
    list-style-type: none;
  }

  ul.lst-kix_list_20-3 {
    list-style-type: none;
  }

  .lst-kix_list_19-0 > li:before {
    content: "" counter(lst-ctn-kix_list_19-0, lower-latin) ") ";
  }

  .lst-kix_list_19-1 > li:before {
    content: "" counter(lst-ctn-kix_list_19-1, lower-latin) ". ";
  }

  ul.lst-kix_list_20-4 {
    list-style-type: none;
  }

  ul.lst-kix_list_20-5 {
    list-style-type: none;
  }

  ul.lst-kix_list_20-6 {
    list-style-type: none;
  }

  ul.lst-kix_list_20-7 {
    list-style-type: none;
  }

  ul.lst-kix_list_1-0 {
    list-style-type: none;
  }

  ul.lst-kix_list_20-8 {
    list-style-type: none;
  }

  ol.lst-kix_list_12-6.start {
    counter-reset: lst-ctn-kix_list_12-6 0;
  }

  .lst-kix_list_19-4 > li:before {
    content: "" counter(lst-ctn-kix_list_19-4, lower-latin) ". ";
  }

  .lst-kix_list_19-2 > li:before {
    content: "" counter(lst-ctn-kix_list_19-2, lower-roman) ". ";
  }

  .lst-kix_list_19-3 > li:before {
    content: "" counter(lst-ctn-kix_list_19-3, decimal) ". ";
  }

  ul.lst-kix_list_20-0 {
    list-style-type: none;
  }

  ul.lst-kix_list_20-1 {
    list-style-type: none;
  }

  ol.lst-kix_list_6-6.start {
    counter-reset: lst-ctn-kix_list_6-6 0;
  }

  ul.lst-kix_list_1-3 {
    list-style-type: none;
  }

  ul.lst-kix_list_1-4 {
    list-style-type: none;
  }

  .lst-kix_list_19-0 > li {
    counter-increment: lst-ctn-kix_list_19-0;
  }

  ul.lst-kix_list_1-1 {
    list-style-type: none;
  }

  ul.lst-kix_list_1-2 {
    list-style-type: none;
  }

  ul.lst-kix_list_1-7 {
    list-style-type: none;
  }

  ul.lst-kix_list_1-8 {
    list-style-type: none;
  }

  ul.lst-kix_list_1-5 {
    list-style-type: none;
  }

  ul.lst-kix_list_1-6 {
    list-style-type: none;
  }

  ol.lst-kix_list_19-8.start {
    counter-reset: lst-ctn-kix_list_19-8 0;
  }

  .lst-kix_list_19-8 > li:before {
    content: "" counter(lst-ctn-kix_list_19-8, lower-roman) ". ";
  }

  .lst-kix_list_5-2 > li {
    counter-increment: lst-ctn-kix_list_5-2;
  }

  .lst-kix_list_19-5 > li:before {
    content: "" counter(lst-ctn-kix_list_19-5, lower-roman) ". ";
  }

  .lst-kix_list_19-6 > li:before {
    content: "" counter(lst-ctn-kix_list_19-6, decimal) ". ";
  }

  .lst-kix_list_19-7 > li:before {
    content: "" counter(lst-ctn-kix_list_19-7, lower-latin) ". ";
  }

  .lst-kix_list_19-7 > li {
    counter-increment: lst-ctn-kix_list_19-7;
  }

  .lst-kix_list_18-0 > li:before {
    content: "\002b9a   ";
  }

  .lst-kix_list_18-1 > li:before {
    content: "o  ";
  }

  .lst-kix_list_18-2 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_2-7 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_2-5 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_18-6 > li:before {
    content: "\0025aa   ";
  }

  ul.lst-kix_list_3-7 {
    list-style-type: none;
  }

  ul.lst-kix_list_3-8 {
    list-style-type: none;
  }

  .lst-kix_list_10-1 > li:before {
    content: "o  ";
  }

  .lst-kix_list_18-4 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_18-8 > li:before {
    content: "\0025aa   ";
  }

  ul.lst-kix_list_3-1 {
    list-style-type: none;
  }

  ul.lst-kix_list_3-2 {
    list-style-type: none;
  }

  ul.lst-kix_list_3-0 {
    list-style-type: none;
  }

  ul.lst-kix_list_3-5 {
    list-style-type: none;
  }

  ul.lst-kix_list_3-6 {
    list-style-type: none;
  }

  ul.lst-kix_list_3-3 {
    list-style-type: none;
  }

  ul.lst-kix_list_3-4 {
    list-style-type: none;
  }

  .lst-kix_list_10-7 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_10-5 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_10-3 > li:before {
    content: "\0025aa   ";
  }

  ul.lst-kix_list_11-7 {
    list-style-type: none;
  }

  ul.lst-kix_list_11-6 {
    list-style-type: none;
  }

  ul.lst-kix_list_11-5 {
    list-style-type: none;
  }

  ul.lst-kix_list_11-4 {
    list-style-type: none;
  }

  ul.lst-kix_list_11-3 {
    list-style-type: none;
  }

  ul.lst-kix_list_11-2 {
    list-style-type: none;
  }

  ul.lst-kix_list_11-1 {
    list-style-type: none;
  }

  ul.lst-kix_list_11-0 {
    list-style-type: none;
  }

  .lst-kix_list_9-2 > li:before {
    content: "\0025aa   ";
  }

  ol.lst-kix_list_5-7.start {
    counter-reset: lst-ctn-kix_list_5-7 0;
  }

  .lst-kix_list_20-8 > li:before {
    content: "\0025aa   ";
  }

  ul.lst-kix_list_11-8 {
    list-style-type: none;
  }

  .lst-kix_list_12-5 > li {
    counter-increment: lst-ctn-kix_list_12-5;
  }

  .lst-kix_list_5-5 > li {
    counter-increment: lst-ctn-kix_list_5-5;
  }

  .lst-kix_list_9-0 > li:before {
    content: "\0025cf   ";
  }

  .lst-kix_list_20-0 > li:before {
    content: "\0025cf   ";
  }

  .lst-kix_list_9-6 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_9-4 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_11-3 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_20-6 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_6-3 > li {
    counter-increment: lst-ctn-kix_list_6-3;
  }

  ul.lst-kix_list_2-8 {
    list-style-type: none;
  }

  .lst-kix_list_20-4 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_11-5 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_20-2 > li:before {
    content: "\0025aa   ";
  }

  ol.lst-kix_list_6-1.start {
    counter-reset: lst-ctn-kix_list_6-1 0;
  }

  ul.lst-kix_list_2-2 {
    list-style-type: none;
  }

  ul.lst-kix_list_2-3 {
    list-style-type: none;
  }

  ul.lst-kix_list_2-0 {
    list-style-type: none;
  }

  ul.lst-kix_list_2-1 {
    list-style-type: none;
  }

  .lst-kix_list_9-8 > li:before {
    content: "\0025aa   ";
  }

  ul.lst-kix_list_2-6 {
    list-style-type: none;
  }

  .lst-kix_list_1-1 > li:before {
    content: "o  ";
  }

  ul.lst-kix_list_2-7 {
    list-style-type: none;
  }

  .lst-kix_list_11-7 > li:before {
    content: "\0025aa   ";
  }

  ul.lst-kix_list_2-4 {
    list-style-type: none;
  }

  ul.lst-kix_list_2-5 {
    list-style-type: none;
  }

  ul.lst-kix_list_10-0 {
    list-style-type: none;
  }

  .lst-kix_list_1-3 > li:before {
    content: "\0025aa   ";
  }

  ul.lst-kix_list_10-8 {
    list-style-type: none;
  }

  ul.lst-kix_list_10-7 {
    list-style-type: none;
  }

  .lst-kix_list_1-7 > li:before {
    content: "\0025aa   ";
  }

  ul.lst-kix_list_10-6 {
    list-style-type: none;
  }

  ul.lst-kix_list_10-5 {
    list-style-type: none;
  }

  ul.lst-kix_list_10-4 {
    list-style-type: none;
  }

  ul.lst-kix_list_10-3 {
    list-style-type: none;
  }

  .lst-kix_list_1-5 > li:before {
    content: "\0025aa   ";
  }

  ul.lst-kix_list_10-2 {
    list-style-type: none;
  }

  ul.lst-kix_list_10-1 {
    list-style-type: none;
  }

  .lst-kix_list_5-6 > li {
    counter-increment: lst-ctn-kix_list_5-6;
  }

  .lst-kix_list_2-1 > li:before {
    content: "o  ";
  }

  .lst-kix_list_19-8 > li {
    counter-increment: lst-ctn-kix_list_19-8;
  }

  .lst-kix_list_2-3 > li:before {
    content: "\0025aa   ";
  }

  ol.lst-kix_list_19-6 {
    list-style-type: none;
  }

  ol.lst-kix_list_19-7 {
    list-style-type: none;
  }

  ol.lst-kix_list_19-8 {
    list-style-type: none;
  }

  ol.lst-kix_list_19-2 {
    list-style-type: none;
  }

  ol.lst-kix_list_19-3 {
    list-style-type: none;
  }

  ol.lst-kix_list_19-4 {
    list-style-type: none;
  }

  ol.lst-kix_list_19-5 {
    list-style-type: none;
  }

  ol.lst-kix_list_19-0 {
    list-style-type: none;
  }

  ol.lst-kix_list_19-1 {
    list-style-type: none;
  }

  .lst-kix_list_3-1 > li:before {
    content: "o  ";
  }

  .lst-kix_list_3-2 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_8-1 > li:before {
    content: "o  ";
  }

  .lst-kix_list_8-2 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_6-0 > li {
    counter-increment: lst-ctn-kix_list_6-0;
  }

  .lst-kix_list_3-5 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_12-0 > li {
    counter-increment: lst-ctn-kix_list_12-0;
  }

  ol.lst-kix_list_12-3.start {
    counter-reset: lst-ctn-kix_list_12-3 0;
  }

  .lst-kix_list_8-5 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_8-6 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_3-6 > li:before {
    content: "\0025aa   ";
  }

  ol.lst-kix_list_5-0.start {
    counter-reset: lst-ctn-kix_list_5-0 0;
  }

  .lst-kix_list_11-2 > li:before {
    content: "\0025aa   ";
  }

  ol.lst-kix_list_12-4.start {
    counter-reset: lst-ctn-kix_list_12-4 0;
  }

  .lst-kix_list_16-6 > li:before {
    content: "\0025cf   ";
  }

  ol.lst-kix_list_6-4.start {
    counter-reset: lst-ctn-kix_list_6-4 0;
  }

  .lst-kix_list_17-1 > li:before {
    content: "o  ";
  }

  ol.lst-kix_list_5-6.start {
    counter-reset: lst-ctn-kix_list_5-6 0;
  }

  .lst-kix_list_16-1 > li:before {
    content: "o  ";
  }

  .lst-kix_list_16-2 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_16-5 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_19-3 > li {
    counter-increment: lst-ctn-kix_list_19-3;
  }

  .lst-kix_list_12-4 > li {
    counter-increment: lst-ctn-kix_list_12-4;
  }

  ol.lst-kix_list_6-3.start {
    counter-reset: lst-ctn-kix_list_6-3 0;
  }

  .lst-kix_list_12-7 > li {
    counter-increment: lst-ctn-kix_list_12-7;
  }

  .lst-kix_list_17-2 > li:before {
    content: "\0025aa   ";
  }

  ol.lst-kix_list_5-5.start {
    counter-reset: lst-ctn-kix_list_5-5 0;
  }

  .lst-kix_list_17-6 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_17-5 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_6-2 > li {
    counter-increment: lst-ctn-kix_list_6-2;
  }

  .lst-kix_list_2-6 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_7-1 > li:before {
    content: "o  ";
  }

  .lst-kix_list_7-5 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_19-5 > li {
    counter-increment: lst-ctn-kix_list_19-5;
  }

  ol.lst-kix_list_5-4.start {
    counter-reset: lst-ctn-kix_list_5-4 0;
  }

  .lst-kix_list_18-5 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_13-6 > li:before {
    content: "\0025aa   ";
  }

  ol.lst-kix_list_5-1.start {
    counter-reset: lst-ctn-kix_list_5-1 0;
  }

  .lst-kix_list_6-7 > li {
    counter-increment: lst-ctn-kix_list_6-7;
  }

  .lst-kix_list_15-6 > li:before {
    content: "\0025aa   ";
  }

  ol.lst-kix_list_6-8.start {
    counter-reset: lst-ctn-kix_list_6-8 0;
  }

  .lst-kix_list_10-2 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_20-7 > li:before {
    content: "\0025aa   ";
  }

  ol.lst-kix_list_6-5.start {
    counter-reset: lst-ctn-kix_list_6-5 0;
  }

  .lst-kix_list_4-2 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_4-6 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_15-2 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_10-6 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_9-1 > li:before {
    content: "o  ";
  }

  ol.lst-kix_list_12-7.start {
    counter-reset: lst-ctn-kix_list_12-7 0;
  }

  ol.lst-kix_list_6-7.start {
    counter-reset: lst-ctn-kix_list_6-7 0;
  }

  .lst-kix_list_12-2 > li {
    counter-increment: lst-ctn-kix_list_12-2;
  }

  .lst-kix_list_9-5 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_12-2 > li:before {
    content: "" counter(lst-ctn-kix_list_12-2, decimal) ". ";
  }

  ol.lst-kix_list_12-8.start {
    counter-reset: lst-ctn-kix_list_12-8 0;
  }

  .lst-kix_list_11-6 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_20-3 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_1-2 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_1-6 > li:before {
    content: "\0025aa   ";
  }

  li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt;
  }

  .lst-kix_list_12-6 > li:before {
    content: "" counter(lst-ctn-kix_list_12-6, decimal) ". ";
  }

  .lst-kix_list_2-2 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_13-2 > li:before {
    content: "\0025aa   ";
  }

  ol.lst-kix_list_5-2.start {
    counter-reset: lst-ctn-kix_list_5-2 0;
  }

  ol {
    margin: 0;
    padding: 0;
  }

  table td,
  table th {
    padding: 0;
  }

  .c0 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-style: normal;
    line-height: 16px;
  }

  .c3 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 9pt;
    font-style: normal;
    line-height: 16px;
  }

  .c1 {
    background-color: #ffffff;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 16px;
    orphans: 2;
    widows: 2;
    text-align: center;
  }

  .c2 {
    background-color: #ffffff;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 16px;
    orphans: 2;
    widows: 2;
    text-align: justify;
    display: block;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .c11 {
    background-color: #fbfbfe;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 16px;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c12 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 16px;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c17 {
    color: #000000;
    text-decoration: none;
    vertical-align: baseline;
    font-style: italic;
  }

  .c14 {
    background-color: transparent;
  }

  .c16 {
    font-weight: 700;
    font-size: 10pt;
    font-family: "Garamond";
  }

  .c15 {
    margin-left: 21.3pt;
    list-style-position: inside;
    text-indent: 45pt;
  }

  .c13 {
    color: #000000;
    vertical-align: baseline;
    font-style: normal;
  }

  .c7 {
    -webkit-text-decoration-skip: none;
    text-decoration: underline;
    text-decoration-skip-ink: none;
  }

  .c6 {
    font-size: 11pt;
    font-weight: 400;
  }

  .c8 {
    padding: 0;
    margin: 0;
  }

  .c9 {
    margin-left: 36pt;
    padding-left: 0pt;
  }

  .c10 {
    color: inherit;
    text-decoration: inherit;
  }

  .c4 {
    height: 12pt;
  }

  .c5 {
    background-color: #ffff00;
    line-height: 16px;
  }

  .title {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 36pt;
    padding-bottom: 6pt;

    line-height: 16px;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .subtitle {
    padding-top: 18pt;
    color: #666666;
    font-size: 24pt;
    padding-bottom: 4pt;
    font-family: "Georgia";
    line-height: 16px;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  li {
    color: #000000;
    font-size: 12pt;
  }

  p {
    margin: 0;
    color: #000000;
    font-size: 12pt;
  }

  h1 {
    padding-top: 0pt;
    color: #000000;
    font-weight: 700;
    font-size: 24pt;
    padding-bottom: 0pt;

    line-height: 16px;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h2 {
    padding-top: 0pt;
    color: #000000;
    font-weight: 700;
    font-size: 18pt;
    padding-bottom: 0pt;

    line-height: 16px;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h3 {
    padding-top: 14pt;
    color: #000000;
    font-weight: 700;
    font-size: 14pt;
    padding-bottom: 4pt;

    line-height: 16px;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h4 {
    padding-top: 12pt;
    color: #000000;
    font-weight: 700;
    font-size: 12pt;
    padding-bottom: 2pt;

    line-height: 16px;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h5 {
    padding-top: 11pt;
    color: #000000;
    font-weight: 700;
    font-size: 11pt;
    padding-bottom: 2pt;

    line-height: 16px;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h6 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 10pt;
    padding-bottom: 2pt;

    line-height: 16px;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
}

.terms {
  @import url(https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLWU6uq_pVWCgBLCs0yVne9VWQcMt4LNPAicvr8auQQHLa7Zo1nuZHHjENflJxJ8CTw);

  ul {
    margin-left: 20px;
  }

  ol.lst-kix_list_3-1 {
    list-style-type: none;
  }

  .lst-kix_list_2-6 > li:before {
    content: "" counter(lst-ctn-kix_list_2-6, decimal) ". ";
  }

  .lst-kix_list_2-7 > li:before {
    content: "" counter(lst-ctn-kix_list_2-7, decimal) ". ";
  }

  .lst-kix_list_2-7 > li {
    counter-increment: lst-ctn-kix_list_2-7;
  }

  ol.lst-kix_list_3-2 {
    list-style-type: none;
  }

  .lst-kix_list_3-7 > li {
    counter-increment: lst-ctn-kix_list_3-7;
  }

  .lst-kix_list_3-1 > li {
    counter-increment: lst-ctn-kix_list_3-1;
  }

  ol.lst-kix_list_3-3 {
    list-style-type: none;
  }

  ol.lst-kix_list_3-4.start {
    counter-reset: lst-ctn-kix_list_3-4 0;
  }

  ol.lst-kix_list_3-4 {
    list-style-type: none;
  }

  .lst-kix_list_2-1 > li {
    counter-increment: lst-ctn-kix_list_2-1;
  }

  ul.lst-kix_list_1-0 {
    list-style-type: none;
  }

  .lst-kix_list_2-4 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_2-5 > li:before {
    content: "" counter(lst-ctn-kix_list_2-5, decimal) ". ";
  }

  .lst-kix_list_2-8 > li:before {
    content: "" counter(lst-ctn-kix_list_2-8, decimal) ". ";
  }

  ol.lst-kix_list_3-0 {
    list-style-type: none;
  }

  ol.lst-kix_list_2-6.start {
    counter-reset: lst-ctn-kix_list_2-6 0;
  }

  .lst-kix_list_3-0 > li:before {
    content: "" counter(lst-ctn-kix_list_3-0, lower-latin) ". ";
  }

  ol.lst-kix_list_3-1.start {
    counter-reset: lst-ctn-kix_list_3-1 0;
  }

  .lst-kix_list_3-1 > li:before {
    content: "" counter(lst-ctn-kix_list_3-1, lower-latin) ". ";
  }

  .lst-kix_list_3-2 > li:before {
    content: "" counter(lst-ctn-kix_list_3-2, lower-roman) ". ";
  }

  ol.lst-kix_list_3-0.start {
    counter-reset: lst-ctn-kix_list_3-0 0;
  }

  ul.lst-kix_list_1-3 {
    list-style-type: none;
  }

  .lst-kix_list_3-5 > li:before {
    content: "" counter(lst-ctn-kix_list_3-5, lower-roman) ". ";
  }

  ul.lst-kix_list_1-4 {
    list-style-type: none;
  }

  ul.lst-kix_list_1-1 {
    list-style-type: none;
  }

  .lst-kix_list_3-4 > li:before {
    content: "" counter(lst-ctn-kix_list_3-4, lower-latin) ". ";
  }

  ul.lst-kix_list_1-2 {
    list-style-type: none;
  }

  ul.lst-kix_list_1-7 {
    list-style-type: none;
  }

  .lst-kix_list_3-3 > li:before {
    content: "" counter(lst-ctn-kix_list_3-3, decimal) ". ";
  }

  ol.lst-kix_list_3-5 {
    list-style-type: none;
  }

  ul.lst-kix_list_1-8 {
    list-style-type: none;
  }

  ol.lst-kix_list_3-6 {
    list-style-type: none;
  }

  ol.lst-kix_list_3-8.start {
    counter-reset: lst-ctn-kix_list_3-8 0;
  }

  ul.lst-kix_list_1-5 {
    list-style-type: none;
  }

  ol.lst-kix_list_3-7 {
    list-style-type: none;
  }

  ul.lst-kix_list_1-6 {
    list-style-type: none;
  }

  ol.lst-kix_list_3-8 {
    list-style-type: none;
  }

  ol.lst-kix_list_2-5.start {
    counter-reset: lst-ctn-kix_list_2-5 0;
  }

  .lst-kix_list_3-8 > li:before {
    content: "" counter(lst-ctn-kix_list_3-8, lower-roman) ". ";
  }

  .lst-kix_list_2-0 > li {
    counter-increment: lst-ctn-kix_list_2-0;
  }

  .lst-kix_list_2-6 > li {
    counter-increment: lst-ctn-kix_list_2-6;
  }

  .lst-kix_list_3-8 > li {
    counter-increment: lst-ctn-kix_list_3-8;
  }

  .lst-kix_list_3-6 > li:before {
    content: "" counter(lst-ctn-kix_list_3-6, decimal) ". ";
  }

  .lst-kix_list_3-7 > li:before {
    content: "" counter(lst-ctn-kix_list_3-7, lower-latin) ". ";
  }

  ol.lst-kix_list_3-7.start {
    counter-reset: lst-ctn-kix_list_3-7 0;
  }

  .lst-kix_list_3-5 > li {
    counter-increment: lst-ctn-kix_list_3-5;
  }

  .lst-kix_list_3-2 > li {
    counter-increment: lst-ctn-kix_list_3-2;
  }

  .lst-kix_list_3-4 > li {
    counter-increment: lst-ctn-kix_list_3-4;
  }

  ol.lst-kix_list_2-5 {
    list-style-type: none;
  }

  ol.lst-kix_list_2-0 {
    list-style-type: none;
  }

  ol.lst-kix_list_2-1 {
    list-style-type: none;
  }

  ol.lst-kix_list_3-6.start {
    counter-reset: lst-ctn-kix_list_3-6 0;
  }

  ol.lst-kix_list_2-8.start {
    counter-reset: lst-ctn-kix_list_2-8 0;
  }

  ul.lst-kix_list_2-2 {
    list-style-type: none;
  }

  .lst-kix_list_1-0 > li:before {
    content: "\0025cf   ";
  }

  ul.lst-kix_list_2-3 {
    list-style-type: none;
  }

  ol.lst-kix_list_3-3.start {
    counter-reset: lst-ctn-kix_list_3-3 0;
  }

  ol.lst-kix_list_2-6 {
    list-style-type: none;
  }

  .lst-kix_list_1-1 > li:before {
    content: "o  ";
  }

  .lst-kix_list_1-2 > li:before {
    content: "\0025aa   ";
  }

  ol.lst-kix_list_2-0.start {
    counter-reset: lst-ctn-kix_list_2-0 0;
  }

  ol.lst-kix_list_2-7 {
    list-style-type: none;
  }

  ul.lst-kix_list_2-4 {
    list-style-type: none;
  }

  ol.lst-kix_list_2-8 {
    list-style-type: none;
  }

  .lst-kix_list_1-3 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_1-4 > li:before {
    content: "\0025aa   ";
  }

  ol.lst-kix_list_3-5.start {
    counter-reset: lst-ctn-kix_list_3-5 0;
  }

  .lst-kix_list_3-0 > li {
    counter-increment: lst-ctn-kix_list_3-0;
  }

  .lst-kix_list_1-7 > li:before {
    content: "\0025aa   ";
  }

  ol.lst-kix_list_2-7.start {
    counter-reset: lst-ctn-kix_list_2-7 0;
  }

  .lst-kix_list_3-3 > li {
    counter-increment: lst-ctn-kix_list_3-3;
  }

  .lst-kix_list_1-5 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_1-6 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_3-6 > li {
    counter-increment: lst-ctn-kix_list_3-6;
  }

  li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt;
  }

  .lst-kix_list_2-0 > li:before {
    content: "" counter(lst-ctn-kix_list_2-0, decimal) ". ";
  }

  .lst-kix_list_2-1 > li:before {
    content: "" counter(lst-ctn-kix_list_2-1, lower-roman) ". ";
  }

  ol.lst-kix_list_2-1.start {
    counter-reset: lst-ctn-kix_list_2-1 0;
  }

  .lst-kix_list_2-5 > li {
    counter-increment: lst-ctn-kix_list_2-5;
  }

  .lst-kix_list_2-8 > li {
    counter-increment: lst-ctn-kix_list_2-8;
  }

  ol.lst-kix_list_3-2.start {
    counter-reset: lst-ctn-kix_list_3-2 0;
  }

  .lst-kix_list_1-8 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_2-2 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_2-3 > li:before {
    content: "\0025aa   ";
  }

  ol {
    margin: 0;
    padding: 0;
  }

  table td,
  table th {
    padding: 0;
  }

  .c5 {
    -webkit-text-decoration-skip: none;
    color: #000000;
    font-weight: 400;
    text-decoration: underline;
    vertical-align: baseline;
    text-decoration-skip-ink: none;
    font-size: 11pt;
    line-height: 16px;
    font-style: normal;
  }

  .c14 {
    color: #242424;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    line-height: 16px;
    font-style: italic;
  }

  .c2 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    font-size: 11pt;
    line-height: 16px;
    font-style: normal;
    display: block;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .c1 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    line-height: 16px;
    font-style: normal;
  }

  .c0 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 16px;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }

  .c11 {
    -webkit-text-decoration-skip: none;
    font-weight: 400;
    text-decoration: underline;
    text-decoration-skip-ink: none;
    font-size: 11pt;
    font-family: "Garamond";
  }

  .c3 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 16px;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c9 {
    background-color: #ffff00;
    font-size: 11pt;
    line-height: 16px;
    font-weight: 400;
  }

  .c8 {
    font-size: 11pt;
    line-height: 16px;
    font-weight: 700;
  }

  .c4 {
    margin-left: 35.5pt;
    padding-left: 0pt;
  }

  .c12 {
    padding: 0;
    margin: 0;
  }

  .c10 {
    max-width: 481.9pt;
    padding: 70.8pt 56.7pt 56.7pt 56.7pt;
  }

  .c6 {
    height: 12pt;
  }

  .c7 {
    background-color: transparent;
  }

  .c13 {
    background-color: #ffff00;
  }

  .title {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 36pt;
    padding-bottom: 6pt;

    line-height: 16px;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .subtitle {
    padding-top: 18pt;
    color: #666666;
    font-size: 24pt;
    padding-bottom: 4pt;
    font-family: "Georgia";
    line-height: 16px;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  li {
    color: #000000;
    font-size: 12pt;
  }

  p {
    margin: 0;
    color: #000000;
    font-size: 12pt;
  }

  h1 {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 24pt;
    padding-bottom: 6pt;

    line-height: 16px;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h2 {
    padding-top: 18pt;
    color: #000000;
    font-weight: 700;
    font-size: 18pt;
    padding-bottom: 4pt;

    line-height: 16px;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h3 {
    padding-top: 14pt;
    color: #000000;
    font-weight: 700;
    font-size: 14pt;
    padding-bottom: 4pt;

    line-height: 16px;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h4 {
    padding-top: 12pt;
    color: #000000;
    font-weight: 700;
    font-size: 12pt;
    padding-bottom: 2pt;

    line-height: 16px;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h5 {
    padding-top: 11pt;
    color: #000000;
    font-weight: 700;
    font-size: 11pt;
    padding-bottom: 2pt;

    line-height: 16px;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h6 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 10pt;
    padding-bottom: 2pt;

    line-height: 16px;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
}

.our-mission {
  @import url(https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLWU6uq_pVWCgBLCs0yVne9VWQcMt4LNPAicvr8auQQHLYlRYpV3GrLaqLJ0DV0sgjA);

  .lst-kix_list_4-1 > li {
    counter-increment: lst-ctn-kix_list_4-1;
  }

  .lst-kix_list_5-1 > li {
    counter-increment: lst-ctn-kix_list_5-1;
  }

  .lst-kix_list_2-1 > li {
    counter-increment: lst-ctn-kix_list_2-1;
  }

  ul.lst-kix_list_1-0 {
    list-style-type: none;
  }

  ol.lst-kix_list_2-6.start {
    counter-reset: lst-ctn-kix_list_2-6 0;
  }

  .lst-kix_list_3-0 > li:before {
    content: "\0025cf   ";
  }

  .lst-kix_list_3-1 > li:before {
    content: "o  ";
  }

  .lst-kix_list_3-2 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_4-0 > li {
    counter-increment: lst-ctn-kix_list_4-0;
  }

  .lst-kix_list_5-0 > li {
    counter-increment: lst-ctn-kix_list_5-0;
  }

  ol.lst-kix_list_2-3.start {
    counter-reset: lst-ctn-kix_list_2-3 0;
  }

  ul.lst-kix_list_1-3 {
    list-style-type: none;
  }

  .lst-kix_list_3-5 > li:before {
    content: "\0025aa   ";
  }

  ul.lst-kix_list_1-4 {
    list-style-type: none;
  }

  ul.lst-kix_list_1-1 {
    list-style-type: none;
  }

  .lst-kix_list_3-4 > li:before {
    content: "\0025aa   ";
  }

  ul.lst-kix_list_1-2 {
    list-style-type: none;
  }

  ul.lst-kix_list_1-7 {
    list-style-type: none;
  }

  .lst-kix_list_3-3 > li:before {
    content: "\0025aa   ";
  }

  ul.lst-kix_list_1-8 {
    list-style-type: none;
  }

  ul.lst-kix_list_1-5 {
    list-style-type: none;
  }

  ul.lst-kix_list_1-6 {
    list-style-type: none;
  }

  .lst-kix_list_3-8 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_2-0 > li {
    counter-increment: lst-ctn-kix_list_2-0;
  }

  ol.lst-kix_list_5-3.start {
    counter-reset: lst-ctn-kix_list_5-3 0;
  }

  .lst-kix_list_2-3 > li {
    counter-increment: lst-ctn-kix_list_2-3;
  }

  .lst-kix_list_3-6 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_4-3 > li {
    counter-increment: lst-ctn-kix_list_4-3;
  }

  .lst-kix_list_3-7 > li:before {
    content: "\0025aa   ";
  }

  ol.lst-kix_list_4-5.start {
    counter-reset: lst-ctn-kix_list_4-5 0;
  }

  ol.lst-kix_list_5-0.start {
    counter-reset: lst-ctn-kix_list_5-0 0;
  }

  .lst-kix_list_5-2 > li {
    counter-increment: lst-ctn-kix_list_5-2;
  }

  ol.lst-kix_list_4-2.start {
    counter-reset: lst-ctn-kix_list_4-2 0;
  }

  ol.lst-kix_list_2-2 {
    list-style-type: none;
  }

  ol.lst-kix_list_2-3 {
    list-style-type: none;
  }

  .lst-kix_list_5-0 > li:before {
    content: "" counter(lst-ctn-kix_list_5-0, lower-latin) ") ";
  }

  ol.lst-kix_list_2-4 {
    list-style-type: none;
  }

  ol.lst-kix_list_2-5 {
    list-style-type: none;
  }

  .lst-kix_list_5-4 > li {
    counter-increment: lst-ctn-kix_list_5-4;
  }

  .lst-kix_list_4-4 > li {
    counter-increment: lst-ctn-kix_list_4-4;
  }

  ol.lst-kix_list_2-0 {
    list-style-type: none;
  }

  ol.lst-kix_list_2-1 {
    list-style-type: none;
  }

  .lst-kix_list_4-8 > li:before {
    content: "" counter(lst-ctn-kix_list_4-8, lower-roman) ". ";
  }

  .lst-kix_list_5-3 > li:before {
    content: "" counter(lst-ctn-kix_list_5-3, decimal) ". ";
  }

  .lst-kix_list_4-7 > li:before {
    content: "" counter(lst-ctn-kix_list_4-7, lower-latin) ". ";
  }

  .lst-kix_list_5-2 > li:before {
    content: "" counter(lst-ctn-kix_list_5-2, lower-roman) ". ";
  }

  .lst-kix_list_5-1 > li:before {
    content: "" counter(lst-ctn-kix_list_5-1, lower-latin) ". ";
  }

  .lst-kix_list_5-7 > li:before {
    content: "" counter(lst-ctn-kix_list_5-7, lower-latin) ". ";
  }

  ol.lst-kix_list_5-6.start {
    counter-reset: lst-ctn-kix_list_5-6 0;
  }

  .lst-kix_list_5-6 > li:before {
    content: "" counter(lst-ctn-kix_list_5-6, decimal) ". ";
  }

  .lst-kix_list_5-8 > li:before {
    content: "" counter(lst-ctn-kix_list_5-8, lower-roman) ". ";
  }

  ol.lst-kix_list_4-1.start {
    counter-reset: lst-ctn-kix_list_4-1 0;
  }

  ol.lst-kix_list_4-8.start {
    counter-reset: lst-ctn-kix_list_4-8 0;
  }

  .lst-kix_list_5-4 > li:before {
    content: "" counter(lst-ctn-kix_list_5-4, lower-latin) ". ";
  }

  .lst-kix_list_5-5 > li:before {
    content: "" counter(lst-ctn-kix_list_5-5, lower-roman) ". ";
  }

  ol.lst-kix_list_2-6 {
    list-style-type: none;
  }

  ol.lst-kix_list_2-7 {
    list-style-type: none;
  }

  ol.lst-kix_list_2-8 {
    list-style-type: none;
  }

  .lst-kix_list_6-1 > li:before {
    content: "\0025cf   ";
  }

  .lst-kix_list_6-3 > li:before {
    content: "\0025cf   ";
  }

  .lst-kix_list_6-0 > li:before {
    content: "\0025cf   ";
  }

  .lst-kix_list_6-4 > li:before {
    content: "\0025cf   ";
  }

  ol.lst-kix_list_4-0.start {
    counter-reset: lst-ctn-kix_list_4-0 0;
  }

  .lst-kix_list_6-2 > li:before {
    content: "\0025cf   ";
  }

  .lst-kix_list_2-5 > li {
    counter-increment: lst-ctn-kix_list_2-5;
  }

  .lst-kix_list_2-8 > li {
    counter-increment: lst-ctn-kix_list_2-8;
  }

  ol.lst-kix_list_5-5.start {
    counter-reset: lst-ctn-kix_list_5-5 0;
  }

  .lst-kix_list_6-8 > li:before {
    content: "\0025cf   ";
  }

  .lst-kix_list_6-5 > li:before {
    content: "\0025cf   ";
  }

  .lst-kix_list_6-7 > li:before {
    content: "\0025cf   ";
  }

  .lst-kix_list_2-2 > li {
    counter-increment: lst-ctn-kix_list_2-2;
  }

  ol.lst-kix_list_2-4.start {
    counter-reset: lst-ctn-kix_list_2-4 0;
  }

  ol.lst-kix_list_4-7.start {
    counter-reset: lst-ctn-kix_list_4-7 0;
  }

  .lst-kix_list_6-6 > li:before {
    content: "\0025cf   ";
  }

  ol.lst-kix_list_5-0 {
    list-style-type: none;
  }

  .lst-kix_list_2-6 > li:before {
    content: "" counter(lst-ctn-kix_list_2-6, decimal) ". ";
  }

  .lst-kix_list_2-7 > li:before {
    content: "" counter(lst-ctn-kix_list_2-7, lower-latin) ". ";
  }

  .lst-kix_list_2-7 > li {
    counter-increment: lst-ctn-kix_list_2-7;
  }

  ol.lst-kix_list_5-1 {
    list-style-type: none;
  }

  ol.lst-kix_list_5-2 {
    list-style-type: none;
  }

  .lst-kix_list_2-4 > li:before {
    content: "" counter(lst-ctn-kix_list_2-4, lower-latin) ". ";
  }

  .lst-kix_list_2-5 > li:before {
    content: "" counter(lst-ctn-kix_list_2-5, lower-roman) ". ";
  }

  .lst-kix_list_2-8 > li:before {
    content: "" counter(lst-ctn-kix_list_2-8, lower-roman) ". ";
  }

  ol.lst-kix_list_5-4.start {
    counter-reset: lst-ctn-kix_list_5-4 0;
  }

  ul.lst-kix_list_3-7 {
    list-style-type: none;
  }

  ul.lst-kix_list_3-8 {
    list-style-type: none;
  }

  ol.lst-kix_list_4-6.start {
    counter-reset: lst-ctn-kix_list_4-6 0;
  }

  ol.lst-kix_list_5-1.start {
    counter-reset: lst-ctn-kix_list_5-1 0;
  }

  ol.lst-kix_list_5-7 {
    list-style-type: none;
  }

  ul.lst-kix_list_3-1 {
    list-style-type: none;
  }

  ol.lst-kix_list_5-8 {
    list-style-type: none;
  }

  ul.lst-kix_list_3-2 {
    list-style-type: none;
  }

  .lst-kix_list_5-7 > li {
    counter-increment: lst-ctn-kix_list_5-7;
  }

  ul.lst-kix_list_3-0 {
    list-style-type: none;
  }

  ol.lst-kix_list_4-3.start {
    counter-reset: lst-ctn-kix_list_4-3 0;
  }

  ol.lst-kix_list_5-3 {
    list-style-type: none;
  }

  ul.lst-kix_list_3-5 {
    list-style-type: none;
  }

  .lst-kix_list_4-7 > li {
    counter-increment: lst-ctn-kix_list_4-7;
  }

  ol.lst-kix_list_5-4 {
    list-style-type: none;
  }

  ul.lst-kix_list_3-6 {
    list-style-type: none;
  }

  ol.lst-kix_list_5-5 {
    list-style-type: none;
  }

  ul.lst-kix_list_3-3 {
    list-style-type: none;
  }

  ol.lst-kix_list_5-6 {
    list-style-type: none;
  }

  ul.lst-kix_list_3-4 {
    list-style-type: none;
  }

  ol.lst-kix_list_2-5.start {
    counter-reset: lst-ctn-kix_list_2-5 0;
  }

  .lst-kix_list_5-8 > li {
    counter-increment: lst-ctn-kix_list_5-8;
  }

  .lst-kix_list_4-0 > li:before {
    content: "" counter(lst-ctn-kix_list_4-0, lower-latin) ") ";
  }

  .lst-kix_list_2-6 > li {
    counter-increment: lst-ctn-kix_list_2-6;
  }

  .lst-kix_list_4-1 > li:before {
    content: "" counter(lst-ctn-kix_list_4-1, lower-latin) ". ";
  }

  .lst-kix_list_4-6 > li {
    counter-increment: lst-ctn-kix_list_4-6;
  }

  .lst-kix_list_4-4 > li:before {
    content: "" counter(lst-ctn-kix_list_4-4, lower-latin) ". ";
  }

  ol.lst-kix_list_2-2.start {
    counter-reset: lst-ctn-kix_list_2-2 0;
  }

  .lst-kix_list_4-3 > li:before {
    content: "" counter(lst-ctn-kix_list_4-3, decimal) ". ";
  }

  .lst-kix_list_4-5 > li:before {
    content: "" counter(lst-ctn-kix_list_4-5, lower-roman) ". ";
  }

  .lst-kix_list_4-2 > li:before {
    content: "" counter(lst-ctn-kix_list_4-2, lower-roman) ". ";
  }

  .lst-kix_list_4-6 > li:before {
    content: "" counter(lst-ctn-kix_list_4-6, decimal) ". ";
  }

  ol.lst-kix_list_5-7.start {
    counter-reset: lst-ctn-kix_list_5-7 0;
  }

  .lst-kix_list_5-5 > li {
    counter-increment: lst-ctn-kix_list_5-5;
  }

  ol.lst-kix_list_4-0 {
    list-style-type: none;
  }

  ol.lst-kix_list_4-1 {
    list-style-type: none;
  }

  ol.lst-kix_list_4-4.start {
    counter-reset: lst-ctn-kix_list_4-4 0;
  }

  ol.lst-kix_list_4-2 {
    list-style-type: none;
  }

  ol.lst-kix_list_4-3 {
    list-style-type: none;
  }

  .lst-kix_list_2-4 > li {
    counter-increment: lst-ctn-kix_list_2-4;
  }

  ul.lst-kix_list_6-6 {
    list-style-type: none;
  }

  ul.lst-kix_list_6-7 {
    list-style-type: none;
  }

  .lst-kix_list_5-3 > li {
    counter-increment: lst-ctn-kix_list_5-3;
  }

  ul.lst-kix_list_6-4 {
    list-style-type: none;
  }

  ul.lst-kix_list_6-5 {
    list-style-type: none;
  }

  ol.lst-kix_list_2-8.start {
    counter-reset: lst-ctn-kix_list_2-8 0;
  }

  ul.lst-kix_list_6-8 {
    list-style-type: none;
  }

  ol.lst-kix_list_4-8 {
    list-style-type: none;
  }

  .lst-kix_list_1-0 > li:before {
    content: "\0025cf   ";
  }

  ol.lst-kix_list_4-4 {
    list-style-type: none;
  }

  ul.lst-kix_list_6-2 {
    list-style-type: none;
  }

  ol.lst-kix_list_4-5 {
    list-style-type: none;
  }

  ul.lst-kix_list_6-3 {
    list-style-type: none;
  }

  .lst-kix_list_1-1 > li:before {
    content: "o  ";
  }

  .lst-kix_list_1-2 > li:before {
    content: "\0025aa   ";
  }

  ol.lst-kix_list_2-0.start {
    counter-reset: lst-ctn-kix_list_2-0 0;
  }

  ol.lst-kix_list_4-6 {
    list-style-type: none;
  }

  ul.lst-kix_list_6-0 {
    list-style-type: none;
  }

  ol.lst-kix_list_4-7 {
    list-style-type: none;
  }

  ul.lst-kix_list_6-1 {
    list-style-type: none;
  }

  .lst-kix_list_1-3 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_1-4 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_4-8 > li {
    counter-increment: lst-ctn-kix_list_4-8;
  }

  .lst-kix_list_1-7 > li:before {
    content: "\0025aa   ";
  }

  ol.lst-kix_list_5-8.start {
    counter-reset: lst-ctn-kix_list_5-8 0;
  }

  ol.lst-kix_list_2-7.start {
    counter-reset: lst-ctn-kix_list_2-7 0;
  }

  .lst-kix_list_1-5 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_1-6 > li:before {
    content: "\0025aa   ";
  }

  li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt;
  }

  .lst-kix_list_5-6 > li {
    counter-increment: lst-ctn-kix_list_5-6;
  }

  .lst-kix_list_2-0 > li:before {
    content: "" counter(lst-ctn-kix_list_2-0, decimal) ". ";
  }

  .lst-kix_list_2-1 > li:before {
    content: "" counter(lst-ctn-kix_list_2-1, lower-latin) ". ";
  }

  ol.lst-kix_list_2-1.start {
    counter-reset: lst-ctn-kix_list_2-1 0;
  }

  .lst-kix_list_4-5 > li {
    counter-increment: lst-ctn-kix_list_4-5;
  }

  .lst-kix_list_1-8 > li:before {
    content: "\0025aa   ";
  }

  .lst-kix_list_2-2 > li:before {
    content: "" counter(lst-ctn-kix_list_2-2, lower-roman) ". ";
  }

  .lst-kix_list_2-3 > li:before {
    content: "" counter(lst-ctn-kix_list_2-3, decimal) ". ";
  }

  .lst-kix_list_4-2 > li {
    counter-increment: lst-ctn-kix_list_4-2;
  }

  ol.lst-kix_list_5-2.start {
    counter-reset: lst-ctn-kix_list_5-2 0;
  }

  ol {
    margin: 0;
    padding: 0;
  }

  table td,
  table th {
    padding: 0;
  }

  .c7 {
    -webkit-text-decoration-skip: none;
    color: #000000;
    font-weight: 400;
    text-decoration: underline;
    vertical-align: baseline;
    text-decoration-skip-ink: none;
    font-size: 11pt;

    font-style: normal;
  }

  .c11 {
    -webkit-text-decoration-skip: none;
    color: #202124;
    font-weight: 400;
    text-decoration: underline;
    vertical-align: baseline;
    text-decoration-skip-ink: none;
    font-size: 21pt;
    font-family: "inherit";
    font-style: normal;
  }

  .c17 {
    -webkit-text-decoration-skip: none;
    font-weight: 700;
    text-decoration: underline;
    vertical-align: baseline;
    text-decoration-skip-ink: none;
    font-size: 11pt;

    font-style: normal;
  }

  .c12 {
    background-color: #ffffff;
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Roboto";
    font-style: normal;
  }

  .c2 {
    color: #242529;
    font-weight: 400;
    text-decoration: none;
    font-size: 11pt;
    line-height: 16px;
    font-style: normal;
    display: block;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .c0 {
    background-color: #ffffff;
    padding-top: 0pt;
    padding-bottom: 12pt;
    line-height: 16px;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c3 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;

    font-style: normal;
  }

  .c4 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 16px;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c1 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 16px;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }

  .c9 {
    font-size: 11pt;

    color: #242529;
    font-weight: 400;
  }

  .c15 {
    font-size: 11pt;

    font-weight: 400;
  }

  .c5 {
    background-color: #ffffff;
    max-width: 481.9pt;
    padding: 70.8pt 56.7pt 56.7pt 56.7pt;
  }

  .c10 {
    font-size: 11pt;

    font-weight: 400;
  }

  .c8 {
    padding: 0;
    margin: 0;
  }

  .c6 {
    margin-left: 36pt;
    padding-left: 0pt;
  }

  .c16 {
    color: #242529;
  }

  .c13 {
    background-color: #ffffff;
  }

  .c14 {
    height: 12pt;
  }

  .title {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 36pt;
    padding-bottom: 6pt;

    line-height: 16px;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .subtitle {
    padding-top: 18pt;
    color: #666666;
    font-size: 24pt;
    padding-bottom: 4pt;
    font-family: "Georgia";
    line-height: 16px;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  li {
    color: #000000;
    font-size: 12pt;
  }

  p {
    margin: 0;
    color: #000000;
    font-size: 12pt;
  }

  h1 {
    padding-top: 12pt;
    color: #2f5496;
    font-size: 16pt;
    padding-bottom: 0pt;

    line-height: 16px;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h2 {
    padding-top: 0pt;
    color: #000000;
    font-weight: 700;
    font-size: 18pt;
    padding-bottom: 0pt;

    line-height: 16px;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h3 {
    padding-top: 14pt;
    color: #000000;
    font-weight: 700;
    font-size: 14pt;
    padding-bottom: 4pt;

    line-height: 16px;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h4 {
    padding-top: 12pt;
    color: #000000;
    font-weight: 700;
    font-size: 12pt;
    padding-bottom: 2pt;

    line-height: 16px;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h5 {
    padding-top: 11pt;
    color: #000000;
    font-weight: 700;
    font-size: 11pt;
    padding-bottom: 2pt;

    line-height: 16px;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h6 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 10pt;
    padding-bottom: 2pt;

    line-height: 16px;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
}

.theme-dark {
  .isolated-page {
    background: var(--background-alt-night, #2b2b2b) !important;
  }

  .terms {
    .title-isolated-page {
      margin-top: 40px;
      color: var(--text-day, white);
      font-size: 32px;
      font-style: normal;
      font-weight: 800;
      line-height: 40px;
    }
    & {
      background: var(--background-alt-night, #2b2b2b) !important;
    }
    div {
      background: var(--background-alt-night, #2b2b2b);
      color: white;
    }

    .back-transparent {
      background: transparent !important;
    }

    li {
      background: var(--background-alt-night, #2b2b2b) !important;
      color: white;
    }

    ul {
      background: var(--background-alt-night, #2b2b2b) !important;
      color: white;
    }

    p {
      background: var(--background-alt-night, #2b2b2b) !important;
      color: white;
    }

    span {
      background: var(--background-alt-night, #2b2b2b) !important;
      color: white;
      padding: 0px !important;
    }

    a {
      background: var(--background-alt-night, #2b2b2b) !important;
      color: white;
    }
  }

  .privacy {
    .title-isolated-page {
      margin-top: 40px;
      color: var(--text-day, white);
      font-size: 32px;
      font-style: normal;
      font-weight: 800;
      line-height: 40px;
    }
    & {
      background: var(--background-alt-night, #2b2b2b) !important;
    }
    div {
      background: var(--background-alt-night, #2b2b2b) !important;
      color: white;
    }

    li {
      background: var(--background-alt-night, #2b2b2b) !important;
      color: white;
    }

    ul {
      background: var(--background-alt-night, #2b2b2b) !important;
      color: white;
    }

    p {
      background: var(--background-alt-night, #2b2b2b) !important;
      color: white;
    }

    span {
      background: var(--background-alt-night, #2b2b2b) !important;
      color: white;
      padding: 0px !important;
    }

    a {
      background: var(--background-alt-night, #2b2b2b) !important;
      color: white;
    }
  }

  .our-mission {
    .title-isolated-page {
      margin-top: 40px;
      color: var(--text-day, white);
      font-size: 32px;
      font-style: normal;
      font-weight: 800;
      line-height: 40px;
    }
    & {
      background: var(--background-alt-night, #2b2b2b) !important;
    }
    div {
      background: var(--background-alt-night, #2b2b2b) !important;
      color: white;
    }

    li {
      background: var(--background-alt-night, #2b2b2b) !important;
      color: white;
    }

    ul {
      background: var(--background-alt-night, #2b2b2b) !important;
      color: white;
    }

    p {
      background: var(--background-alt-night, #2b2b2b) !important;
      color: white;
    }

    span {
      background: var(--background-alt-night, #2b2b2b) !important;
      color: white;
      padding: 0px !important;
    }

    a {
      background: var(--background-alt-night, #2b2b2b) !important;
      color: white;
    }
  }
}

.plus-add-media-chat {
  display: grid;
  align-items: center;
  font-size: 16px;
  width: 24px;
  height: 24px;
  padding: 4px;
  align-items: center;
  justify-content: center;
  color: black;
  background-color: #eee;
  border-radius: 4px;
}

.chip-div {
  border-radius: 8px;
  border: 1px solid var(--border-day, #eee);
  background: var(--border-day, #eee);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  display: inline-flex;
  padding: 8px 16px;
  align-items: flex-start;
  color: var(--text-day, #111);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.42px;
  height: 36px;
  white-space: nowrap;
  z-index: 9999;
}

.theme-dark {
  .chip-div {
    border-radius: 8px;
    border: 1px solid var(--border-night, #242424);
    background: var(--border-night, #242424);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
    color: var(--text-night, #fff);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: -0.42px;
  }

  .plus-add-media-chat {
    background: var(--border-night, #242424);
    color: white;
  }
}

.paid-media-chat-preview {
  background-color: #d5e1ff !important;
}

.chat-medias-paid-container {
  border-top: 0px;
}

.fix-width-chat92px {
  width: calc(50% + 24px) !important;
}

.fix-width-chat-text {
  width: calc(90% + 24px) !important;
}

.chat-columns {
  min-height: 100%;
  // overflow: visible !important;
  overflow: hidden !important;
  height: auto !important;
}

.input-search-chat {
  background: transparent;
}

.theme-dark {
  .input-search-chat {
    background: #2b2b2b;
    color: white !important;
  }
}

.modal-content200px {
  .modal-content {
    width: 250px !important;
  }

  .modal-dialog {
    justify-content: center !important;
  }
}

.modal-media-gallery-chat {
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
}

.lock-media-gallery {
  padding-top: 50%;
  padding-bottom: 50%;
  height: 0px;
  width: 100%;
  position: absolute;
  z-index: 2;
  top: 0px;
  display: grid;
  align-items: center;
  align-content: center;
  place-items: center;
}

.extra-filter-chat {
  width: fit-content;
  cursor: pointer;
  display: flex;
  padding: 4px 12px;
  align-items: center;
  gap: 4px;
  border-radius: 32px;
  border: 1px solid var(--tertiary-blue-day, #2e69ff);
  background: var(--tertiary-blue-white-80-day, #d5e1ff);
}

.extra-filter-chat-text-700 {
  color: var(--tertiary-blue-day, #2e69ff);
  font-family: Helvetica Now Display;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  /* 133.333% */
  letter-spacing: -0.36px;
}

.extra-filter-chat-text-400 {
  color: var(--tertiary-blue-day, #2e69ff);
  font-family: Helvetica Now Display;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.36px;
}

.extra-dropdown-list {
  top: 32px;

  label {
    color: #111 !important;
  }

  hr {
    color: #242424 !important;
    opacity: 1;
  }
}

.theme-dark {
  .extra-dropdown-list {
    top: 32px;

    label {
      color: #ffffff !important;
    }

    hr {
      color: #242424 !important;
      opacity: 1;
    }
  }
}

.unsend-label-chat {
  color: var(--tertiary-blue-day, #2e69ff);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: -0.42px;
}

.pulse-chip {
  border-radius: 50%;
  animation: chip-pulse-animation 2s infinite;
}

@keyframes chip-pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }

  100% {
    box-shadow: 0 0 0 8px rgba(0, 0, 0, 0);
  }
}

.message-min-detail {
  text-align: right;
  min-height: 24px;
}

.center-icon-right-chat {
  display: inline-flex;

  i {
    display: flex;
    align-items: center;
  }
}

.center-icon-chat {
  display: inline-flex;

  span {
    display: flex;
    align-items: center;
  }
}

// .overflow-chip{
//   overflow-y: visible !important;
//   overflow-x: visible !important;
//   overflow: visible !important;
// }
.fix-right-chat {
  //overflow: visible !important;
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
  // width: 100% !important;
  //height: calc(100vh - 15px) !important;
  background: transparent !important;
  transition: all 0.25s ease !important;
  margin-left: 0px !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
  padding-bottom: 0px !important;
  z-index: 1 !important;
}

@media (min-width: 513px) {
  .fix-right-chat {
    margin-right: 24px !important;
  }

  .fix-desk-right-chat {
    height: 100% !important;
  }
}

.theme-dark {
  .MuiChip-label {
    color: white;
  }
}

.dQiuok {
  margin: 0px !important;
}

@media (max-width: 512px) {
  .rec-slider-container {
    margin: 0px !important;
  }

  .fix-right-chat {
    width: 100% !important;
  }
}

.theme-light {
  background-color: #fafafa;
}

.theme-dark {
  background-color: #2b2b2b;
}

.image-fit-300 {
  max-height: 300px;
  width: auto;
}

.grab {
  cursor: grab;
}

.grabbed {
  cursor: grabbing;
}

.pointer {
  cursor: pointer;
}

.clip-page {
  width: 100%;

  .clip-container {
    max-width: 560px;
  }

  .clip-data {
    max-width: 560px;
  }
}

.z9999999 {
  z-index: 99999999998 !important;
}

.video-js-container-fix {
  video-js {
    max-height: 50vh !important;
    width: 46.2589vw !important;
  }
}

.video-js-container-add-media-fix {
  video-js {
    max-height: 50vh !important;
    width: 419px !important;
  }
}

.video-js-container-bookmark-fix {
  video-js {
    max-height: 50vh !important;
    width: 40vw !important;
  }
}

@media (max-width: 990px) {
  .video-js-container-fix {
    video-js {
      height: 50vh !important;
      width: 88vw !important;
    }
  }

  .video-js-container-bookmark-fix {
    video-js {
      max-height: 50vh !important;
      width: 88vw !important;
    }
  }
}

@media (max-width: 575px) {
  .video-js-container-add-media-fix {
    video-js {
      max-height: 50vh !important;
      width: 88vw !important;
    }
  }
}

.vjs-big-play-button {
  display: none !important;
}

.height-margin-pause-button {
  height: calc(100% - 90px) !important;
  margin-bottom: 30px !important;
  padding-top: 60px !important;
  position: absolute !important;
  top: 0px !important;
}

.vjs-fullscreen {
  video {
    height: 100vh !important;
  }
}

.mobile-fix-custom-video {
  width: 100% !important;
  // height: fit-content !important;
  max-height: 70vh;

  .pause-internal-button-collection {
    width: 100% !important;
  }

  .containter-button-mobile-pause-profile {
    width: 100% !important;
  }
}

@media (max-width: 512px) {
  .height-margin-pause-button {
    height: calc(100% - 30px) !important;
    margin-bottom: 30px !important;
    position: absolute;
    top: 0px;
    width: 100%;
    padding-top: 0px !important;
  }

  .limiter-heigth-emoji {
    height: 20px;
    align-items: center;
    display: flex;
  }
}

.no-point-event {
  pointer-events: none !important;
}

.stats-modal {
  padding: 8px 24px !important;
}

.user-like-thumbs {
  color: red;
  display: inline-flex;
  padding: 4px;
  align-items: flex-start;
  border-radius: 20px;
  background: #fff;
  position: relative;
  top: 16px;
  right: 20px;
  height: 24px;
  width: 24px;
}

.theme-dark {
  .user-like-thumbs {
    background-color: #000;
  }
}

.story-img-border {
  border-radius: 40px;
  border: solid 1px red;
  padding: 1px;
  width: 52px;
  height: 52px;
}

.story-img-border-gray {
  border-radius: 40px;
  border: solid 1px gray;
  padding: 1px;
  width: 52px;
  height: 52px;
}

.story-box-name {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  color: #fff;
}

.story-box-extra {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.story-box-clip {
  overflow: hidden;
  border-radius: 56px;
  background: var(--background-day, transparent);
}

.story-box-home {
  border-radius: 56px;
  background: var(--background-day, transparent);
}

.audio-text-collection {
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
  color: var(--text-day, #111);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.42px;
}

// .icon-fix-collection {
//   // top: -28px !important
// }
.padding-16px {
  padding: 16px !important;
}

.fs-12px {
  font-size: 12px !important;
}

.h-100-perc {
  height: 100% !important;
}

.fix-carousel-bookmark {
  display: -webkit-box !important;
  -webkit-box-pack: center;
}

.background-other-message {
  background: var(--border-day, #eee) !important;
}

.theme-dark {
  .background-other-message {
    background: var(--border-night, #242424) !important;
    color: white !important;
    text-decoration-color: white !important;
  }
}

.fancybox__container {
  z-index: 999999999999999999;
}

.red-check-buy {
  color: #ff2800 !important;
  font-size: 14px;
}

.infinite-scroll-component__outerdiv {
  width: 100%;
}

.modal-content {
  max-height: 100dvh;
  overflow-y: scroll;
}

.container-preview-media {
  height: 70px;
  width: 70px;
  object-fit: cover;
}

@media (max-width: 512px) {
  .container-preview-media {
    height: 60px;
    width: 60px;
  }

  .table-promotion-fix-mobile {
    width: max-content !important;

    th {
      padding-right: 8px;
    }

    td {
      padding-right: 8px;
    }
  }

  .fix-padding-b-mobile {
    padding-bottom: 48px !important;
  }

  .icon-clip-profile-mobile {
    color: white !important;
    width: 100% !important;
    position: relative !important;
    left: 0px !important;
  }

  .remove-bottom-clip {
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
  }
}

.clip-icon-margin {
  width: auto !important;
  margin-left: 16px !important;
}

.theme-light {
  .MuiDayCalendar-header {
    span {
      color: black !important;
    }
  }
}

.default-avatar-bg {
  display: contents !important;

  img {
    background-color: white !important;
  }
}

.avatar {
  img {
    background-color: white !important;
  }
}

.bg-white-absolute {
  background-color: white !important;
}

img {
  object-fit: cover !important;
}

.cancel-img-fit {
  object-fit: unset !important;
}

.responsive-bookmark-card {
  border: 1px solid #eee !important;
  border-radius: 8px !important;
  padding: 24px 32px;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}

.control-arrow {
  opacity: 1 !important;
}

.theme-dark {
  .react-tel-input {
    .country-list {
      background-color: #424242;
    }

    .search {
      background-color: #424242;
    }

    .search-box {
      background-color: #424242;
    }

    .country:hover {
      background-color: #212121;
    }

    .highlight {
      background-color: #212121 !important;
    }
  }
}

.carousel .thumb.selected {
  border: 2px solid red !important;
  animation: pulse-animation-carousel-selected 2s infinite;
}

@keyframes pulse-animation-carousel-selected {
  0%,
  100% {
    box-shadow: 0 0 4px red;
  }

  50% {
    box-shadow: 0 0 12px red;
  }
}

.fix-avatar {
  position: static !important;
}

.fancybox-container {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9999999;
  height: 100dvh;
  width: 100dvw;
  background: var(--overlay-day, rgba(0, 0, 0, 0.77));

  canvas {
    position: relative !important;
  }
}

// .a-dialog-text-container{
//   color: black;
// }
// .a-dialog-text-container::before{
//   content: "non t'appirmiettere";
// }
.a-dialog-text {
  color: black;
}

.a-dialog {
  border-radius: 8px !important;
  padding: 0px;
  margin: 0px;
  height: 175px !important;
}

.a-modal {
  background: transparent;
  display: grid;
  align-items: center;
  place-content: center;
}

.a-dialog-ok-button {
  background: transparent;
  color: #a3a3a3;
  font-size: 16px;
  font-weight: 800;
  font-weight: 800;
  opacity: 1;
}

.a-dialog-deny-button {
  background-color: transparent;
  font-weight: 800;
  color: #a3a3a3;
  opacity: 1;
}

.a-dialog-allow-button {
  background-color: transparent;
  font-weight: 800;
  color: #4ecc6a;
  opacity: 1;
}

.a-dialog-buttons-container {
  opacity: 1;

  button {
    box-shadow: 0px 0px 0px black !important;
    background-color: transparent !important;
    background: transparent !important;
  }
}

.disable-control-gyroscop {
  .a-modal {
    display: none !important;
  }

  .a-dialog {
    display: none !important;
  }
}

.custom-video-player-menu {
  color: white;
  align-content: center !important;
  position: absolute;
  z-index: 9999999;
  height: 40px;
  width: 100%;
  background: var(--overlay-day, rgba(0, 0, 0, 0.5));
  opacity: 1;
  transition: opacity 0.3s ease;
  place-content: flex-start;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  animation: opacity 0.5s ease;
}

@media (max-width: 512px) {
  .custom-video-player-menu {
    opacity: 1;
  }
}

video {
  transition: position 0.3 ease;
  animation: slideIn 0.3s ease forwards;
}

canvas {
  transition: position 0.3 ease;
  animation: slideIn 0.3s ease forwards;
}

@keyframes slideIn {
  from {
    opacity: 0;
    /* Opacità iniziale */
  }

  to {
    opacity: 1;
    /* Opacità finale */
  }
}

.custom-video-player-menu:hover {
  opacity: 1;
}

.a-enter-vr-button {
  display: none;
}

.animation-pause-play {
  opacity: 0;
  animation: play-stop-video 0.5s ease;
}

@keyframes play-stop-video {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: matrix(1.4, 0, 0, 1.4, 0, 0);
  }
}

.base-carousel-comportament {
  height: 100% !important;
  max-height: 70vh !important;
  width: calc(100% - 56px) !important;
  margin-left: 28px !important;
  margin-right: 28px !important;
  place-items: center;
  min-height: 100px;
  min-width: 100px;
  overflow: hidden;
  // margin-bottom: 48px;
}

@media (max-width: 512px) {
  .base-carousel-comportament {
    width: 100% !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    // padding-bottom: 36px !important;
  }
  .control-next {
    height: calc(100% - 100px) !important;
    margin-bottom: auto;
    padding-top: 57px !important;
    // height: 48px !important;
    // margin-top: auto !important;
    // // background: white !important;
    // padding: 0px !important;
    // padding-left: 5px !important;
    // padding-right: 5px !important;
  }
  .control-prev {
    height: calc(100% - 100px) !important;
    margin-bottom: auto;
    padding-top: 57px !important;
    // height: 48px !important;
    // margin-top: auto !important;
    // // background: white !important;
    // padding: 0px !important;
    // padding-left: 5px !important;
    // padding-right: 5px !important;
  }
}

.center-figure-bg {
  figure {
    background-position-x: center !important;
  }
}

// .controls[data-state="hidden"] {
//   display: none;
// }
// video[data-state="hidden"] {
//   display: none;
// }

// video::-webkit-media-controls-panel {
//   background-color: green !important;
// }

// video::-webkit-media-controls-play-button {
//   background-color: yellow !important;
// }

// video::-webkit-media-controls-play-button:hover {
//   background-color: blue;
// }
.pl-0px-imp {
  padding-left: 0px !important;
}

input[type="range"]::-webkit-slider-runnable-track {
  background: #ffffff;
  height: 4px;
  border-radius: 8px;
}

input[type="range"]::-moz-range-track {
  background: #ffffff;
  height: 0.5rem;
  border-radius: 8px;
}

input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 0px;
  opacity: 0;
  transition: width 0.5s ease-in-out;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  margin-top: -5px;
  background-color: #ffffff;
  height: 14px;
  width: 14px;
  border-radius: 99px;
}

.video-time-bar {
  input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 0px;
    opacity: 1;
    transition: width 0.5s ease-in-out;
  }
}

.vanishAudio {
  animation: closeAudio 2 ease;
}

.appearAudio {
  animation: openAudio 2 ease;
}

@keyframes open-audio {
  0% {
    width: 0px !important;
    opacity: 0;
  }

  100% {
    width: 120px !important;
    opacity: 1;
    // display: flex !important;
  }
}

@keyframes close-audio {
  0% {
    width: 120px;
  }

  100% {
    width: 0px;
    display: none;
  }
}

.absolute-hide {
  display: none !important;
}
.disable-transform-carousel {
  .slider.animated {
    // transform: none !important;
  }
}
.fixed-fullscreen-canvas-iphone {
  width: auto;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999;
}
.fixed-fullscreen-menu-iphone {
  width: 100vw;
  position: fixed;
  bottom: 0px;
  left: 0px;
  z-index: 9999;
}
.remove-option-image-iphone-chrome {
  img {
    pointer-events: none !important ;
  }
}
.text-modal-archive {
  color: black;
}
.theme-dark {
  .text-modal-archive {
    color: white;
  }
}

.disclaimerPaymentFooter {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  a:hover {
    color: #ff2800 !important;
  }
}

#withdrawalmethodCard {
  max-height: fit-content;
}
//Personalizzazione del form di pagamento
html {
  /* Custom - Background */
  --ccb-background: #280f50;

  /* Custom - Header - Ribbon */
  --ccb-ribbon-background: #62389e;
  --ccb-header-language-dropdown-text: #ffffff;
  --ccb-header-language-dropdown-icon: #ffffff;
  --ccb-header-language-background: #62389e;

  /* Add more customizations here */
}

#withdrawalmethodCard {
  max-height: fit-content;
}
