.emoji-list-main-container {
    background-color: white;
    position: absolute;
    border-radius: 16px;
    padding: 6px 10px;
    z-index: 9999999999;
    left: 5px;
    box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.15);

    .emoji-list-main-list {
        display: flex;
        gap: 8px;

        .emoji-list-main-item {
            .emoji-list-main-item-span {
                font-size: 30px;
                line-height: 34px;
                cursor: pointer;
            }
        }
    }
}

.theme-dark {
    .emoji-list-main-container {
        background-color: #111;
    }
}