@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('./fonts/HelveticaNowDisplay-LightIta.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('fonts/HelveticaNowDisplay-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('./fonts/HelveticaNowDisplay-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('./fonts/HelveticaNowDisplay-RegIta.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'HelveticaNowDisplay Hairline';
    src: url('./fonts/HelveticaNowDisplay-HairlineI.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('./fonts/HelveticaNowDisplay-MedIta.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('./fonts/HelveticaNowDisplay-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('./fonts/HelveticaNowDisplay-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('./fonts/HelveticaNowDisplay-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('./fonts/HelveticaNowDisplay-ThinIta.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('fonts/HelveticaNowDisplay-BlackIta.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('./fonts/HelveticaNowDisplay-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('./fonts/HelveticaNowDisplay-BoldIta.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('./fonts/HelveticaNowDisplay-ExtLt.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNowDisplay ExtraBlack';
    src: url('./fonts/HelveticaNowDisplay-ExtBlk.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNowDisplay ExtraBlack';
    src: url('./fonts/HelveticaNowDisplay-ExtBlkIta.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('./fonts/HelveticaNowDisplay-ExtBdIta.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('./fonts/HelveticaNowDisplay-ExtLtIta.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('./fonts/HelveticaNowDisplay-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNowDisplay Hairline';
    src: url('./fonts/HelveticaNowDisplay-Hairline.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}



@import "./assets/scss/style.scss";
@import "react-phone-input-2/lib/style.css";
