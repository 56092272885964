.collection-details-page {
    .main-container {
        margin: auto 7.5rem;
    }

    .bread-crumb {
        .main-page {
            color: $gray-color;
        }
    }

    .page-container {
        border: 1px solid $dark-white-color;
        border-radius: 8px;
        padding-bottom: 15px;

        .tab-menu {
            display: flex;
            align-items: center;
            gap: 16px;
            font-size: 14px;
            padding-top: 24px;
            padding-left: 16px;
            width: 95%;
            margin: 0 auto;
        }

        .tab-menu-mobile {
            padding: 10px;
            display: none;
        }

        .action-buttons {
            padding-left: 32px;

            .delete-selection {
                color: #c80000;
                background-color: #fae5e5;
            }

            .add-to-collecton {
                color: #2e69ff;
                background-color: #d5e1ff;
                padding: 5px 100px;
            }
        }

        .collection-image-card {
            aspect-ratio: 1;

            .image-card-body {
                width: fit-content;
                margin: unset;

                .collection-image {
                    aspect-ratio: 1;
                    object-fit: cover !important;
                }

                .checkbox-image-div {
                    right: 10px !important;
                    top: 7px !important;
                }
            }
        }
    }
}

.theme-dark {
    .mui-select-menu {
        border: 1px solid #242424;

        .MuiSelect-icon {
            color: #242424;
        }
    }
}

.theme-dark {
    .collection-details-page {
        .bread-crumb {
            .main-page {
                color: $dim-gray-color;
            }
        }

        .page-container {
            border: 1px solid #242424;
        }
    }
}

@media screen and (max-width: 600px) {
    .collection-details-page {
        .main-container {
            margin: 4rem 1rem 0;

            .page-container {
                .tab-menu {
                    display: none;
                }

                .tab-menu-mobile {
                    padding: 10px;
                    display: block;
                }

                .action-buttons {
                    padding-left: 32px;

                    .delete-selection {
                        color: #c80000;
                        background-color: #fae5e5;
                        text-align: center;
                        width: 90%;
                        padding: unset 0 unset;
                    }

                    .add-to-collecton {
                        color: #2e69ff;
                        background-color: #d5e1ff;
                        text-align: center;
                        width: 90%;
                    }
                }

                .image-card-body {
                    padding: unset !important;

                    .checkbox-image-div {
                        right: 10px !important;
                        top: 10px !important;
                    }
                }
            }
        }
    }

    .theme-dark {
        .collection-details-page {
            .main-container {
                .page-container {
                    background-color: transparent;
                    border: unset;
                }
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .collection-details-page {
        .main-container {
            margin: auto 2rem;

            .bread-crumb {
                padding-top: 1rem !important;
            }
        }
    }
}